import React, { useState, useRef, useEffect } from 'react';
import './MenuDropdownProps.scss'; 
import { useTranslation } from 'react-i18next';
import CustomTooltip from '../../Tooltip/tooltip';
import { useLocation } from 'react-router-dom';

interface MenuDropdownProps {
    onShareClick?: () => void;
    onReportClick?: () => void;
    isMobile?: boolean;
}

const MenuDropdown: React.FC<MenuDropdownProps> = ({ onShareClick, onReportClick, isMobile }) => {
  const location = useLocation()
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);

    return (
        <div className="menu-container" ref={dropdownRef}>
            <CustomTooltip title={t("common.Menu")}>
    <img
        src={
            /^\/blog\/\d+$/.test(location.pathname) 
                ? '/images/apts/icons/menuBlack-vertical_svgrepo.com.svg'
                : isMobile
                ? '/images/menu-vertical_svgrepo.com-white.svg'
                : '/images/menu-vertical_svgrepo.com.svg'
        }
        alt="Menu"
        onClick={handleMenuToggle}
        className="menu-icon"
        style={{ cursor: 'pointer' }}
    />
</CustomTooltip>


            {isMenuOpen && (
                <div className="dropdown-menu">
                    {onShareClick && (
                        <CustomTooltip title={t("common.Share")}>
                            <div className="menu-item" onClick={onShareClick}>
                                <img src={'/images/apts/icons/blue-share.svg'} alt="Share" className="menu-icon" />
                                <span>{t("common.Share")}</span>
                            </div>
                        </CustomTooltip>
                    )}
                    {onReportClick && (
                        <CustomTooltip title={t("common.Report")}>
                            <div className="menu-item" onClick={onReportClick}>
                                <img src={'/images/apts/icons/report.svg'} alt="Report" className="menu-icon" />
                                <span>{t("common.Report")}</span>
                            </div>
                        </CustomTooltip>
                    )}
                </div>
            )}
        </div>
    );
};

export default MenuDropdown;
