import { useTranslation } from 'react-i18next';
import './areaCard.scss';
import { useNavigate } from "react-router-dom";

interface AreaCardProps {
    backgroundImageUrl?: string;
    areaName: string;
    propertiesNum: number | string;
    slug: string;
}

export default function AreaCard({ slug, backgroundImageUrl, areaName, propertiesNum }: AreaCardProps) {
    const navigate = useNavigate();
    const {t}=useTranslation()

    return (
        <div className="areaCard" onClick={() => navigate(`/props/${slug}`)}>
            <div className="image-wrapper">
                <img src={backgroundImageUrl} alt={areaName} className="property-image" />
            </div>
            <div className="info-wrapper">
                <div className="area-name">{areaName}</div>
                <div className="properties-count">{propertiesNum} {t("common.Properties")}</div>
            </div>
        </div>
    );
}
