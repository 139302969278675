import { useRef, useState, useEffect } from 'react';
import './popularInAreas.scss';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import SliderControls from '../sliderControls/sliderControls';
import SmallAreaCard from '../smallAreaCard/smallAreaCard';
import { Location } from '../../../helpers/types/general';
import { useLanguage } from '../../../context/LanguageContext';

interface PopularInAreasProps {
    areas: Location[];
    onSearchClick?: (search: Location) => void;
    onViewAllClick?: () => void;
}

const PopularInAreas: React.FC<PopularInAreasProps> = ({ areas, onSearchClick, onViewAllClick }) => {
    const swiperRef = useRef<any>(null);
    const { t } = useTranslation();
    const { language } = useLanguage();
    const direction = language === 'ar' ? 'rtl' : 'ltr';
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            const swiperInstance = swiperRef.current.swiper;
            setIsAtStart(swiperInstance.isBeginning);
        }
    };

    const handleSwiperInit = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            const swiperInstance = swiperRef.current.swiper;
            setIsAtStart(swiperInstance.isBeginning);
        }
    };

    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            if (!swiperRef.current.swiper.isEnd) {
                setIsAtEnd(false);
            }
        }
    };

    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            if (swiperRef.current.swiper.isEnd) {
                setIsAtEnd(swiperRef.current.swiper.isEnd);
            }
        }
    };

    const handleItemClick = (item: Location) => {
        if (onSearchClick) {
            onSearchClick(item);
        }
    };
    const updateNavigationState = () => {
        if (swiperRef.current?.swiper) {
            setIsAtStart(swiperRef.current.swiper.isBeginning);
            setIsAtEnd(swiperRef.current.swiper.isEnd);
        }
    };
    useEffect(() => {
        if (swiperRef.current?.swiper) {
            const swiperInstance = swiperRef.current.swiper;
            updateNavigationState();
            swiperInstance.on('slideChange', updateNavigationState);
            return () => {
                swiperInstance.off('slideChange', updateNavigationState);
            };
        }
    }, [areas.length, direction]);
    return (
        <div className="popular-area">
            <div className="popular-area-header">
                <h2>{t("discover.FindPropertiesinTheseCities")}</h2>
                <SliderControls
                    className={"web-show"}
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                    onViewAllClick={onViewAllClick}
                    isAtStart={isAtStart}
                    isAtEnd={isAtEnd}
                />
            </div>
            <div className="popular-residential">
                <div className="slider">
                    <Swiper
                        key={direction}
                        ref={swiperRef}
                        slidesPerView={'auto'}
                        spaceBetween={10}
                        modules={[Navigation]}
                        onInit={handleSwiperInit}
                        onSlideChange={handleSlideChange}
                    >
                        {areas?.map((option) => (
                            <SwiperSlide key={option?.area?.name} onClick={() => handleItemClick(option)}>
                                <SmallAreaCard
                                    areaName={option?.area?.name}
                                    propertyCount={10}
                                    img={option?.area?.image || "/images/apts/1.png"}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <SliderControls
                    className={"mobile-show"}
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                    onViewAllClick={onViewAllClick}
                    isAtStart={isAtStart}
                    isAtEnd={isAtEnd}
                />
            </div>
        </div>
    );
}

export default PopularInAreas;
