import React, { useEffect, useRef, useState } from "react";
import './aiCards.scss';
import ReactImageGallery from "react-image-gallery";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { convertObjectToImageString, formatTimeAgo, whatsAppME } from "../../helpers/helpers";
import { ImageSize } from "../../helpers/types/general";
import { PropertyDTO } from "../../helpers/types/property";
import CustomTooltip from "../Tooltip/tooltip";
import ShareModal from "../Modals/ShareModal/ShareModal";
import { useAddToFavourites, useDeleteFavourites } from "../../services/userServices";
import { message, Modal } from "antd";
import { useSettings } from "../../context/SettingsContext";
import { useAuthContext } from "../../auth/AuthProvider";
import Login from "../../pages/user/login/login";
import { useNativeShare } from "../Modals/ShareModal/nativeShare";


interface ApartmentCardInterface {
    property: PropertyDTO & { rentalFrequency?: string };
    authToken: string;
    onPropertyClick?: (propID: string | number) => void;
    setIsModalVisible?: any
    setIsShareModalVisible?: any
    showUserModal?: any
    isAuthenticated?: any
    setSelectedMessage?: any
    setPropertyId?: any
}

const ApartmentCardAi: React.FC<ApartmentCardInterface> = ({ setPropertyId, setSelectedMessage, isAuthenticated, showUserModal, setIsShareModalVisible, setIsModalVisible, property, authToken, onPropertyClick }) => {
    // const { ID, rentSellType, agent, publishDate, bedRoomNums, bathRoomNums, company, propImages, title, location, price, size, propertiesType, rentalFrequency } = property;
     const [isMobile, setIsMobile] = useState(false);
       useEffect(() => {
         const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera; 
         if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
           setIsMobile(true);
         }
       }, []);
             const handleNativeShare = useNativeShare(property.whatsAppMsg, property.ID);
       
    const { t } = useTranslation();
    const { currency } = useSettings();
    const [price, setPrice] = useState<{ value: number | undefined; per: string; }>({ value: 0, per: '' });
    const [isFavorite, setIsFavorite] = useState(property.isFavorite);
    const { refetch: AddToFavourites } = useAddToFavourites();
    const { refetch: DeleteFavourites } = useDeleteFavourites();
    const location = useLocation()
    const handleShareClick = (e: React.MouseEvent<HTMLImageElement>) => {
        e.stopPropagation()
        setSelectedMessage(property.whatsAppMsg)
        setPropertyId(property.ID)
        if(isMobile){
            handleNativeShare()
        }
        else {
            setIsShareModalVisible(true);
        }
      
    };

    const handleCloseShareModal = () => {
        setIsShareModalVisible(false);
    };

    useEffect(() => {
        const getPrice = () => {
            if (property.rentSellType === 'Rent') {
                switch (property.rentalFrequency) {
                    case 'Yearly':
                        return { value: property.priceYearly?.AED, per: `/ ${t("property.year")}` };
                    case 'Monthly':
                        return { value: property.priceMonthly?.AED, per: `/ ${t("property.month")}` };
                    // case 'Weekly':
                    //     return { value: property.priceWeekly?.AED, per: `/ ${t("property.week")}` };
                    // case 'Daily':
                    //     return { value: property.priceDaily?.AED, per: `/ ${t("property.day")}` };
                    default:
                        return { value: property.priceYearly?.AED, per: `/ ${t("property.year")}` };
                }
            }
            return { value: property.price?.AED, per: '' };
        };

        setPrice(getPrice());
    }, [property, t]);




    const images = property.propImages?.map((item) => {
        const img = convertObjectToImageString(item, ImageSize.Medium);
        return { original: img, thumbnail: img }
    }) || [];

    const navigate = useNavigate()
    const handleClick = () => {
        if (onPropertyClick && property.ID) onPropertyClick(property.ID);
        else navigate(`/property/${property.ID}`);



    }


    const handleFavoriteToggle = (e: React.MouseEvent<HTMLImageElement>) => {
        e.stopPropagation();
        if (!isAuthenticated) {
            showUserModal()
        }
        else {
            if (!isFavorite) {
                AddToFavourites({
                    data: {
                        Id: property.ID,
                    },
                    headers: {
                        channel: "w",
                        authorization: `Bearer ${authToken}`,
                    },
                });
            } else {
                DeleteFavourites({
                    data: {
                        Id: property.ID,
                    },
                    headers: {
                        channel: "w",
                        authorization: `Bearer ${authToken}`,
                    },
                });
            }
            setIsFavorite(!isFavorite);
        }

    };
    return (
        <>
            <div className="apartment-card-ai" >
                <div className="container" onClick={handleClick}>
                    <div className="img-gallery" onClick={(e) => e.stopPropagation()}>
                        <ReactImageGallery
                            items={images}
                            infinite
                            showIndex={images.length ? true : false}
                            lazyLoad
                            disableSwipe={false}
                            autoPlay={false}
                            showPlayButton={false}
                            showFullscreenButton={false}
                            showThumbnails={false}
                            showBullets={false}
                            onClick={(e) => e.stopPropagation()}

                        />
                        <div className="love">
                            <CustomTooltip title={t("common.Share")}>
                                <img
                                    className="lf-btn"
                                    src={'/images/apts/icons/share.svg'}
                                    onClick={handleShareClick}
                                    alt="Share"
                                />
                            </CustomTooltip>
                            <CustomTooltip title={isFavorite ? t("common.RemovefromFavorites") : t("common.AddtoFavorites")}>
                                <img
                                    className={`lf-btn ${isFavorite ? 'filled' : 'empty'}`}
                                    src={isFavorite ? '/images/apts/icons/love-filled.svg' : '/images/apts/icons/love.svg'}
                                    onClick={handleFavoriteToggle}
                                    alt="Favorite"
                                />
                            </CustomTooltip>

                        </div>
                    </div>
                    <div className="details" >
                        <div className="detail">
                            <span className="listed">
                                {t("property.ListedIn")} {formatTimeAgo(property.publishDate)}
                            </span>
                            <span className="price">
                                {price.value
                                    ? (price.value.toString().length > 15
                                        ? price.value.toFixed(3).toLocaleString()
                                        : price.value.toLocaleString(undefined, { maximumFractionDigits: 3 }))
                                    : '0'} {currency ?? t('common.AED')} {price.per}
                            </span>


                            <div className="location">
                                <img src={'/images/apts/icons/location-pin.svg'} />
                                <span >{property.location.location_description ?? "Jade Tower, Majan, Dubai"}</span>
                            </div>

                            <span className="location-description">{property.title ?? "INVESTOR DEAL | 20 % HUGE DISCOUNT | FULLY LUXURY"}</span>
                            <div className="description">
                                <div className="item">
                                    {(property.propertiesType !== undefined && property.propertiesType) && (
                                        <div className="beds-baths">
                                            <img className="icon" src={'/images/apts/icons/type.svg'} />
                                            <span>{property.propertiesType}</span>
                                        </div>
                                    )}
                                    {(property.bedRoomNums !== undefined && property.bedRoomNums > 0) && (
                                        <div className="beds-baths">
                                            <img className="icon" src={'/images/apts/icons/bed-single.svg'} />
                                            <span>{property.bedRoomNums} {t("filters.Beds")}</span>
                                        </div>
                                    )}

                                </div>
                                <div className="item">
                                    {(property.bathRoomNums !== undefined && property.bathRoomNums > 0) && (
                                        <div className="beds-baths">
                                            <img className="icon" src={'/images/apts/icons/bathtub.svg'} />
                                            <span>{property.bathRoomNums} {t("filters.Baths")}</span>
                                        </div>
                                    )}

                                    {(property.size?.sqft !== undefined && property.size?.sqft > 0) && (
                                        <div className="beds-baths">
                                            <img className="icon" src={'/images/apts/icons/area.svg'} />
                                            <span>{property.size?.sqft?.toFixed(2)} sqft</span>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>

                        <div className="btns">
                            <div className="agent">
                                <img className="agent-img" src={convertObjectToImageString(property.agent.image, ImageSize.Small) ?? '/images/agents/1.png'} />
                                <div className="agent-info">
                                    <span className="agent-name">{property.agent.name ?? "Agent Name"}</span>
                                    <span className="view" onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/agentProfile/${property.agent.id}`);
                                        setIsModalVisible(false)
                                    }}>
                                        {t("FindYourAgent.OpenProfile")}
                                    </span>
                                </div>
                            </div>
                            <div className="contact">
                                <CustomTooltip title={t("common.call")}>
                                    <button className="contact-btn">
                                        <a href={`tel:${property.agent.mobile}`}>
                                            <img className="contact-icon" src="/images/apts/icons/call.svg" />
                                        </a>
                                    </button>
                                </CustomTooltip>

                                <CustomTooltip title={t("common.email")}>
                                    <button className="contact-btn">

                                        <a href={`mailto:${property.agent.email}`} >
                                            <img className="contact-icon" src={'/images/apts/icons/mail.svg'} />
                                        </a>
                                    </button>
                                </CustomTooltip>
                                <CustomTooltip title={t("common.whatsApp")}>
                                    <button className="contact-btn" onClick={() => whatsAppME(property.agent.mobile || "", property?.whatsAppMsg, property?.ID, location)}>

                                        <a href=''>
                                            <img className="contact-icon" src={'/images/apts/icons/whatsapp_svgrepo.com.svg'} />
                                        </a>
                                    </button>
                                </CustomTooltip>

                            </div>

                        </div>
                    </div>
                </div>


            </div>

        </>

    )
}

export default (ApartmentCardAi);
