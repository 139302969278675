import { useLanguage } from "../context/LanguageContext";
import { BaseParam, favoriteBlog, ReportCategoryResponse, ReportForm } from "../helpers/types/general";
import { CreateAxiosGetRequest, CreateAxiosPostRequest } from "./GeneralService";

const blogsData = [
  {
    id: '1',
    title: 'Your Ultimate Guide to Real Estate in Dubai',
    summary: "Dubai’s real estate market is one of the most dynamic and sought-after in the world. Whether you're an investor, a homebuyer, or simply curious, this article provides valuable insights into the opportunities, trends, and tips to navigate the market effectively.",
    content: 'Full content of Tech Blog 1',
    category: 'News & Trends'
  },
  {
    id: '2',
    title: 'Your Ultimate Guide to Real Estate in Dubai',
    summary: "Dubai’s real estate market is one of the most dynamic and sought-after in the world. Whether you're an investor, a homebuyer, or simply curious, this article provides valuable insights into the opportunities, trends, and tips to navigate the market effectively.",
    content: 'Full content of Tech Blog 1',
    category: 'News & Trends'
  },
  { id: '3', title: 'News Article 1', summary: 'Summary of News Article 1', content: 'Full content of News Article 1', category: 'Finance & Investment' },
  { id: '4', title: 'News Article 1', summary: 'Summary of News Article 1', content: 'Full content of News Article 1', category: 'Finance & Investment' },
  { id: '5', title: 'Tech Blog 2', summary: 'Summary of Tech Blog 2', content: 'Full content of Tech Blog 2', category: 'Buying' },
  { id: '6', title: 'Tech Blog 2', summary: 'Summary of Tech Blog 2', content: 'Full content of Tech Blog 2', category: 'Buying' },
  { id: '7', title: 'News Article 2', summary: 'Summary of News Article 2', content: 'Full content of News Article 2', category: 'Selling' },
  { id: '8', title: 'News Article 2', summary: 'Summary of News Article 2', content: 'Full content of News Article 2', category: 'Selling' },
  { id: '9', title: 'News Article 3', summary: 'Summary of News Article 3', content: 'Full content of News Article 3', category: 'Renting' },
  { id: '10', title: 'News Article 3', summary: 'Summary of News Article 3', content: 'Full content of News Article 3', category: 'Renting' },
  { id: '11', title: 'News Article 4', summary: 'Summary of News Article 4', content: 'Full content of News Article 4', category: 'Places' },
  { id: '12', title: 'News Article 4', summary: 'Summary of News Article 4', content: 'Full content of News Article 4', category: 'Places' },
  { id: '13', title: 'News Article 5', summary: 'Summary of News Article 5', content: 'Full content of News Article 5', category: 'Lifestyle' },
  { id: '14', title: 'News Article 5', summary: 'Summary of News Article 5', content: 'Full content of News Article 5', category: 'Lifestyle' },


];


export const getBlogsByFilter = async (filterId: string) => {
  if (filterId === 'all') return blogsData;
  return blogsData.filter(blog => blog.category === filterId);
};


export const getBlogById = async (id: string) => {
  return blogsData.find(blog => blog.id === id) || null;
};

interface UseGetBlogsParams {
  page?: number;
  size?: number;
  section?: string;
  flag?: any
}
export const useGetBlogs = ({ page = 0, size = 3, section, flag }: UseGetBlogsParams) => {

  const { language } = useLanguage();
  const token = localStorage.getItem('access_token');
  const { data: result, loading, error, refetch } = CreateAxiosGetRequest({
    url: `blog/loadBlogs`,
    params: {
      page,
      size,
      section,
      flag,
      language: language.toUpperCase(),
    },
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
      authorization: token ? `Bearer ${token}` : undefined
    },
  });

  return { result, loading, error, refetch };
};

export const useGetBlog = (id: any) => {
  const token = localStorage.getItem('access_token');
  const { language } = useLanguage();
  const { data: result, loading, error, refetch } = CreateAxiosGetRequest({
    url: `blog/loadBlog`,
    params: {
      Id: id,
      language: language.toUpperCase(),
    },
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
      authorization: token ? `Bearer ${token}` : undefined
    },
  });

  return { result, loading, error, refetch };
};

export const useLikeDislikeBlog = (params?: favoriteBlog) => {
  const token = localStorage.getItem('access_token');
  const { language } = useLanguage();
  const { responseData: result, loading, error, refetch } = CreateAxiosPostRequest({
    url: `blog/likeDislikeBlog`,
    data: params,
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
      authorization: `Bearer ${token}`,

    },
    enabled: false,

  });

  return { result, loading, error, refetch };
};

export const addBlogToFavorite = (params?: favoriteBlog) => {
  const token = localStorage.getItem('access_token');

  const { responseData: result, loading, error, refetch } = CreateAxiosPostRequest({
    url: `blog/addToFavourite`,
    data: params,
    headers: {
      section: "H",
      channel: "w",
      authorization: `Bearer ${token}`,
    },
    enabled: false,
  });

  return { result, loading, error, refetch };
};


export const deleteBlogToFavorite = (params?: favoriteBlog) => {
  const token = localStorage.getItem('access_token');

  const { responseData: result, loading, error, refetch } = CreateAxiosPostRequest({
    url: `blog/deleteFavourite`,
    data: params,

    headers: {
      section: "H",
      channel: "w",
      authorization: `Bearer ${token}`,

    },
    enabled: false,

  });

  return { result, loading, error, refetch };
};

export const deleteAllBlogToFavorite = () => {
  const token = localStorage.getItem('access_token');

  const { responseData: result, loading, error, refetch } = CreateAxiosPostRequest({
    url: `blog/deleteAllFavourite`,

    headers: {
      section: "H",
      channel: "w",
      authorization: `Bearer ${token}`,
    },
    enabled: false,

  });

  return { result, loading, error, refetch };
};


export const useGetFavBlogs = ({ page = 0, size = 3 }) => {
  const { language } = useLanguage();
  const { data: result, loading, error, refetch } = CreateAxiosGetRequest({
    url: `blog/loadUserFavProperties`,
    params: {
      page,
      size,
      language: language.toUpperCase(),
    },
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
    },
  });

  return { result, loading, error, refetch };
};

export const useGetPropBlogReason = (params: BaseParam) => {
  const { data: reasons, loading: propertyLoading, error: propertyError, refetch } = CreateAxiosGetRequest<ReportCategoryResponse>({
    url: `systemData/loadPropReportCategory`,
    params: { ...params }
  });

  return { reasons, propertyLoading, propertyError, refetch };
};
export const usePostBlogReport = (params?: ReportForm, enable: boolean = true) => {
  const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
    url: `blog/blogReport`,
    data: params,
    enabled: enable
  });


  return { responseData, loading, error, refetch };
};


export const useGetSections = () => {
  const { language } = useLanguage();
  const { data: result, loading, error, refetch } = CreateAxiosGetRequest({
    url: `systemData/loadSection`,
    params: {
      language: language.toUpperCase(),
    },
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
    },
  });

  return { result, loading, error, refetch };
};


