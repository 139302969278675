import React, { useEffect, useState } from "react";
import { Modal, Radio, Button, Spin } from "antd";
import "./SettingsModal.scss";
import {
    useGetAreaMeasurement,
    useGetCurrencies,
    useGetModes,
    useGetSortLists,
} from "../../../services/settingsServices";
import { SettingsContextProps, useSettings } from "../../../context/SettingsContext";
import { useTranslation } from "react-i18next";

interface SettingsModalProps {
    visible: boolean;
    onCancel: () => void;
    onApply: (selectedOption: string) => void;
    activeSetting: string | null;
    selectedOption: string;
    onOptionChange: (value: string) => void;
}

interface Option {
    value: string;
    label: string;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
    visible,
    onCancel,
    onApply,
    activeSetting,
    selectedOption: propSelectedOption,
    onOptionChange,
}) => {
    const { t } = useTranslation()
    const { area, currency, mode, sort, updateSetting } = useSettings();
    const [options, setOptions] = useState<Option[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const { result: currenciesResult, loading: currenciesLoading } = useGetCurrencies();
    const { result: sortResult, loading: sortLoading } = useGetSortLists();
    const { result: areaResult, loading: areaLoading } = useGetAreaMeasurement();
    const { result: modeResult, loading: modeLoading } = useGetModes();
    useEffect(() => {
        if (activeSetting) {
            const currentValue =
                activeSetting === "area"
                    ? area
                    : activeSetting === "currency"
                        ? currency
                        : activeSetting === "mode"
                            ? mode
                            : activeSetting === "sort"
                                ? sort
                                : "";

            handleOptionChange(currentValue);
        }
    }, [activeSetting, area, currency, mode, sort, onOptionChange]);

    useEffect(() => {
        setLoading(true);
        switch (activeSetting) {
            case "currency":
                if (currenciesResult) {
                    setOptions(
                        currenciesResult?.result?.map((currency: any) => ({
                            value: currency?.abbreviation,
                            label: currency.name,
                        }))
                    );
                }
                setLoading(currenciesLoading);
                break;
            case "sort":
                if (sortResult) {
                    setOptions(
                        sortResult?.map((sortOption) => ({
                            value: sortOption.name,
                            label: sortOption.label,
                        }))
                    );
                }
                setLoading(sortLoading);
                break;
            case "area":
                if (areaResult) {
                    setOptions(
                        areaResult?.result?.map((areaOption: any) => ({
                            value: areaOption?.abbreviation,
                            label: areaOption?.name,
                        }))
                    );
                }
                setLoading(areaLoading);
                break;
            case "mode":
                if (modeResult) {
                    setOptions(
                        modeResult?.map((modeOption) => ({
                            value: modeOption.name,
                            label: modeOption.label,
                        }))
                    );
                }
                setLoading(modeLoading);
                break;
            default:
                setOptions([]);
                setLoading(false);
        }
    }, [activeSetting, currenciesResult, currenciesLoading, sortResult, sortLoading, areaResult, areaLoading]);

    const handleOptionChange = (value: string) => {
        setSelectedOption(value);
        onOptionChange(value);
    };

    const handleApply = () => {
        if (activeSetting && selectedOption) {
            onApply(selectedOption);
            updateSetting(activeSetting as keyof SettingsContextProps, selectedOption);
        }
    };

    const renderContent = () => {
        if (loading) {
            return (
                <div className="loading-spinner">
                    <Spin size="large" />
                </div>
            );
        }

        return (
            <div className="headerAndradios">
                <h3 className="settingHeaderName">
                    {activeSetting === "area"
                        ? t("settings.AreaMeasurement")
                        : activeSetting === "currency"
                            ? t("settings.ChangeCurrency")
                            : activeSetting === "mode"
                                ? t("settings.ChangeMode")
                                : activeSetting === "sort"
                                    ? t("settings.SortLists")
                                    : ""}
                </h3>
                <Radio.Group
                    onChange={(e) => handleOptionChange(e.target.value)}
                    value={selectedOption}
                    className="ant-radio-group"
                >
                    {options.map((option) => (
                        <Radio key={option.value} value={option.value} className="ant-radio-wrapper">
                            {option.label}
                        </Radio>
                    ))}
                </Radio.Group>
            </div>
        );
    };
    useEffect(() => {
        const lockScroll = () => {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden';
        };

        const unlockScroll = () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };

        if (visible) {
            lockScroll();
        } else {
            unlockScroll();
        }
        return () => {
            unlockScroll();
        };
    }, [visible]);

    return (
        <Modal

            modalRender={(modal) => (
                <div className="modalCard">
                    {modal}
                </div>
            )}
            visible={visible}
            getContainer={false}
            onCancel={onCancel}
            footer={[
                <div className="modal-footer" key="footer">
                    <Button
                        type="primary"
                        onClick={handleApply}
                        className="ant-btn ant-btn-primary"
                    >
                        {t("settings.Apply")}
                    </Button>
                </div>,
            ]}
            centered
            className="settings-modal"
            bodyStyle={{ padding: "0px" }}
        >
            {renderContent()}
        </Modal>
    );
};

export default SettingsModal;
