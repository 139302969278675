import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import './shareModal.scss';
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  message?: any;
  id?: any
  checkPage?: any
}

const ShareModal: React.FC<ShareModalProps> = ({ checkPage, isOpen, onClose, message, id }) => {
  const [pathCopy, setPathCopy] = useState(false);
  const location = useLocation();
  const currentUrl = window.location.href;
  const { t } = useTranslation();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      setPathCopy(true);
      setTimeout(() => {
        setPathCopy(false);
      }, 2000);
    }).catch(err => {
      console.error('Failed to copy the link: ', err);
    });
  };


  const shareViaWhatsApp = () => {
    let updatedUrl = currentUrl;

    if (location.pathname === '/') {
      updatedUrl = `${currentUrl}property`;
    } else if (sessionStorage.getItem('aiModal') === "true") {
      updatedUrl = `${window.location.origin}/property`;
    } else if (updatedUrl.includes("properties")) {
      updatedUrl = `${window.location.origin}/property`;
    } else if (updatedUrl.includes("blogs")) {
      updatedUrl = updatedUrl.replace("blogs", "blog");
    }
    else if (updatedUrl.includes("agentProfile") && checkPage === true) {
      updatedUrl = updatedUrl.replace(/agentProfile\/\d+/, "property");
    }


    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(`${message} ${updatedUrl}/${id ? id : ''}`)}`;
    window.open(url, '_blank');
  };
  const shareViaTelegram = () => {
    let updatedUrl = currentUrl;

    if (location.pathname === '/') {
      updatedUrl = `${currentUrl}property`;
    } else if (sessionStorage.getItem('aiModal') === "true") {
      updatedUrl = `${window.location.origin}/property`;
    } else if (updatedUrl.includes("properties")) {
      updatedUrl = `${window.location.origin}/property`;
    } else if (updatedUrl.includes("blogs")) {
      updatedUrl = updatedUrl.replace("blogs", "blog");
    }
    else if (updatedUrl.includes("agentProfile") && checkPage === true) {
      updatedUrl = updatedUrl.replace(/agentProfile\/\d+/, "property");
    }
    const url = `https://t.me/share/url?url=${encodeURIComponent(`${message} ${updatedUrl}/${id ? id : ''}`)}`;
    window.open(url, '_blank');
  };

  const shareViaMessenger = () => {
    let updatedUrl = currentUrl;
    if (location.pathname === '/') {
      updatedUrl = `${currentUrl}property`;
    } else if (sessionStorage.getItem('aiModal') === "true") {
      updatedUrl = `${window.location.origin}/property`;
    } else if (updatedUrl.includes("properties")) {
      updatedUrl = `${window.location.origin}/property`;
    } else if (updatedUrl.includes("blogs")) {
      updatedUrl = updatedUrl.replace("blogs", "blog");
    } else if (updatedUrl.includes("agentProfile") && checkPage === true) {
      updatedUrl = updatedUrl.replace(/agentProfile\/\d+/, "property");
    }
    const finalUrl = `${updatedUrl}${id ? `/${id}` : ''}`;
    const encodedUrl = encodeURIComponent(finalUrl);
    const encodedMessage = encodeURIComponent(message);
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${encodedMessage}`;
    window.open(url, '_blank');
  };



  const shareViaTwitter = () => {
    let updatedUrl = currentUrl;

    if (location.pathname === '/') {
      updatedUrl = `${currentUrl}property`;
    } else if (sessionStorage.getItem('aiModal') === "true") {
      updatedUrl = `${window.location.origin}/property`;
    } else if (updatedUrl.includes("properties")) {
      updatedUrl = `${window.location.origin}/property`;
    } else if (updatedUrl.includes("blogs")) {
      updatedUrl = updatedUrl.replace("blogs", "blog");
    }
    else if (updatedUrl.includes("agentProfile") && checkPage === true) {
      updatedUrl = updatedUrl.replace(/agentProfile\/\d+/, "property");
    }
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(`${message} ${updatedUrl}/${id ? id : ''}`)}`;
    window.open(url, '_blank');
  };


  return (
    <>

      <div className="shareModal">
        <div className={`modal-overlay ${isOpen ? 'open' : onclose && 'open'}`}>
          <div className="modal-content">
            <div className="modal-header">
              <p className="shareText"> {t("common.Share")} </p>
              <button className="close-btn" onClick={onClose}>
                <CloseOutlined style={{ fontSize: '20px' }} />
              </button>
            </div>
            <div className="share-buttons">
              <>
                <button onClick={shareViaWhatsApp}>
                  <img src='/images/apts/icons/whatsAppIcon.svg' alt="WhatsApp" />
                  <span>{t("shareModal.whatsApp")}</span>
                </button>
                <button onClick={shareViaTelegram}>
                  <img src='/images/apts/icons/telegramIcon.svg' alt="Telegram" />
                  <span>{t("shareModal.Telegram")}</span>
                </button>
                <button onClick={shareViaMessenger}>
                  <img src='/images/apts/icons/facebookShare.svg' alt="Messenger" />
                  <span>{t("shareModal.Messenger")}</span>
                </button>
                <button onClick={shareViaTwitter}>
                  <img src='/images/apts/icons/XIcon.svg' alt="Twitter" />
                  <span>{t("shareModal.Twitter")}</span>
                </button>
              </>
            </div>
            <div className="modal-footer">
              <div className="s">
                <p className="footer-text">{t("shareModal.MustSee")}</p>
                <button style={{ padding: "0" }} className="copy-link-btn" onClick={handleCopyLink}>
                  <img src='/images/apts/icons/linkIcon.svg' alt="Link Icon" />
                  {pathCopy ? t("shareModal.Copied") : t("shareModal.Copylink")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>


  );
};

export default ShareModal;
