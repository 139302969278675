import './advertisingCard.scss';


interface AdvertisingCardProps {
    imageUrl?: string;
    linkUrl?: string;
    altText?: string;
}

const AdvertisingCard = ({ imageUrl, linkUrl, altText }: AdvertisingCardProps) => {
    return (
        <div className="advertising-card">
            <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                <img src={imageUrl} alt={altText} className="advertising-image" />
            </a>
        </div>
    );
};

export default AdvertisingCard;
