import { Pagination } from 'antd';
import './PaginationComponent.scss';
import { useLanguage } from '../../context/LanguageContext';

interface PaginationComponentProps {
    current: number;
    total: number;
    pageSize: number;
    onChange: (page: number) => void;
}

export default function PaginationComponent(props: PaginationComponentProps) {
    const { language } = useLanguage();
    const direction = language === 'ar' ? 'rtl' : 'ltr';

    return (
        <Pagination
            current={props.current + 1}
            total={props.total}
            pageSize={props.pageSize}
            onChange={props.onChange}
            align="center"
            showSizeChanger={false}
            className={`custom-pagination ${direction === 'rtl' ? 'rtl-pagination' : ''}`}
        />
    );
};
