import React, { useEffect, useState } from 'react';
import './actionButtons.scss';
import MenuDropdown from '../MenuDropdownProps/MenuDropdownProps';
import CustomTooltip from '../../Tooltip/tooltip';
import { useTranslation } from 'react-i18next';
import ShareModal from '../../Modals/ShareModal/ShareModal';
import { useNativeShare } from '../../Modals/ShareModal/nativeShare';

interface ActionButtonsProps {
    isMobile: boolean;
    latitude?: string;
    longitude?: string;
    isFavorited?: boolean;
    onFavoriteToggle?: (favorited: boolean) => void;
    onReportClick: () => void;
    property?: any
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ isMobile = false, latitude, longitude, isFavorited = false, onFavoriteToggle, onReportClick, property }) => {
    const [isMobileNative, setIsMobileNative] = useState(false);
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
        if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
            setIsMobileNative(true);
        }
    }, []);
    const handleNativeShare = useNativeShare(property?.whatsAppMsg);
    const [isFavorite, setIsFavorite] = useState(isFavorited);
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const { t } = useTranslation()
    const handleMapClick = () => {
        if (latitude && longitude) {
            const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
            window.open(googleMapsUrl, '_blank');
        } else {
            alert('Location is not available');
        }
    };

    const handleFavoriteClick = () => {
        const newFavoriteState = !isFavorite;
        setIsFavorite(newFavoriteState);
        if (onFavoriteToggle) {
            onFavoriteToggle(newFavoriteState);
        }
    };

    const handleReportClick = () => {
        if (onReportClick) onReportClick();
    };

    const handleShareClick = () => {
        if (isMobileNative) {
            handleNativeShare()
        }
        else {
            setIsShareModalVisible(true);
        }
    };

    const handleCloseShareModal = () => {
        setIsShareModalVisible(false);
    };

    return (
        <div className="action-btns">
            {latitude && longitude && (
                <CustomTooltip title={t("common.ViewonMap")}>
                    <img
                        src={isMobile ? '/images/map_svgrepo.com-white.svg' : '/images/map_svgrepo.com.svg'}
                        alt="Map"
                        onClick={handleMapClick}
                        style={{ cursor: 'pointer' }}
                    />
                </CustomTooltip>
            )}
            <CustomTooltip title={isFavorite ? t("common.RemovefromFavorites") : t("common.AddtoFavorites")}>
                <img
                    className={`favorite-btn ${isFavorite ? 'filled' : 'empty'}`}
                    src={isFavorite
                        ? (isMobile ? '/images/apts/icons/love-filled-white.svg' : '/images/apts/icons/love-svgrepo-com-filled-white.svg')
                        : (isMobile ? '/images/apts/icons/love-white.svg' : '/images/apts/icons/love-svgrepo-com-white.svg')}
                    alt="Favorite"
                    onClick={handleFavoriteClick}
                    style={{ cursor: 'pointer' }}
                />
            </CustomTooltip>
            <MenuDropdown
                isMobile={isMobile}
                onShareClick={handleShareClick}
                onReportClick={handleReportClick}
            />
            <ShareModal isOpen={isShareModalVisible} onClose={handleCloseShareModal} message={property?.whatsAppMsg} />

        </div>
    );
};

export default ActionButtons;
