
import React from "react";
import './header.scss'
interface HeaderPagesProps {
  h1: string;
  title?: string;
}

const HeaderPages: React.FC<HeaderPagesProps> = ({ h1 }) => {
  return (
    <div className="group">
      <p className="h1">{h1}</p>
      
    </div>
  );
};

export default HeaderPages;
