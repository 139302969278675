import React, { useEffect, useState } from "react";
import { AutoComplete, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { convertObjectToImageString } from "../../../helpers/helpers";

interface SearchOption {
  id: number | string;
  name: string | { text: string; index: [] };
  image?: { url: string };
}

interface TextSearchFilterProps {
  className?: string;
  options: SearchOption[];
  placeholder: string;
  onChange: (value: string) => void;
  value: string;
  setText?: any;
  loadingSuggestion?: boolean;
}

const filterOptions = (
  options: SearchOption[],
  searchText: string
): SearchOption[] => {
  return options.filter((option) => {
    const name =
      typeof option.name === "string" ? option.name : option.name.text;
    return name.toLowerCase().includes(searchText.toLowerCase());
  });
};

const TextSearchFilter: React.FC<TextSearchFilterProps> = ({
  loadingSuggestion,
  setText,
  placeholder,
  onChange,
  options,
  value,
  className,
}) => {
  const [currentPlaceholder, setCurrentPlaceholder] =
    useState<string>(placeholder);
  const [filteredOptions, setFilteredOptions] =
    useState<SearchOption[]>(options);
  const [displayValue, setDisplayValue] = useState<string>("");
  const [valueId, setValueId] = useState<string>(value);

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const mapOptionsToAutoComplete = (options: any) => {
    if (options.length === 0) {
      return [
        {
          value: "no-agents",
          label: (
            <div style={{ padding: "10px" }}>
              {loadingSuggestion ? "Searching" : "No Data Found"}
            </div>
          ),
        },
      ];
    }
    return options.map((option: any, index: number) => {
      const name =
        typeof option.name === "string" ? option.name : option.name.text;
      const img = option.image?.url;
      const lowercasedName = name.toLowerCase();
      const lowercasedSearchTerm = displayValue.toLowerCase();
      const nameArray = name.split("");
      const nameWithBold = nameArray.map((char: string, idx: number) => {
        const isBold = lowercasedSearchTerm.includes(lowercasedName[idx]);
        return (
          <span
            key={idx}
            style={{
              fontWeight: isBold ? "bold" : "var(--weight-400)",
              color: "var(--text-color-light)",
              fontSize: "var(--font-14)",
              fontFamily: "var(--primary-font)",
            }}
          >
            {char}
          </span>
        );
      });
      return {
        value: `${option.id}:${name}`,
        label: (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "16px 0px 16px 30px",
                gap: "9px",
              }}
            >
              <img
                className="agent-img"
                src={
                  convertObjectToImageString(option?.image) ??
                  "/images/agents/1.png"
                }
              />
              <div>{nameWithBold}</div>
            </div>
            {index < options.length - 1 && (
              <div
                style={{
                  width: "100%",
                  height: "1.2px",
                  backgroundColor: "var(--box-gray)",
                }}
              />
            )}
          </div>
        ),
      };
    });
  };
  useEffect(() => {
    setCurrentPlaceholder(placeholder);
  }, [placeholder]);







  const handleSearch = (searchText: string) => {
    const filtered = filterOptions(options, searchText);
    setFilteredOptions(filtered);
    setDisplayValue(searchText);
    setText && setText(searchText);
    onChange("");
    setShowOptions(filtered.length > 0);
    if (searchText === "") {
      setText && setText("all");
    }
  };

  const handleSelect = (selectedValue: string) => {
    const [selectedId, selectedName] = selectedValue.split(":");
    setDisplayValue(selectedName);
    onChange(selectedId);
    setShowOptions(false);
  };

  const handleClear = () => {
    setValueId("")
    setDisplayValue("");
    onChange("");
    setShowOptions(false);
    setText && setText("all");


  };

  const handleFocus = () => {
    setCurrentPlaceholder("");
  };

  const handleBlur = () => {
    if (!value) setCurrentPlaceholder(placeholder);
  };

  useEffect(() => {

    setFilteredOptions(options);
    if (!displayValue && valueId) {
      const selectedOption = options.find(
        (option) => option.id.toString() === valueId
      );

      if (selectedOption) {
        const name =
          typeof selectedOption.name === "string"
            ? selectedOption.name
            : selectedOption.name.text;
        setDisplayValue(name);
      } else {
        setDisplayValue("");
      }
    }
  }, [options, value, displayValue]);


  useEffect(() => {
    const handleButtonClick = () => {
      setDisplayValue("");
      setValueId("")
    };

    // Add event listener to the document
    document.addEventListener("resetButtonClick", handleButtonClick);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("resetButtonClick", handleButtonClick);
    };
  }, []);
  return (
    <AutoComplete
      className={`custom-autocomplete ${className}`}
      options={mapOptionsToAutoComplete(filteredOptions)}
      onSearch={handleSearch}
      onSelect={handleSelect}
      value={displayValue}
      filterOption={false}
      popupMatchSelectWidth={371}
      onClear={handleClear}
    >
      <Input
        placeholder={placeholder}
        allowClear


        prefix={<SearchOutlined />}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClear={handleClear}
        onChange={(e) => handleSearch(e.target.value)}
      />
    </AutoComplete>
  );
};

export default TextSearchFilter;
