import React, { useState, useEffect, useCallback } from 'react';
import { Layout } from 'antd';
import './TermsAndConditions.scss';
import TC from '../../components/TermsAndCondition/t&c';
import PremiumListing from '../../components/TermsAndCondition/Premium Listing T&C';
import LiveViewing from '../../components/TermsAndCondition/Live Viewing';
import LiveViewingTOU from '../../components/TermsAndCondition/Live Viewing (ToU)';
import CustomerAgreement from '../../components/TermsAndCondition/Customer Agreement';
import BackOffice from '../../components/TermsAndCondition/Backoffice';
import { useTranslation } from 'react-i18next';


const { Header, Content } = Layout;

interface Section {
  id: string;
  label: string;
  description: any;
}



const TermsAndConditions = () => {
  const {t}=useTranslation()
  const sections: Section[] = [
    {
      id: 'section1',
      label: t("termsAndCondition.T&C"),
      description: <TC/>,
    },
    {
      id: 'section2',
      label: t("termsAndCondition.PremiumListing"),
      description: <PremiumListing />,
    },
    {
      id: 'section3',
      label:t("termsAndCondition.LiveViewing"),
      description: <LiveViewing />,
    },
    {
      id: 'section4',
      label: t("termsAndCondition.LiveViewingTOU"),
      description: <LiveViewingTOU />,
    },
    {
      id: 'section5',
      label:t("termsAndCondition.CustomerAgreement"),
      description: <CustomerAgreement />,
    },
    {
      id: 'section6',
      label: t("termsAndCondition.Backoffice"),
      description: <BackOffice />,
    },
  ];
  const [activeSection, setActiveSection] = useState<string>('section1');

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.getBoundingClientRect().top + window.scrollY;
      const offset = 150;
      window.scrollTo({
        top: offsetTop - offset,
        behavior: 'smooth',
      });
    }
    setActiveSection(id);
  };


  const handleScroll = useCallback(() => {
    const buffer = 250;

    const sectionOffsets = sections.map((section) => {
      const element = document.getElementById(section.id);
      if (element) {
        return { id: section.id, top: element.offsetTop };
      }
      return { id: '', top: 0 };
    });

    const scrollPosition = window.scrollY + buffer;
    const active = sectionOffsets.find(
      (section) => scrollPosition >= section.top && scrollPosition < section.top + document.getElementById(section.id)?.offsetHeight!
    );

    if (active) {
      setActiveSection(active.id);
    }
  }, []);

  useEffect(() => {
    const handleScrollThrottled = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener('scroll', handleScrollThrottled);
    return () => {
      window.removeEventListener('scroll', handleScrollThrottled);
    };
  }, [handleScroll]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Layout className='termsAndCondition'>
      <Header className="sticky-buttons">
        {sections.map((section) => (
          <button
            className={`custom-button ${activeSection === section.id ? 'active' : ''}`}
            key={section.id}
            onClick={() => scrollToSection(section.id)}
            style={{
              marginRight: '10px',
            }}
          >
            {section.label}
          </button>
        ))}
      </Header>
      <Content className=''>
        {sections.map((section, index) => (
          <div key={section.id} id={section.id} className="sectionDetails">
            <h2 className='title'>{section.label}</h2>
            <p className='content'>{section.description}</p>
          </div>
        ))}
      </Content>
    </Layout>
  );
};

export default TermsAndConditions;
