import React, { useState } from "react";
import './contactUs.scss';
import { useTranslation } from "react-i18next";
import { Button } from 'antd';
import { useLanguage } from "../../context/LanguageContext";
import { ContactUsForm } from "../../helpers/types/general";
import { useGetEmirates } from "../../services/filtersServices";
import ContactUsModal from "../../components/Modals/ContactUsModal/JoinNow";

const JoinNow: React.FC = () => {

    const { language } = useLanguage();
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const direction = language === 'ar' ? 'rtl' : 'ltr';

    const [formDataRequest, setFormDataRequest] = useState<ContactUsForm>({
        name: '',
        companyName: '',
        emirateID: undefined,
        phoneNumber: '',
        email: ''
    });

    const { emirates } = useGetEmirates({ language: language.toUpperCase() });


    return (
        <div className="contact-us-section">
            <ContactUsModal
                emirates={emirates?.result || []}
                formRequest={formDataRequest}
                setFormRequest={setFormDataRequest}
                isVisible={isModalVisible}
                title={t('ContactUs.title')}
                description={t('ContactUs.GetInTouchModal')}
            />
        </div>
    );
};

export default JoinNow;
