import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal, Input, Button, message } from "antd";
import "./SaveSearchModal.scss"
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useEditSaveSearch } from "../../../services/userServices";
import { useAuthContext } from "../../../auth/AuthProvider";
interface SaveSearchModalProps {
    isOpen: boolean;
    setIsModalOpen?: Dispatch<SetStateAction<boolean>>

    isEditSearchName?: boolean;
    nameValue?: string;
    idSearchName?: number;
    onClose: () => void;
    onSave?: (body: { name: string; searchjson: string }) => void;
    // searchjson: string;
}

const SaveSearchModal: React.FC<SaveSearchModalProps> = ({
    isOpen,
    setIsModalOpen,
    onClose,
    onSave,
    isEditSearchName = false,
    idSearchName,
    nameValue = ""
    // searchjson,
}) => {
    const [saveSearchName, setSaveSearchName] = useState<string>(nameValue);
    const { t } = useTranslation()
    const location = useLocation();
    const { authToken } = useAuthContext();

    const { refetch: EditSaveSearch } = useEditSaveSearch();

    // Function to get query parameters from the URL and return as a query string
    const getQueryParamsAsString = () => {
        const queryString = location.search; // Get the query string
        const urlParams = new URLSearchParams(queryString);
        // Delete specified parameters
        const paramsToDelete = [
            ""// 'typeIDs', 'bedroomNums', 'bathroomNums', 'orderByMode', 'frequency', 'minPrice', 'maxPrice', 'featureIDs', 'maxSize', 'minSize'
        ];
        paramsToDelete.forEach(param => urlParams.delete(param));
        // urlParams.delete('orderByMode');
        return urlParams.toString(); // Return the query string directly
    };
    const queryString = getQueryParamsAsString()
    const handleSave = async () => {
        if (!saveSearchName.trim()) {
            message.warning("Please enter a search name!");
            return;
        }
        const body = { name: saveSearchName, searchjson: queryString };
        if (isEditSearchName) {
            try {
                await EditSaveSearch({
                    data: {
                        Id: idSearchName,
                        name: saveSearchName
                    },
                    headers: {
                        channel: "w",
                        authorization: `Bearer ${authToken}`,
                    },
                });
                message.success("Edit Search Item successfully!");
                setIsModalOpen && setIsModalOpen(false)
            } catch (error) {
                message.error("An error occurred while Editing Search Item.");
            }
        } else {
            onSave && onSave(body);

        }
        setSaveSearchName(""); // Reset the input field
    };
    const renderContent = () => {


        return (
            <div className="headerAndradios">
                <h3 className="settingHeaderName">
                    {isEditSearchName ? `${t("saveModal.PleaseRename")} - ${nameValue}` : t("saveModal.Pleasename")}
                </h3>
                <Input
                    placeholder={isEditSearchName ? `${t("saveModal.ReNamesearch")}` : t("saveModal.Namesearch")}
                    value={saveSearchName}
                    className='InputBoxes'
                    onChange={(e) => setSaveSearchName(e.target.value)}
                />
            </div>
        );
    };
    useEffect(() => {
        const lockScroll = () => {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden';
        };

        const unlockScroll = () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };

        if (isOpen) {
            lockScroll();
        } else {
            unlockScroll();
        }
        return () => {
            unlockScroll();
        };
    }, [isOpen]);
    return (
        <Modal
            getContainer={false}
            centered
            modalRender={(modal) => (
                <div className="modalCard">
                    {modal}
                </div>

            )}
            open={isOpen}
            onCancel={onClose}
            className="saveModal"

            footer={[

                <Button className="login-button" key="save" type="primary" onClick={handleSave}>
                    {t("common.save")}
                </Button>,
            ]}
        >

            {renderContent()}
        </Modal>
    );
};

export default SaveSearchModal;
