import React, { FC } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form } from "antd";
import "./PhoneNumberInput.scss";
import { useTranslation } from "react-i18next";

interface PhoneNumberInputProps {
    value: string;
    onChange: (value: string) => void;
}

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({ value, onChange }) => {
    const { i18n } = useTranslation();
    return (
        <Form.Item>
            <PhoneInput
                country={"ae"}
                value={value}
                onChange={onChange}
                inputClass="phone-input"
                buttonClass="country-button"
                containerClass="phone-input-container"
                inputStyle={{
                    borderRadius: "30px",
                    width: "100%",
                    height: "46px",
                    border: "1px solid #ccc",
                    fontSize: "16px",
                }}
                buttonStyle={{
                    borderRadius: "30px 0 0 30px",
                    width: "62px",
                    display: "flex",
                    justifyContent: "center",
                    background: "transparent",
                    transition: "none",
                    direction: "ltr",
                }}
                dropdownStyle={{
                    borderRadius: "8px",
                    position: "absolute",
                    left: "50%",

                }}
            />
        </Form.Item>
    );
};

export default PhoneNumberInput;
