import React from 'react';
import './infoSectionQRCodeComponent.scss';

export interface InfoItem {
    label: string;
    value?: string | number;
    iconUrl?: string;
    qrCodeUrl?: string;
}

interface InfoSectionQRCodeComponentProps {
    title: string;
    items: InfoItem[];
}

const InfoSectionQRCodeComponent: React.FC<InfoSectionQRCodeComponentProps> = ({ title, items }) => {
    const qrCodeItem = items.find(item => item.qrCodeUrl);
    const nonQrCodeItems = items.filter(item => !item.qrCodeUrl);

    return (
        <div className="info-section-details">
            <span className="section-title">{title}</span>
            <div className="grid-container">
                <div className="items-grid small-grid">
                    {nonQrCodeItems.map((item, index) => {
                        const isLastRowItem = index >= nonQrCodeItems.length - 1;
                        return <div key={index} className={`info-item ${isLastRowItem || !item.value ? 'last-in-column' : ''}`}>
                            {item.iconUrl && (
                                <img src={item.iconUrl} alt={item.label} className="icon" />
                            )}
                            <span className="label">{item.label}</span>
                            {item.value && <span className="value">{item.value}</span>}
                        </div>
                    })}
                </div>

                {qrCodeItem && (
                    <div className="qr-code-column">
                        <img src={qrCodeItem.qrCodeUrl} alt="QR Code" className="qr-code" />
                        <span className="label">{qrCodeItem.label}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InfoSectionQRCodeComponent;
