import React from 'react';
import HeaderPages from '../pagesFooterHeaderSection/header';
const Career = () => {
  return (
    <div >
    <HeaderPages h1="Career" title=''/>

    </div>
  );
};

export default Career;
