import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import './joinNow.scss'
const ContactUs: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const handleNavigate = () => {
        navigate('/join-us')
    }

    return (
        <div className="contact-us-home-section">
            <div className="text-container ">
                <h2>{t('ContactUs.listOn')}</h2>
                <Button type="primary" className="request-button" onClick={handleNavigate}>
                    {t('ContactUs.join')}
                </Button>
            </div>
        </div>
    );
};

export default ContactUs;
