import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { LanguageProvider } from './context/LanguageContext';
import { AuthProvider } from './auth/AuthProvider';
import { Auth0CustomProvider } from './auth/Auth0CustomProvider';
import SettingsProvider from './context/SettingsContext';
import { HelmetProvider } from 'react-helmet-async';


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <LanguageProvider>
      <Auth0CustomProvider>
        <SettingsProvider>
          <App />
        </SettingsProvider>
      </Auth0CustomProvider>
    </LanguageProvider>
  </React.StrictMode>
);

reportWebVitals();
