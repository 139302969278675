import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useGetSections } from '../services/blogService';
import { useTranslation } from 'react-i18next';

interface FilterContextType {
    filters?: { id: string; name: string }[];
    sectionsResults: any;
    selectedFilter: string;
    setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
    handleFilterSelect: (filterId: string, name: string) => void;
    selectedFilterName: string;
    setSelectedFilterName: React.Dispatch<React.SetStateAction<string>>;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

interface FilterProviderProps {
    children: ReactNode;
}

export const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
    const { t, i18n } = useTranslation();
    const { result: sectionsResults, loading: sectionsLoading, error: sectionsErr, refetch: sections } = useGetSections();
    const [selectedFilter, setSelectedFilter] = useState<string>('all');
    const [selectedFilterName, setSelectedFilterName] = useState<string>('');

    const handleFilterSelect = (filterId: string, name: string) => {
        setSelectedFilter(filterId);
        setSelectedFilterName(name);
        sessionStorage.setItem('selectedFilter', filterId);
        sessionStorage.setItem('selectedFilterName', name);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const selectedFilterId = sessionStorage.getItem('selectedFilter');
        if (selectedFilterId && sectionsResults?.result?.length > 0) {
            const matchingFilter = sectionsResults.result.find((section: any) => section.id.toString() === selectedFilterId);
            if (matchingFilter) {
                const translatedName = matchingFilter.name;
                setSelectedFilterName(translatedName);
                sessionStorage.setItem('selectedFilterName', translatedName);
            }
        }
    }, [i18n.language, sectionsResults]);

    return (
        <FilterContext.Provider value={{ setSelectedFilterName, selectedFilterName, sectionsResults, selectedFilter, setSelectedFilter, handleFilterSelect }}>
            {children}
        </FilterContext.Provider>
    );
};

export const useFilter = (): FilterContextType => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error('useFilter must be used within a FilterProvider');
    }
    return context;
};
