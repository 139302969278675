import React, { useState, useEffect } from 'react';
import '../styles/MinMaxInput.scss';
import { useTranslation } from 'react-i18next';
import { message } from 'antd'; // Import Ant Design's message component

interface MinMaxInputProps {
    className?: string;
    minPlaceholder: string;
    maxPlaceholder: string;
    minValue?: number;
    maxValue?: number;
    defaultMaxValue?: number;
    defaultMinValue?: number;
    onMinChange: (value: number | string) => void;
    onMaxChange: (value: number | string) => void;
}

const MinMaxInput: React.FC<MinMaxInputProps> = ({
    className,
    minPlaceholder,
    maxPlaceholder,
    minValue = '',
    maxValue = '',
    defaultMaxValue = 10000,
    defaultMinValue = 0,
    onMinChange,
    onMaxChange,
}) => {
    const { t } = useTranslation();
    const [min, setMin] = useState<string | number>(minValue);
    const [max, setMax] = useState<string | number>(maxValue);
    const [error, setError] = useState<string | null>(null);

    const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const numericValue = Number(value);

        if (numericValue > Number(max)) {
            return;
        }

        setMin(value);
        onMinChange(value);
    };

    const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const numericValue = Number(value);


        setMax(value);
        onMaxChange(value);

        if (numericValue < Number(min) && min !== '') {
            setMin(0);

            message.error('Maximum value cannot be less than minimum value');
            return;
        }
    };




    useEffect(() => {
        const handleButtonClick = () => {

            setMin(defaultMinValue);
            setMax(defaultMaxValue);
        };

        // Add event listener to the document
        document.addEventListener("advancedResetButtonClick", handleButtonClick);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener("advancedResetButtonClick", handleButtonClick);
        };
    }, []);


    useEffect(() => {
        if (!minValue || minValue === 0) {
            setMin(defaultMinValue);
        }
        if (!maxValue || maxValue === 0) {
            setMax(defaultMaxValue);

        }


    }, [minValue, maxValue]);

    return (
        <div className={`min-max-input ${className}`}>
            <div className="min-max-input__label">
                <span>{t('filters.Minimum')}</span>
                <input
                    type="number"
                    placeholder={minPlaceholder}
                    value={min}
                    onChange={handleMinChange}
                    className="min-max-input__field"
                />
            </div>
            <div className="min-max-input__label">
                <span>{t('filters.Maximum')}</span>
                <input
                    type="number"
                    placeholder={maxPlaceholder}
                    value={max}
                    onChange={handleMaxChange}
                    className="min-max-input__field"
                />
            </div>
        </div>
    );
};

export default MinMaxInput;