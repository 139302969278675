import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Input, Spin } from 'antd';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import './aiModal.scss';
import TermsModal from './termsModal';
import i18n from '../../i18n';
import { clearHistory, fetchConversationHistory, messageLikeDislike, sendMessageToChatbot } from '../../services/aiServices';
import { useAuthContext } from '../../auth/AuthProvider';
import ReactMarkdown from 'react-markdown';
import ShareModal from '../Modals/ShareModal/ShareModal';
import Apartments from '../apartments/apartments';
import CustomTooltip from '../Tooltip/tooltip';
import { t } from 'i18next';
import { useNativeShare } from '../Modals/ShareModal/nativeShare';

interface AiModalProps {
    showModal: () => void;
    handleCancel: () => void;
    isModalVisible: any;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AiModal: React.FC<AiModalProps> = ({ handleCancel, isModalVisible, setIsModalVisible }) => {
    const dir = i18n.dir();
    sessionStorage.setItem('aiModal', isModalVisible)
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [shareMessage, setShareMessage] = useState()
    const [conversation, setConversation] = useState<Array<
        { sender: string; text: string; messageCode?: string } |
        { sender: string; type: 'cards'; messageCode?: string; cards: Array<{ title: string; content: string }> }
    >>([]);
    const [isTyping, setIsTyping] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
    const conversationEndRef = useRef<HTMLDivElement | null>(null);
    const [likedMessages, setLikedMessages] = useState<Set<number>>(new Set());
    const [dislikedMessages, setDislikedMessages] = useState<Set<number>>(new Set());
    const [copiedMessages, setCopiedMessages] = useState<{ [key: number]: boolean }>({});
    const { user, isAuthenticated } = useAuthContext();
    const generateRandomId = (): string => {
        return Math.floor(Math.random() * 10000).toString();
    };
    const [userId, setUserId] = useState<any>(generateRandomId());
    useEffect(() => {
        if (user?.id) {
            setUserId(user?.id?.replace(/\D/g, ''));
        }
    }, [user]);
    useEffect(() => {
        if (conversationEndRef.current) {
            conversationEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversation]);

    useEffect(() => {
        const loadConversationHistory = async () => {
            try {
                const history = await fetchConversationHistory(userId);
                const formattedHistory: Array<
                    { sender: string; text: string, messageCode: any } | { sender: string; type: 'cards'; messageCode: any; cards: Array<{ title: string; content: string }> }
                > = [];

                history?.forEach((msg: any) => {
                    // Always push the text message first
                    if (msg.text) {
                        formattedHistory.push({
                            sender: msg.sender === 'chatbot' ? 'ai' : 'user',
                            text: msg.text,
                            messageCode: msg.message_code
                        });
                    }

                    if (msg.hits?.length > 0) {
                        formattedHistory.push({
                            sender: msg.sender === 'chatbot' ? 'ai' : 'user',
                            type: 'cards',
                            cards: msg.hits.map((hit: any) => hit),
                            messageCode: msg.message_code
                        });
                    }
                });

                setConversation(formattedHistory);
            } catch (error) {
                console.error('Failed to load conversation history.', error);
            }
        };

        if (isModalVisible) {
            loadConversationHistory();
        }
    }, [isModalVisible]);

    const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = async (msg?: string) => {
        const textToSend = msg || message;

        if (textToSend?.trim()) {
            setIsSending(true);
            setConversation((prevConversation) => [
                ...prevConversation,
                { sender: 'user', text: textToSend },
            ]);
            setMessage('');
            setIsTyping(true);

            try {
                const responses = await sendMessageToChatbot(userId, textToSend);

                const formattedResponses: Array<
                    { sender: string; text: string, messageCode: any } | { sender: string; type: 'cards'; messageCode: any; cards: Array<{ title: string; content: string }> }
                > = [];

                responses.forEach((msg: any) => {
                    // Add text response first
                    if (msg.text) {
                        formattedResponses.push({
                            sender: msg.sender === 'chatbot' ? 'ai' : 'user',
                            text: msg.text,
                            messageCode: msg.message_code
                        });
                    }

                    // Add hits as a separate entry
                    if (msg.hits?.length > 0) {
                        formattedResponses.push({
                            sender: 'ai',
                            type: 'cards',
                            cards: msg.hits.map((hit: any) => hit),
                            messageCode: msg.message_code
                        });
                    }
                });

                setConversation((prevConversation) => [...prevConversation, ...formattedResponses]);
            } catch (error) {
                console.error('Failed to send message.', error);
            } finally {
                setIsSending(false);
                setIsTyping(false);
            }
        }
    };
    const handleCloseShareModal = () => {
        setIsShareModalVisible(false);
    };
    const [isMobile, setIsMobile] = useState(false);
        useEffect(() => {
          const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera; 
          if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
            setIsMobile(true);
          }
        }, []);
          const handleNativeShare = useNativeShare(shareMessage);
    const handleShareClick = (e: React.MouseEvent<HTMLImageElement>, text: any) => {
        setShareMessage(text)
        e.stopPropagation();
        if(isMobile){
            handleNativeShare()
        }
        else {
            setIsShareModalVisible(true);

        }
    };
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const showTermsModal = () => {
        setIsTermsModalVisible(true);
    };

    const hideTermsModal = () => {
        setIsTermsModalVisible(false);
    };


    const handleLikeDislike = async (messageIndex: number, action: 'like' | 'dislike', messageCode: any, feedBack: any) => {
        const newLikedMessages = new Set(likedMessages);
        const newDislikedMessages = new Set(dislikedMessages);
        let newFeedback = feedBack;
        try {
            if (action === 'like') {
                if (newLikedMessages.has(messageIndex)) {
                    newLikedMessages.delete(messageIndex);
                    newFeedback = null;
                } else {
                    newLikedMessages.add(messageIndex);
                    newDislikedMessages.delete(messageIndex);
                    newFeedback = 1;
                }
            } else if (action === 'dislike') {
                if (newDislikedMessages.has(messageIndex)) {
                    newDislikedMessages.delete(messageIndex);
                    newFeedback = null;
                } else {
                    newDislikedMessages.add(messageIndex);
                    newLikedMessages.delete(messageIndex);
                    newFeedback = 0;
                }
            }
            const response = await messageLikeDislike(userId, messageCode, newFeedback);
            setLikedMessages(newLikedMessages);
            setDislikedMessages(newDislikedMessages);

        } catch (error) {
            console.error('Error sending feedback:', error);
        }
    };

    const handleCopy = (text: string, index: number) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopiedMessages(prevState => ({ ...prevState, [index]: true }));
                setTimeout(() => {
                    setCopiedMessages(prevState => ({ ...prevState, [index]: false }));
                }, 1000);
            })
            .catch((error) => {
                console.error('Failed to copy text:', error);
            });
    };

    useEffect(() => {
        const lockScroll = () => {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden';
        };

        const unlockScroll = () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };

        if (isModalVisible) {
            lockScroll();
        } else {
            unlockScroll();
        }
        return () => {
            unlockScroll();
        };
    }, [isModalVisible]);


    const clearAiChat = async () => {
        clearHistory(userId).then(() => {
            setConversation([])
        });
    }

    return (

        <div className="icon-container-ai-modal">
            <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                width="1252px"
                className="ai-modal"
                getContainer={false}
                centered
                closeIcon={false}
                footer={[
                    <div className="modal-footer" key="footer">
                        {isTyping && (
                            <div className="typing-indicator">
                                <Spin size="small" />
                            </div>
                        )}
                        <div className="message-input-container" style={{ paddingTop: conversation.length === 0 ? '200px' : '0px' }}>
                            {conversation.length === 0 && (
                                <div className="modal-actions">
                                    <div className='button-container'>
                                        <Button type="primary" shape="round" onClick={() => handleSendMessage('Rent a property in Abu Dhabi')}>
                                            Rent a property in Abu Dhabi?
                                        </Button>
                                        <Button type="primary" shape="round" onClick={() => handleSendMessage('Buy a property in Abu Dhabi')}>
                                            Buy a property in Abu Dhabi?
                                        </Button>
                                        <Button type="primary" shape="round" onClick={() => handleSendMessage('Last Blogs')}>
                                            Last Blogs
                                        </Button>
                                    </div>


                                </div>
                            )}
                            <div style={{ display: 'flex', width: '100%' }}>
                                <Input
                                    value={message}
                                    onChange={handleMessageChange}
                                    onKeyPress={handleKeyPress}
                                    placeholder={t("aiModal.MessageHousezAI")}
                                    className="message-input"
                                />
                                <Button
                                    type="primary"
                                    className="send-button"
                                    onClick={() => {
                                        handleSendMessage();
                                    }}
                                    disabled={isSending || isTyping || !message}
                                >
                                    <img
                                        src="/images/apts/icons/sendAi.svg"
                                        style={{
                                            transform: dir === 'rtl' ? 'scaleX(-1)' : 'unset',
                                        }}
                                    />
                                </Button>
                            </div>
                        </div>
                        <div className="poweredby">
                            <p className="HAText">
                                {t("aiModal.HousezAIis")} <span className="terms" onClick={showTermsModal}>{t("aiModal.terms")}</span>
                            </p>
                        </div>
                    </div>,
                ]}
            >
                <div className='aiHeader'>
                    <div className="modal-header">
                        <div className="header-content">
                            <div className="header-text">
                                <img src="/images/apts/icons/HousezAiLogo.svg" alt="Logo" className="logoAiChat" />
                            </div>
                            <div className='AiNewAndClose'>
                                <CustomTooltip title={t("aiModal.newChat")}>
                                    <img className='aiNewChatIcon' src="/images/apts/icons/aiNewChat.svg" onClick={clearAiChat} />
                                </CustomTooltip>
                                <CustomTooltip title={t("aiModal.Close")}>

                                    <img className="close-icon" src="/images/apts/icons/AiCloseIcon.svg" onClick={handleCancel} />
                                </CustomTooltip>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal-body">
                    {conversation.length === 0 ? (
                        <div>
                            <h3 className="helloThere">{t("aiModal.Hellothere")} 😊</h3>
                            <p>
                                {t("aiModal.Imhereto")}
                                <br /> {t("aiModal.marketinsights")}
                            </p>
                        </div>
                    ) : (
                        <div className="conversation-container">
                            {conversation.map((msg, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.3 }}
                                    className={`message ${msg.sender}`}
                                >
                                    {msg.sender === 'ai' && (
                                        <div style={{ paddingRight: '10px', height: "40px" }}>
                                            <img src="/images/apts/icons/housezAiIncon.svg" alt="AI Icon" />
                                        </div>
                                    )}
                                    {'type' in msg ? (
                                        <div className="cards-container">
                                            <Apartments setIsModalVisible={setIsModalVisible} apartments={msg?.cards} apartType="Ai" />
                                            {msg.sender === 'ai' && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "end",
                                                        gap: "12px",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: "16px", height: "14px" }}
                                                        src={likedMessages.has(index) ? '/images/apts/icons/likefilled.svg' : '/images/apts/icons/likeOutline.svg'}
                                                        onClick={() => handleLikeDislike(index, 'like', msg.messageCode, 1)}
                                                    />
                                                    <img
                                                        style={{ width: "16px", height: "14px" }}
                                                        src={dislikedMessages.has(index) ? '/images/apts/icons/dislikeFilled.svg' : '/images/apts/icons/dislikeOutline.svg'}
                                                        onClick={() => handleLikeDislike(index, 'dislike', msg.messageCode, 0)}
                                                    />

                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div
                                            className='ai-message'
                                            style={{
                                                display: 'inline-block',
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div className="message-text">
                                                <ReactMarkdown>{msg.text}</ReactMarkdown>
                                            </div>
                                            {msg.sender === 'ai' && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "end",
                                                        paddingTop: "10px",
                                                        gap: "12px",
                                                        cursor: "pointer"
                                                    }}
                                                >

                                                    <img
                                                        style={{ width: '16px', height: '14px' }}
                                                        src={copiedMessages[index] ? '/images/apts/icons/check-circle.svg' : '/images/apts/icons/copy.svg'}
                                                        onClick={() => handleCopy(msg.text, index)}
                                                        alt="Copy"
                                                    />
                                                    <img
                                                        style={{ width: "16px", height: "14px" }}
                                                        src={'/images/apts/icons/shareAi.svg'}
                                                        onClick={(e) => handleShareClick(e, msg.text)}
                                                    />
                                                    <img
                                                        style={{ width: "16px", height: "14px" }}
                                                        src={likedMessages.has(index) ? '/images/apts/icons/likefilled.svg' : '/images/apts/icons/likeOutline.svg'}
                                                        onClick={() => handleLikeDislike(index, 'like', msg.messageCode, 1)}
                                                    />
                                                    <img
                                                        style={{ width: "16px", height: "14px" }}
                                                        src={dislikedMessages.has(index) ? '/images/apts/icons/dislikeFilled.svg' : '/images/apts/icons/dislikeOutline.svg'}
                                                        onClick={() => handleLikeDislike(index, 'dislike', msg.messageCode, 0)}
                                                    />

                                                </div>
                                            )}
                                        </div>

                                    )}
                                    {msg.sender === 'user' && (
                                        <div>
                                            <img src="/images/apts/icons/userAiIncon.svg" alt="User Icon" />
                                        </div>
                                    )}
                                </motion.div>
                            ))}
                            <div ref={conversationEndRef} />
                        </div>
                    )}

                </div>
                <ShareModal isOpen={isShareModalVisible} onClose={handleCloseShareModal} message={shareMessage} />
            </Modal>
            <TermsModal visible={isTermsModalVisible} onCancel={hideTermsModal} />
        </div>
    );
};

export default AiModal;
