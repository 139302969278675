import React, { useState } from 'react';
import './aiIcon.scss';
import AiModal from './AiModal';

const AiIcon: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="icon-container-ai">
        <img
          className='ai-icon'
          src={"/images/apts/icons/aiIcon.gif"}
          onClick={showModal}
        />
      </div>

      <AiModal
        showModal={showModal}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
};

export default AiIcon;
