import { Fragment, useState, useEffect } from 'react';
import Banner from '../../components/banner/banner';
import './home.scss';
import { useTranslation } from 'react-i18next';
import { useGetProperties } from '../../services/propertiesServices';
import { useLanguage } from '../../context/LanguageContext';
import Apartments from '../../components/apartments/apartments';
import { PuffLoader } from 'react-spinners';
import PopularSearches from '../../components/HomeComponents/popularSearches/popularSearches';
import { useNavigate } from 'react-router-dom';
import { useGetEmirates } from '../../services/filtersServices';
import PopularInEmirates from '../../components/HomeComponents/popularInEmirates/popularInEmirates';
import PopularInAreas from '../../components/HomeComponents/popularInAreas/popularInAreas';
import DownloadSection from '../../components/HomeComponents/downloadSection/downloadSection';
import FreshSale from '../../components/HomeComponents/freshSale/freshSale';
import ContactUs from '../../components/contactUs/contactUs';
import { Emirate, Location } from '../../helpers/types/general';
import dataEn from '../../Data/data.json';
import dataAr from '../../Data/dataAr.json';

export default function Home() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { language } = useLanguage();
    const [rentSellType, setRentSellType] = useState('R');
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState(dataEn);
    useEffect(() => {
        if (language === 'ar') {
            setData(dataAr);
        } else {
            setData(dataEn);
        }
    }, [language]);
    const itemsPerPage = 3;

    const { properties, loading } = useGetProperties({
        page: currentPage - 1,
        size: itemsPerPage,
        rentOrSell: rentSellType,
        orderByMode: 'LTH',
        language: language.toUpperCase(),
        channel: 'W',
    });

    useEffect(() => {
        setCurrentPage(1);
    }, [rentSellType]);

    const locations: Location[] = data.locations;
    // const emirates: Emirate[] = data.emirates;
    const { emirates } = useGetEmirates({ language: language.toUpperCase(), section: "H" });
    const onPopularSearchesClick = (search: Location) => {
        navigate(`/properties?rentOrSell=R&emirateIDs=${search.emirate.id}&areaIDs=${search.area.id}`);
    };
    const onPopularInEmiratesClick = (emirate: Emirate) => {
        navigate(`/properties?rentOrSell=R&emirateIDs=${emirate.id}`);
    };
    const onPopularInEmiratesViewAllClick = () => {
        navigate(`/properties`);
    };
    const handleSeeMoreClick = () => {
        navigate(`/properties?rentOrSell=${rentSellType}`);
    };
    return (
        <Fragment>
            <Banner />
            <div className='home-container'>
                <DownloadSection />
                <PopularSearches searches={locations} onSearchClick={onPopularSearchesClick} />
                <PopularInEmirates emirates={emirates?.result} onSearchClick={onPopularInEmiratesClick} onViewAllClick={onPopularInEmiratesViewAllClick} />
                <PopularInAreas areas={locations} onSearchClick={onPopularSearchesClick} onViewAllClick={onPopularInEmiratesViewAllClick} />
                <FreshSale areas={locations} onSearchClick={onPopularSearchesClick} />
                <ContactUs />

                <div className='props'>
                    <div className='title'>
                        <h2>{t("popularInUAE.popularInUAE")}</h2>
                        <div className='rs-type'>
                            <button
                                className={`${rentSellType === 'R' ? 'active' : ''}`}
                                onClick={() => setRentSellType('R')}
                            >
                                {t("popularInUAE.Rent")}
                            </button>
                            <button
                                className={`${rentSellType === 'S' ? 'active' : ''}`}
                                onClick={() => setRentSellType('S')}
                            >
                                {t("popularInUAE.Buy")}
                            </button>
                        </div>
                    </div>
                    {loading ? (
                        <div className="loader">
                            <PuffLoader color="#54B0ED" loading={loading} />
                        </div>
                    ) : (
                        <>
                            <Apartments apartments={properties?.result} className='all' />
                            {properties?.result?.length > 0 && (
                                <div className="see-more" onClick={handleSeeMoreClick}>
                                    {t("common.SeeMore")}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Fragment>
    );
}
