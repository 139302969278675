import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined, CloseOutlined } from '@ant-design/icons';
import './emailModal.scss'
import { useTranslation } from 'react-i18next';
import PhoneNumberInput from '../phoneNumber/phoneNumber';
interface EmailModalProps {

    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    isModalVisible: boolean,
    property: any
}
const EmailModal: React.FC<EmailModalProps> = ({
    setIsModalVisible,
    isModalVisible,
    property
}) => {
    const { t } = useTranslation()
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const handlePhoneChange = (value: string) => {
        setPhoneNumber(value);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values: any) => {
        console.log('Form Values:', values);
    };
    useEffect(() => {
        const lockScroll = () => {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden';
        };

        const unlockScroll = () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };

        if (isModalVisible) {
            lockScroll();
        } else {
            unlockScroll();
        }
        return () => {
            unlockScroll();
        };
    }, [isModalVisible]);

    return (
        <>
            <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                closeIcon={<CloseOutlined style={{ color: "var(--primary-color)" }} />}
                width={427}
                centered
                className='emailModal'
                getContainer={false}
            >
                <div style={{ textAlign: 'center', marginBottom: 20 }}>
                    <p className='email'> {t("emailModal.Email")} </p>
                </div>
                <p className='emailDesc' style={{ color: '#00AEEF' }}>{property ?? "Unfurnished | High Floor | Marina View"}</p>

                <Form
                    name="contact-form"
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <div className='input-group'>
                        <span>{t("emailModal.FullName")}</span>
                        <Form.Item
                            className='span'
                            name="fullName"
                            rules={[{ required: true, message: 'Please enter your full name!' }]}
                        >
                            <Input
                                className='InputBoxes'
                                prefix={<UserOutlined className="icon" />}

                                placeholder="Enter First and Last Name"
                                size="large"
                            />
                        </Form.Item>

                    </div>

                    <div>
                        <span className='span'>{t("emailModal.Phone")}</span>
                        <Form.Item
                            style={{
                                margin: "0"
                            }}
                            name="phone"
                            rules={[{ required: true, message: 'Please enter your phone number!' }]}
                        >
                            <PhoneNumberInput value={phoneNumber} onChange={handlePhoneChange} />
                        </Form.Item>
                    </div>

                    <div className='input-group'>
                        <span>{t("emailModal.Email")}</span>
                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: 'Please enter your email!' },
                                { type: 'email', message: 'Please enter a valid email!' }
                            ]}
                        >
                            <Input
                                className='InputBoxes'
                                prefix={<img src='/images/apts/icons/email_svg.svg' />}
                                placeholder="yourname@gmail.com"
                                size="large"
                            />
                        </Form.Item>
                    </div>

                    <div className='input-group'>
                        <Form.Item
                            name="message"
                            initialValue={`Hi Agent\nI found this property on Housez (List ID 122229)\nPlease contact me. Thank you.`}
                        >
                            <Input.TextArea rows={4} className='InputBoxesMessage' />
                        </Form.Item>
                    </div>


                    <Form.Item name="similarProperties" valuePropName="checked">
                        <Checkbox className='emailme'>{t("emailModal.Emailme")}</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            className='send-button'
                            type="primary"
                            htmlType="submit"
                            style={{ width: '100%', backgroundColor: '#00AEEF', borderColor: '#00AEEF' }}
                            size="large"
                        >
                            {t("emailModal.SendMessage")}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default EmailModal;
