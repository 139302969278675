import React, { useState } from 'react';
import './FilterCountDisplay.scss';
import { useTranslation } from 'react-i18next';
import RentSellFilter from '../FilterRentOrSell/RentOrSell';
import sortsIcon from '../../../assets/icons/filter/sorts.svg'
import SettingsModal from '../../Modals/SettingsModal/SettingsModal';
import Sort from '../sort/Sort';

interface FilterCountDisplayProps {
    currentPage?: number;
    itemsPerPage?: number;
    totalItems: number;
    title?: string;
    entityName?: string;
    showPages?: boolean;
    showSort?: boolean;
    showSortWithRentSellFilter?: boolean;

    setFilterParams?: any;
    agent?: any
}

const FilterCountDisplay: React.FC<FilterCountDisplayProps> = ({
    currentPage = 1,
    itemsPerPage = 100,
    totalItems,
    title = '',
    entityName = '',
    showPages = true,
    showSort = false,
    showSortWithRentSellFilter = false,
    setFilterParams,
    agent
}) => {
    const { t } = useTranslation();
    const start = (currentPage) * itemsPerPage + 1;
    const end = Math.min((currentPage + 1) * itemsPerPage, totalItems);
    const [isSettingModalVisible, setIsSettingModalVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string>("");
    const handleCancelSettings = () => {
        setIsSettingModalVisible(false);
    };
    const openSettingModal = () => {
        setIsSettingModalVisible(true);
    };
    const handleApplySettings = () => {
        handleCancelSettings();
    }
    return (
        <div className="filter-count-display">
            <div className='filter-count-display-text'>
                {title && <>{title}</>}
                <div className="count">
                    {showPages ? (
                        <>
                            {t('filters.Showing')}  {start} - {end} {t('common.of')} {totalItems ?? 0} {entityName}
                        </>
                    ) : (
                        <div className={`apartments ${showSort ? "sort" : ""}`}>

                            <span>{totalItems} {entityName}</span>
                            {showSort && (
                                <Sort />
                                // <button className='sort-btn' onClick={openSettingModal}>
                                //     {t('property.sort')}
                                //     <img className="img-icon" src={sortsIcon} alt="Save" />
                                // </button>
                            )}

                        </div>
                    )}
                </div>
            </div>
            {/* <SettingsModal
                visible={isSettingModalVisible}
                onCancel={handleCancelSettings}
                onApply={handleApplySettings}
                activeSetting={"sort"}
                selectedOption={selectedOption}
                onOptionChange={setSelectedOption}
            /> */}
            {!showPages && <div className={`${showSortWithRentSellFilter ? "apartments sort" : ""}`}>
                <RentSellFilter setFilterParams={setFilterParams} agent={agent} />
                {showSortWithRentSellFilter && <div><Sort /></div>}
            </div>}
        </div>
    );
};

export default FilterCountDisplay;
