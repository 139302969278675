import React from 'react';
import { Tooltip } from 'antd';

interface CustomTooltipProps {
    title: string;
    children: React.ReactNode;
    placement?: "top" | "left" | "bottom" | "right"; 
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, children, placement = "top" }) => {
    return (
        <Tooltip title={title} placement={placement}>
            {children}
        </Tooltip>
    );
};

export default CustomTooltip;
