import { Modal, Button, Form, Input, Select, Row, Col, notification } from "antd";
import './ContactUsModal.scss';
import { useLanguage } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { ContactUsForm, Emirate } from "../../../helpers/types/general";
import userIcon from '../../../assets/icons/contactus/user.svg';
import phoneIcon from '../../../assets/icons/contactus/phone_light.svg';
import companyIcon from '../../../assets/icons/contactus/building-4.svg';
import emailIcon from '../../../assets/icons/contactus/email_svgrepo.com.svg';
import locationIcon from '../../../assets/icons/contactus/Pin_light.svg';
import { useEffect, useState } from "react";
import { usePostContactUs } from "../../../services/contactUsServices";
import PhoneNumberInput from "../../phoneNumber/phoneNumber";

interface ContactUsModalProps {
    emirates: Emirate[] | undefined;
    formRequest: ContactUsForm;
    setFormRequest: (values: any) => void;
    isVisible: boolean;
    title: string;
    description: string;
}

const ContactUsModal: React.FC<ContactUsModalProps> = ({
    emirates,
    formRequest,
    setFormRequest,
    isVisible,
}) => {
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const handlePhoneChange = (value: string) => {
        setPhoneNumber(value);
    };
    const { responseData, loading, error, refetch } = usePostContactUs();
    const { t } = useTranslation();
    const { language } = useLanguage();
    const [form] = Form.useForm();
    const { Option } = Select;
    const direction = language === 'ar' ? 'rtl' : 'ltr';
    const googleUrl = process.env.REACT_APP_REGISTRATION_URL;
    const handleButtonClick = () => {
        window.open(googleUrl, '_blank');
    };
    const handleSubmit = () => {
        form.validateFields().then(values => {
            setFormRequest({ ...formRequest, ...values });
            refetch({
                data: { ...formRequest, ...values },
            }).then(() => {
                form.resetFields();
                notification.success({
                    message: t('ContactUs.RequestSent'),
                    description: t('ContactUs.RequestSuccessMessage'),
                    placement: 'topRight',
                });
            }).catch(error => {
                notification.error({
                    message: t('ContactUs.SubmissionError'),
                    description: t('ContactUs.SubmissionErrorMessage'),
                    placement: 'topRight',
                });
            });
        }).catch(info => {
            if (info.errorFields && info.errorFields.length > 0) {
                console.log('Form validation failed. Missing required fields.');
            } else {
                notification.error({
                    message: t('ContactUs.SubmissionError'),
                    description: t('ContactUs.SubmissionErrorMessage'),
                    placement: 'topRight',
                });
            }
        });
    };

    useEffect(() => {
        const lockScroll = () => {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden';
        };

        const unlockScroll = () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };

        if (isVisible) {
            lockScroll();
        } else {
            unlockScroll();
        }
        return () => {
            unlockScroll();
        };
    }, [isVisible]);
    return (

        <Form form={form} layout="vertical" className="contact-us-form">
            <p className="getInTouch"> {t("ContactUs.GetInTouchModal")} </p>
            <Row gutter={[102, 0]} justify="start">
                <Col xs={24} sm={12}>
                    <label className="field-label">{t('ContactUsModal.name', 'Your Name')}</label>
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: t('ContactUs.NameRequired') }]}
                    >
                        <Input
                            placeholder={t('ContactUsModal.placeholders.name')}
                            prefix={<div className="icon-text-container">
                                <img src={userIcon} alt="reason" className="select-icon" />
                            </div>}
                        />
                    </Form.Item>

                    <label className="field-label">{t('ContactUsModal.companyName', 'Company Name')}</label>
                    <Form.Item
                        name="companyName"
                        rules={[{ required: true, message: t('ContactUs.CompanyNameRequired') }]}
                    >
                        <Input
                            placeholder={t('ContactUsModal.placeholders.companyName')}
                            prefix={<div className="icon-text-container">
                                <img src={companyIcon} alt="reason" className="select-icon" />
                            </div>}
                        />
                    </Form.Item>

                    <label className="field-label">{t("ContactUsModal.emirate", "Emirates")}</label>
                    <Form.Item
                        name="emirateID"
                        rules={[{ required: true, message: t('ContactUs.EmirateRequired') }]}
                    >
                        <Select
                            placeholder={
                                <div className="icon-text-container">
                                    <img src={locationIcon} alt="reason" className="select-icon" />
                                    <span>{t("ContactUsModal.SelectEmirate", "Select Emirate")}</span>
                                </div>
                            }
                        >
                            {emirates?.map((emirate) => (
                                <Option key={emirate.id} value={emirate.id}>
                                    {emirate.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <label className="field-label">{t('ContactUsModal.phoneNumber', 'Phone Number')}</label>
                    <Form.Item
                        style={{
                            margin: "0"
                        }}
                        name="phoneNumber"
                        rules={[
                            { required: true, message: t('ContactUs.PhoneNumberRequired') },
                            { pattern: /^\+?[0-9]{10,15}$/, message: t('ContactUs.InvalidPhoneNumber') }
                        ]}
                    >
                        <PhoneNumberInput value={phoneNumber} onChange={handlePhoneChange} />

                    </Form.Item>

                    <label className="field-label">{t('ContactUsModal.email', 'Email Address')}</label>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, type: 'email', message: t('ContactUs.EmailRequired') }]}
                    >
                        <Input
                            placeholder={t('ContactUsModal.placeholders.email')}
                            prefix={<div className="icon-text-container">
                                <img src={emailIcon} alt="reason" className="select-icon" />
                            </div>}
                        />
                    </Form.Item>
                    <div className="submit-btn-container">
                        <Button loading={loading} type="primary" onClick={handleSubmit} block className="submit-btn">
                            {t("ContactUsModal.submit", "Send Request")}
                        </Button>
                    </div>
                </Col>
            </Row>
            <div className="online-registration-container">
                <div className="line-with-icon">
                    <span className="horizontal-line"></span>
                    <p className="orText">{t("ContactUsModal.or")}
                    </p>
                    <span className="horizontal-line"></span>
                </div>
                <Button block type="primary" className="submit-btn" onClick={handleButtonClick}>
                    {t("ContactUsModal.OnlineRegistration")}
                </Button>
            </div>
        </Form>
    );
};

export default ContactUsModal;
