import React from 'react';
import { Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbItem } from '../../helpers/types/general';
import { useTranslation } from 'react-i18next';
import separatorLeft from '../../assets/icons/separatorLeft.svg'
import separatorRight from '../../assets/icons/separatorRight.svg'
import line from '../../assets/icons/line.svg'

import './breadcrumbs.scss';

interface BreadcrumbsProps {
    locationBreadcrumbs: BreadcrumbItem[];
    rentOrSell: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ locationBreadcrumbs, rentOrSell }) => {
    const navigate = useNavigate();

    const handleBackToSearch = () => {
        navigate(-1);
    };

    const handleBreadcrumbClick = (crumb: BreadcrumbItem) => {
        navigate(`/properties?rentOrSell=${rentOrSell}&${crumb.name}=${crumb.value}`);
    };
    const { t } = useTranslation()

    return (
        <Breadcrumb style={{ marginBottom: '16px' }} separator={<img src={separatorRight} />}>
            <Breadcrumb.Item className='standard-text' >
                <span onClick={handleBackToSearch} style={{ cursor: 'pointer' }}>
                    <span><img src={separatorLeft} /></span> <span style={{ margin: "0px 4px" }}>{t("common.BacktoSearch")}</span>
                </span>
                <span style={{ margin: "0px 18px 0px 14px" }}> <img src={line} height={"27px"} /> </span>
                <span onClick={() => navigate(`/properties?rentOrSell=${rentOrSell}`)} style={{ cursor: 'pointer', margin: "0px 4px" }}>
                    {t("common.for")} {rentOrSell}
                </span>

            </Breadcrumb.Item>
            {locationBreadcrumbs.map((crumb, index) => (
                <Breadcrumb.Item key={index} separator={<img src={separatorRight} />}>
                    <span onClick={() => handleBreadcrumbClick(crumb)} style={{ cursor: 'pointer', }}>
                        {crumb.value}
                    </span>
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};

export default Breadcrumbs;
