import React, { useEffect, useState } from 'react';
import { useFilter } from '../../context/FilterContext';
import { getBlogsByFilter, useGetBlogs, useGetSections } from '../../services/blogService';
import BlogList from '../../components/blogAndNews/BlogList';
import Filters from '../../components/blogAndNews/Filters';
import './stickyBlogsFilter.scss';
import LastBlogs from '../../components/blogAndNews/lastBlogs';

interface Blog {
  id: string;
  title: string;
  summary: string;
  category: string;
}

const BlogsPage: React.FC = () => {
  const { filters, selectedFilter, handleFilterSelect, setSelectedFilter, sectionsResults, selectedFilterName } = useFilter();
  const { result, loading, error, refetch } = useGetBlogs({
    page: 0,
    size: 4,
    section: selectedFilter === 'all' ? undefined : selectedFilter,
    flag: 'T'
  });

  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [lastBlogs, setLastBlogs] = useState<Blog[]>([]);

  useEffect(() => {
    if (result) {
      setLastBlogs(result.blogs);
    }
  }, [result, refetch]);

  useEffect(() => {
    const savedFilter = sessionStorage.getItem('selectedFilter');
    const savedFilterName = sessionStorage.getItem('selectedFilterName');

    if (savedFilter && savedFilterName) {
      handleFilterSelect(savedFilter, savedFilterName);
    } else {
      setSelectedFilter('all');
    }
    fetchBlogs(selectedFilter);

  }, [selectedFilter, handleFilterSelect, setSelectedFilter]);

  const fetchBlogs = async (filterId: string) => {
    const data = await getBlogsByFilter(filterId);
    setBlogs(data);
  };

  const handleBlogClick = (id: string) => {
    window.location.href = `/blog/${id}`;
  };

  return (
    <div>
      <div className="sticky-filter-buttons"
        style={{
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
        }}
      >
        <Filters
          filters={sectionsResults?.result}
          selectedFilterName={selectedFilterName}
          selectedFilter={selectedFilter}
          onFilterSelect={handleFilterSelect}
          setSelectedFilter={setSelectedFilter}

        />
      </div>
      <BlogList
        blogs={result?.result}
        onBlogClick={handleBlogClick}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        selectedFilterName={selectedFilterName}
        loading={loading}
      />
      <LastBlogs
        onBlogClick={handleBlogClick}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        selectedFilterName={selectedFilterName}
      />
    </div>
  );
};

export default BlogsPage;
