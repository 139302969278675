import React from 'react';
import '../styles/MinMaxInput.scss';
import { Checkbox } from 'antd';

interface SelectOption {
    id: number | string;
    name: string;
}

interface CheckboxInputProps {
    className?: string;
    value?: any;
    options: SelectOption[];
    multiple?: boolean;
    onChange: (selectedOptions: any[]) => void;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
    className,
    value,
    options,
    multiple = true,
    onChange,
}) => {

    const handleCheckboxChange = (checkedValues: any[]) => {
        if (multiple) onChange(checkedValues);
        else onChange(checkedValues.slice(-1));
    };
    return (
        <Checkbox.Group
            value={value}
            onChange={handleCheckboxChange}
        >
            <div className={`filter-options ${className}`}>
                {options?.map((option) => (
                    <Checkbox key={option.id} value={option.id} className="checkbox-option circular-checkbox">
                        {option.name}
                    </Checkbox>
                ))}
            </div>
        </Checkbox.Group>
    );
};

export default CheckboxInput;
