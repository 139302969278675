import useAxios from "axios-hooks";
import { BaseParam } from "../helpers/types/general";
import { BASE_URL } from "../helpers/types";
import { createQueryParams } from "../helpers/helpers";

interface AxiosGetRequestConfig<T = any> {
  url?: string;
  params?: Partial<BaseParam & Record<string, any>>;
  headers?: {
    language?: string;
    channel?: string | "W" | "MA" | "MI"; // Channel (W - Website, MA - Android Mobile, MI - iOS Mobile)
    section?: string | "H";
    authorization?: string; // Bearer token
    USER_ID?: string
  };
  enabled?: boolean;
}

interface AxiosPostRequestConfig<T = any> {
  url?: string;
  data?: Partial<BaseParam & Record<string, any>>;
  headers?: {
    language?: string;
    channel?: string;
    section?: string;
    authorization?: string; // Bearer token
  };
  enabled?: boolean;
}

export const CreateAxiosGetRequest = <T = any>({
  url,
  params = {},
  headers = { USER_ID: "1", language: "en", channel: "w", section: "" },
  enabled = true,
}: AxiosGetRequestConfig<T>) => {
  const queryString = createQueryParams(params);
  const requestHeaders: Record<string, string | undefined> = {
    "Content-Type": "application/json",
    Language: headers.language,
    Channel: headers.channel,
    Section: headers.section,
  };
  if (headers.authorization && headers.authorization.startsWith("Bearer ") && headers.authorization.trim().length > 7) {
    requestHeaders.Authorization = headers.authorization;
  }
  const [{ data, loading, error, response }, refetch] = useAxios<T>(
    {
      url: `${BASE_URL}/${url}?${queryString}`,
      method: "GET",
      headers: requestHeaders,
    },
    {
      manual: !enabled,
      useCache: false,

    }
  );

  return { data, loading, error, refetch, response };
};

export const CreateAxiosPostRequest = <T = any>({
  url,
  data = {},
  headers = { language: "en", channel: "w", section: "" },
  enabled = true,
}: AxiosPostRequestConfig<T>) => {
  const requestHeaders: Record<string, string | undefined> = {
    "Content-Type": "application/json",
    Language: headers.language,
    Channel: headers.channel,
    Section: headers.section,
  };
  if (headers.authorization && headers.authorization.startsWith("Bearer ") && headers.authorization.trim().length > 7) {
    requestHeaders.Authorization = headers.authorization;
  }

  const [{ data: responseData, loading, error, response }, refetch] =
    useAxios<T>(
      {
        url: `${BASE_URL}/${url}`,
        method: "POST",
        data,
        headers: requestHeaders,

      },
      { manual: !enabled, useCache: true }
    );

  return { responseData, loading, error, refetch, response };
};
