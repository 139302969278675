import React, { useState, useEffect } from "react";
import "./RentOrSell.scss";
import { useTranslation } from "react-i18next";
interface Props {
    setFilterParams: (params: any) => any;
    agent: any;
}
export default function RentSellFilter({ setFilterParams, agent }: Props) {
    const { t } = useTranslation();
    const [selected, setSelected] = useState<string | null>(null);
    const initializeDealType = async () => {
        if (agent && agent.dealType) {
            const dealType = agent.dealType === "R" ? "Rent" : "Buy";
            setSelected(dealType);
            setFilterParams((prevParams: any) => ({
                ...prevParams,
                rentOrSell: agent.dealType,
            }));
        }
    };
    useEffect(() => {
        initializeDealType();
    }, [agent]);
    const handleClick = (type: string) => {
        setSelected(type);
        setFilterParams((prevParams: any) => ({
            ...prevParams,
            rentOrSell: type === "Rent" ? "R" : "S",
        }));
    };

    if (!selected) {
        return null;
    }
    return (
        <div className="RentOrSell">
 <div className="btns">
            <button
                className={`RentBtn ${selected === "Rent" ? "active" : ""}`}
                onClick={() => handleClick("Rent")}
            >
                {t("popularInUAE.Rent")}
            </button>
            <button
                className={`BuyBtn ${selected === "Buy" ? "active" : ""}`}
                onClick={() => handleClick("Buy")}
            >
                {t("popularInUAE.Buy")}
            </button>
        </div>
        </div>
       
    );
}
