import axios from 'axios';
import { CreateAxiosGetRequest, CreateAxiosPostRequest } from './GeneralService';
import { favoriteBlog } from '../helpers/types/general';

const BASE_URL = 'https://development-chatbot.housez.ae';
// const BASE_URL = 'http://localhost:80';


export const fetchConversationHistory2 = (userId: string) => {

  const { data: result, loading, error, refetch } = CreateAxiosGetRequest<any>({
    url: `${BASE_URL}/chatbot/history`,
    headers: {
      USER_ID: userId.toString(),
    },
  });

  return { result, loading, error, refetch };
};

export const fetchConversationHistory = async (userId: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/chatbot/history`, {
      headers: {
        "USER_ID": userId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching conversation history:', error);
    throw error;
  }
};
export const clearHistory = async (userId: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/chatbot/clear`,
      {},
      {
        headers: {
          "USER_ID": userId.toString(),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error sending message to chatbot:', error);
    throw error;
  }
};


export const sendMessageToChatbot = async (userId: string, message: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/chatbot/ask`,
      { message },
      {
        headers: {
          "USER_ID": userId.toString(),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error sending message to chatbot:', error);
    throw error;
  }
};

export const messageLikeDislike = async (userId: string, message_code: any, feedback: any) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/chatbot/feedback`,
      {
        message_code,
        feedback
      },
      {
        headers: {
          "USER_ID": userId.toString(),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error sending message to chatbot:', error);
    throw error;
  }
};


