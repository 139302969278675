import React, { useState, useEffect, useRef } from "react";
import "./DropdownSavedSearch.scss";
import { useTranslation } from "react-i18next";
import { Button, Col, message, Row, Space, Spin } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import closeIcon from '../../../assets/icons/close.svg'
import { useAuthContext } from "../../../auth/AuthProvider";
import { useDeleteSaveSearch, useGetSavedSearch } from "../../../services/userServices";
import { useNavigate } from "react-router-dom";
import SaveSearchModal from "../../Modals/SaveSearchModal/SaveSearchModal";
interface Param {
    page: string;
    size: string;
    rentOrSell: string;
    orderByMode: string;
    language?: string;
}

interface SavedSearch {
    id: number;
    name: string;
    param: Param;
}

interface DropdownSavedSearchProps {
    title: string;
    // data: SavedSearch[];
    icon?: string;
    onSelect: (param: Param) => void;
    // onEdit: (id: number) => void;
    // onDelete: (id: number) => void;
    className?: string;
}

const DropdownSavedSearch: React.FC<DropdownSavedSearchProps> = ({
    title,
    // data,
    icon,
    onSelect,
    // onEdit,
    // onDelete,
    className = "",
}) => {
    const { authToken } = useAuthContext();

    const [isOpen, setIsOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedSearchItem, setSelectedSearchItem] = useState<{ id: number, name: string }>();

    const { data: savedSearch, loading: loadingSavedSearch, refetch: refetchSaveList } = useGetSavedSearch({}, authToken ?? "", isOpen);
    const { refetch: DeleteSaveSearch } = useDeleteSaveSearch();

    const dropdownRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation()
    const toggleDropdown = () => setIsOpen((prev) => !prev);

    const handleDelete = async (id: number) => {
        try {
            await DeleteSaveSearch({
                data: {
                    Id: id,
                },

                headers: {
                    channel: "w",
                    authorization: `Bearer ${authToken}`,
                },
            });

            message.success("Delete Search Item successfully!");
            refetchSaveList()
        } catch (error) {
            message.error("An error occurred while Deleting Search Item.");
        }

    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={`dropdown-saved-search-container ${className}`} ref={dropdownRef}>

            <div className="dropdown-toggle" onClick={toggleDropdown} aria-expanded={isOpen}>
                <img src={icon} alt="My Favourites" className="nav-icon" />
                <span>{title}</span>
            </div>
            {isOpen && (
                <div className="dropdown-menu">
                    {loadingSavedSearch && <Spin
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '200px',
                            color: "green !important",
                        }}
                    />}
                    {!loadingSavedSearch && savedSearch?.result.length === 0 && (
                        <div className="closeIcon" >
                            <img src={closeIcon} alt="close" className="nav-icon" onClick={() => setIsOpen(false)} />
                        </div>
                    )}

                    {!loadingSavedSearch && savedSearch?.result.length === 0 ? (
                        <div className="no-data-container">

                            <div className="no-data-icon">
                                <img src="/images/apts/icons/noData.svg" alt="No data icon" />
                            </div>
                            <p>{t("searches.noSearches")}</p>
                        </div>
                    ) : !loadingSavedSearch && (
                        <>
                            {savedSearch?.result?.map((item: { id: number; param: Param; name: string }) => (
                                <Row key={item.id} className="dropdown-item" align="middle" justify="space-between">
                                    {/* Item name and click handler */}
                                    <Col span={17}>
                                        <span
                                            className="item-name"
                                            onClick={() => {
                                                onSelect(item.param);
                                                setIsOpen(false);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {item.name}
                                        </span>
                                    </Col>

                                    {/* Action buttons */}
                                    <Col span={4}>
                                        <div className="item-actions">
                                            <button
                                                className="edit-button"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedSearchItem({ id: item?.id, name: item?.name })
                                                    setIsOpen(false)
                                                    setIsEditModalOpen(true)

                                                    // onEdit(item.id);
                                                }}
                                            >
                                                <img src="/images/apts/icons/editIcon.svg" />
                                            </button>
                                            <button
                                                className="delete-button"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    // onDelete(item.id);
                                                    handleDelete(item.id)
                                                }}
                                            >
                                                <img src="/images/apts/icons/deleteIcon.svg" />

                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </>
                    )}
                </div>
            )}
            <SaveSearchModal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                isEditSearchName={true}
                nameValue={selectedSearchItem?.name}
                idSearchName={selectedSearchItem?.id}
                setIsModalOpen={setIsEditModalOpen}
            />
        </div>
    );
};

export default DropdownSavedSearch;
