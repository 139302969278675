import './explore.scss';
import Apartments from "../../components/apartments/apartments";
import RecommendedSearches from "../../components/recommendedSearches/recommendedSearches";
import { useParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { useLanguage } from "../../context/LanguageContext";
import { PropertyFilterParam } from "../../helpers/types/filter";
import { useGetProperties } from "../../services/propertiesServices";
import PaginationComponent from "../../components/Pagination/PaginationComponent";
import FilterCountDisplay from "../../components/filters/FilterCountDisplay/FilterCountDisplay";
import FilterContainer, { FilterConfig } from "../../components/filters/FilterContainer";
import { useTranslation } from "react-i18next";
import { useGetAreasByEmirates, useGetEmirates, useGetFeatures, useGetFrequencies, useGetPropTypesByUsage } from "../../services/filtersServices";
import AdvertisingCard from "../../components/advertisingCard/advertisingCard";
import { useFilterState } from "./filterState";
import { useEffect, useState } from 'react';
import { capitalizeFirstLetter, splitString } from '../../helpers/helpers';
import { PlaceResult } from '../../components/filters/FilterTypes/LocationSearchFilter';
import jsonData from '../../Data/data.json';
import emptyData from "../../assets/images/exploreEmpty.svg"
import { useAddSaveSearch, useGetSavedSearch } from '../../services/userServices';
import { Button, message } from "antd";
import SaveSearchModal from '../../components/Modals/SaveSearchModal/SaveSearchModal';
import { useAuthContext } from '../../auth/AuthProvider';
import { useSettings } from '../../context/SettingsContext';



export default function Explore() {

    const area = useParams().area;
    const { language } = useLanguage();
    const { t } = useTranslation();
    const itemsPerPage = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 8;
    const { refetch: AddSaveSearch } = useAddSaveSearch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [saveSearchName, setSaveSearchName] = useState("");
    const { area: areaStored, currency, sort } = useSettings();

    const [searchQuery, setSearchQuery] = useState<string>(
        `?page=0&size=200&rentOrSell=S&orderByMode=${sort ?? "price_low_to_high"}`
    );
    const { authToken } = useAuthContext();

    // Save Search
    // const { data: savedSearch, loading: loadingSavedSearch, refetch: refetchListSearch } = useGetSavedSearch({}, authToken ?? "", false);



    const initialFilterParams: PropertyFilterParam = {
        usageIDs: [1],
        sizeUnit: `${area ?? "sqft"}`,
        priceCurrency: `${currency ?? "AED"}`,
        orderByMode: `${sort ?? "price_low_to_high"}`,
        rentOrSell: 'R',
        page: 0,
        size: itemsPerPage,
        language: language.toUpperCase(),
    };
    const resetFilterParams = (filterParams: PropertyFilterParam, keepParams: Partial<PropertyFilterParam> = {}): PropertyFilterParam => {
        const event = new Event("resetPropertyButtonClick");
        document.dispatchEvent(event);
        return {
            usageIDs: [],
            areaIDs: [],
            emirateIDs: [],
            typeIDs: [],
            featureIDs: [],
            bathroomNums: [],
            bedroomNums: [],
            frequency: [],
            placeId: "",
            placeName: "",
            latitude: undefined,
            longitude: undefined,
            premise: "",
            route: "",
            sublocalityLevel1: "",
            locality: "",
            country: "",
            streetNumber: "",
            adminLavel1: "",
            location: "",

            orderByMode: `${sort ?? "price_low_to_high"}`,
            sizeUnit: `${area ?? "sqft"}`,
            priceCurrency: `${currency ?? "AED"}`,

            rentOrSell: 'R',
            page: 0,
            size: filterParams.size,
            language: filterParams.language,
            channel: 'W',
            maxPrice: undefined,
            minPrice: undefined,
            maxSize: undefined,
            minSize: undefined,
            ...keepParams
        };//TODO add all
    };
    const [filterParams, updateFilterParams] = useFilterState(initialFilterParams);

    const [originalRentOrSell, setOriginalRentOrSell] = useState<string>('R');

    const { emirates } = useGetEmirates({ language: language.toUpperCase() });
    const { features } = useGetFeatures({ language: language.toUpperCase() });
    const { frequencies } = useGetFrequencies({ language: language.toUpperCase() });
    const { propTypes } = useGetPropTypesByUsage(filterParams.usageIDs?.[0] || 1, { language: language.toUpperCase() });
    const { areas } = useGetAreasByEmirates(filterParams.emirateIDs || [], language.toUpperCase());
    const { properties: data, loading } = useGetProperties(filterParams, authToken ?? "");

    const properties = data?.result;
    const totalItems = data?.pageInfo?.totalElements || 0;

    const beds = jsonData?.beds?.map((bed: any) => {
        if (bed.name === "Studio") {
            return { ...bed, name: t('filters.Studio') };
        }
        return bed;
    });;
    const baths = jsonData?.baths;
    const rentalFrequencyName = frequencies?.result?.find((frequency: any) => filterParams?.frequency?.includes(frequency.id))?.name || 'Yearly';

    const handleFilterTypeChange = (newFilterParams: string) => {
        let rentOrSellValue = newFilterParams;
        let usageIDValue = 1;

        if (newFilterParams === 'CR') { rentOrSellValue = 'R'; usageIDValue = 2; }
        else if (newFilterParams === 'CS') { rentOrSellValue = 'S'; usageIDValue = 2; }

        setOriginalRentOrSell(newFilterParams);
        updateFilterParams({
            rentOrSell: rentOrSellValue,
            usageIDs: [usageIDValue],
        });
    };

    const handlePageChange = (page: number) => {
        updateFilterParams({ page: page - 1 });
    };

    const handleRecommendedSearchClick = (bedroomNums: number) => {
        const updatedFilters = resetFilterParams(filterParams, { bedroomNums: [bedroomNums] });
        updateFilterParams(updatedFilters);
    };

    const handleReset = () => {
        const updatedFilters = resetFilterParams(filterParams);
        updateFilterParams(updatedFilters);
    };

    const handleSave = () => {
        setIsModalOpen(true);
    };

    const handleSaveSearch = async (body: { name: string; searchjson: string }) => {
        try {
            await AddSaveSearch({
                data: body,
                headers: {
                    channel: "w",
                    authorization: `Bearer ${authToken}`,
                },
            });
            message.success("Search saved successfully!");
            // refetchListSearch()
            setIsModalOpen(false);
        } catch (error) {
            message.error("An error occurred while saving the search.");
        }
    };


    const handlePlaceSelected = (place: PlaceResult) => {
        const emirateName = place.locality || place.administrative_area_level_1;
        const matchingEmirate = emirates?.result?.find((emirate: any) =>
            emirate.name.toLowerCase() === emirateName?.toLowerCase()
        );
        const emirateID = matchingEmirate ? matchingEmirate.id : null;
        updateFilterParams({
            placeId: place.placeId,
            placeName: place.placeName,
            latitude: place.lat,
            longitude: place.lng,
            premise: place.premise,
            streetNumber: place.street_number,
            route: place.route,
            sublocalityLevel1: place.sublocality,
            locality: place.locality,
            adminLavel1: place.administrative_area_level_1,
            country: place.country,
            emirateIDs: emirateID ? [emirateID] : [],
        });
    };
    const filtersConfig: FilterConfig[] = [
        {
            type: 'select' as const,
            param: 'rentOrSell',
            options: [
                { id: 'R', name: `${t('filters.Rent')}` },
                { id: 'S', name: `${t('filters.Buy')}` },
                { id: 'CR', name: `${t('filters.CRent')}` },
                { id: 'CS', name: `${t('filters.CBuy')}` },
            ],
            placeholder: t('filters.RentOrSell_Purpose'),
            value: filterParams.rentOrSell,
            multiple: false,
            onChange: (key, value) => handleFilterTypeChange(value),
            className: "rent custom-select",
            directQueryOnChange: true
        },
        {
            type: 'location' as const,
            param: 'location',
            placeholder: 'Location',
            value: {
                placeId: filterParams.placeId,
                placeName: filterParams.placeName,
                lat: filterParams.latitude,
                lng: filterParams.longitude,
                premise: filterParams.premise,
                street_number: filterParams.streetNumber,
                route: filterParams.route,
                sublocality: filterParams.sublocalityLevel1,
                locality: filterParams.locality,
                administrative_area_level_1: filterParams.adminLavel1,
                country: filterParams.country,
            } as PlaceResult,
            onChange: (key, value) => handlePlaceSelected(value),
            className: "explore-location-filter"
        },
        {
            type: 'checkboxGroup',
            param: 'typeID',
            sections: [
                {
                    type: "checkboxGroup",
                    label: 'Types',
                    param: 'typeIDs',
                    value: filterParams.typeIDs,
                    options: propTypes?.result || [],
                    showLabel: false
                },
            ],
            placeholder: t('filters.Type'),
            multiple: true,
            className: "custom-select hide-search property-filter-background property-filter-type",
            directQueryOnChange: false
        },
        {
            type: 'checkboxGroup',
            param: 'BedsAndBaths',
            sections: [
                { label: t('filters.Beds'), type: "checkboxGroup", param: 'bedroomNums', options: beds, showLabel: true, value: filterParams.bedroomNums },
                { label: t('filters.Baths'), type: "checkboxGroup", param: 'bathroomNums', options: baths, showLabel: true, value: filterParams.bathroomNums },
            ],
            placeholder: t('filters.BedsAndBaths'),
            multiple: true,
            className: "custom-select horizontal-select hide-search beds-and-baths",
            directQueryOnChange: false
        },
        {
            type: 'advanced',
            param: 'advanced',
            sections: [
                {
                    label: `${t('filters.totalArea')} (${areaStored || t('common.sqft')})`,
                    type: "minMax",
                    param: 'minSize',
                    showLabel: true,
                    attributes: {
                        maxParam: "maxSize",
                        minParam: "minSize",
                        defaultMaxValue: 10000,
                        maxValue: filterParams.maxSize || 10000,
                        defaultMinValue: 0,

                        minValue: filterParams.minSize || 0,
                        maxPlaceholder: `Maximum (${areaStored || t('common.sqft')})`,
                        minPlaceholder: `Minimum (${areaStored || t('common.sqft')})`
                    }
                },
                {
                    label: `${t('filters.Price')} (${currency || t('common.AED')})`,
                    type: "minMax",
                    param: 'minPrice',
                    showLabel: true,
                    attributes: {
                        maxParam: "maxPrice",
                        minParam: "minPrice",
                        defaultMaxValue: 10000000,
                        maxValue: filterParams.maxPrice || 10000000,
                        defaultMinValue: 0,
                        minValue: filterParams.minPrice || 0,

                        maxPlaceholder: "Maximum",
                        minPlaceholder: "Minimum"
                    }
                },
                {
                    label: t('filters.Features'),
                    type: "checkbox",
                    param: 'featureIDs',
                    options: features?.result || [],
                    showLabel: true,
                    value: filterParams.featureIDs,
                    className: "features"

                },
                {
                    label: t('filters.RentalFrequency'),
                    type: "checkbox",
                    param: 'frequency',
                    options: frequencies?.result || [],
                    showLabel: true,
                    className: "horizontal-select",
                    multiple: false,
                    // onChange: (key, value) => handleFilterFrequenciesChange(value),
                    directQueryOnChange: true,
                    value: filterParams.frequency
                }
            ],
            // placeholder: undefined,
            placeholder: t("filters.MoreFilters"),
            multiple: true,
            className: "custom-select hide-search more-filter",
            directQueryOnChange: false
        }
    ];

    useEffect(() => {
        updateFilterParams({ ...filterParams, orderByMode: `${sort ?? "price_low_to_high"}`, sizeUnit: `${area ?? "sqft"}`, priceCurrency: `${currency ?? "AED"}` })
        // setSearchQuery(`?page=0&size=200&rentOrSell=S&orderByMode=${sort ?? "price_low_to_high"}`)
    }, [sort, currency, areaStored])
    return (
        <>
            <FilterContainer filtersConfig={filtersConfig} onFilterChange={updateFilterParams} onSave={handleSave} onReset={handleReset} showSearchButton={false} showSaveButton={true} />
            <div className="explore">
                {area && (
                    <div className="title">
                        <h1>{t("common.Propertiesavailablein")} {capitalizeFirstLetter(splitString(String(area)))}</h1>
                        <span className="prop-num">{totalItems} {t("common.Properties")}</span>
                    </div>
                )}
                {loading ? (
                    <div className="loader">
                        <PuffLoader color="#54B0ED" loading={loading} />
                    </div>
                ) : (
                    <>
                        <div className="props">
                            <div className="apts">
                                <FilterCountDisplay
                                    title={
                                        originalRentOrSell === 'R'
                                            ? t('property.PropertiesRentInUAE')
                                            : originalRentOrSell === 'S'
                                                ? t('property.PropertiesSellInUAE')
                                                : originalRentOrSell === 'CR'
                                                    ? t('property.CommercialRentInUAE')
                                                    : t('property.CommercialSellInUAE')
                                    }
                                    entityName={t('common.Properties')}
                                    currentPage={filterParams.page + 1}
                                    itemsPerPage={filterParams.size}
                                    totalItems={totalItems}
                                    showPages={false}
                                    showSort={true}
                                />
                                {properties?.length > 0 ? (
                                    <>
                                        <Apartments apartments={properties} rentalFrequency={rentalFrequencyName} />
                                        <PaginationComponent
                                            current={filterParams.page}
                                            total={totalItems}
                                            pageSize={filterParams.size}
                                            onChange={handlePageChange}
                                        />
                                    </>
                                ) : (
                                    <div className='emptyData'>
                                        <img style={{ maxWidth: "100%" }} src={emptyData} />
                                        <p className='noMatchText'>{t("property.noMatch")}</p>
                                    </div>
                                )}
                            </div>
                            <div className="recomended">
                                <AdvertisingCard
                                    imageUrl="https://tpc.googlesyndication.com/simgad/1476376105149956178"
                                    altText="Advertisement"
                                />
                                <RecommendedSearches onSearchClick={handleRecommendedSearchClick} />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <SaveSearchModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={handleSaveSearch}
            // searchjson={searchQuery}
            />
        </>
    );
}
