import React from "react";
import { useTranslation } from "react-i18next";


export default function CustomerAgreement() {
    const { i18n } = useTranslation()
    const dir = i18n.dir()
    return (
        <>
            {dir === 'ltr' ? (
                <>
                    <strong>Housez Customer Agreement</strong>
                    <br /><br />
                    This Customer Agreement (this <strong>“Agreement”</strong>) contains the terms and conditions that govern your access to and use of the Services and Platforms (as defined below) and is an agreement between the applicable Housez Contracting Party specified in Section 15 below (<strong>“Housez,” “we,” “us,”</strong> or <strong>“our”</strong>) and you or the entity you represent (<strong>“you”</strong> or <strong>“your”</strong>). This Agreement takes effect when you click an “I Accept” button or check box presented with these terms or sign an Order Form incorporating this Agreement (the <strong>“Effective Date”</strong>). You represent to us that you are lawfully able to enter into contracts (e.g., you are not a minor). If you are entering into this Agreement for an entity, such as the company you work for, you represent to us that you have legal authority to bind that entity. In the event of a conflict between this Agreement and the Order Form, this Agreement shall prevail. Please see Section 15 for definitions of certain capitalized terms used in this Agreement.
                    <br /><br />
                    <strong>1. Scope and Application of the Contract </strong>
                    <br /><br />
                    1.1The Contract is effective between you and us. Your Affiliates may not use the Services under your Contract and must enter into a separate contract with us.
                    <br /><br />
                    1.2Your Contract, including this Agreement, governs (i) the activity on your Account, the Division Accounts, the User Accounts; and (ii) the use of the Platforms by your Users. Your obligations under the Contract apply to your Users as well.
                    <br /><br />
                    1.3You accept your Contract (i) by signing your Order Form (either digitally or by physical means); (ii) through the use of the Services; or (ii) by continuing to use the Services after being notified of a change to this Agreement.
                    <p>1.4There may be circumstances where you are entering into the Contract on behalf of a company or any other legal person affiliated to you. In such cases:</p>
                    <ol type="1" style={{ paddingLeft: "40px;" }}>
                        <li>You warrant and represent that you have the authority, necessary licenses, permits, and consents to enter into the Contract on behalf of such company or legal person. We can direct you to furnish proof thereof.</li>
                        <br /> <li>You will (prior to the commencement of your Contract) provide us with a copy of the relevant Government Identity issued to you.</li>
                        <br /><li>You and the company or legal person are jointly and severally liable in respect of all the obligations under your Contract.</li>
                    </ol>
                    <p>1.5We reserve the right to reject any Order Form or refuse to provide any Service at our absolute discretion, including if:</p>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <li>You are not a licensed real estate agent, broker, or developer.</li>
                        <br /> <li>You do not have the authority to act on behalf of the owner of a property for which Listings are published.</li>
                        <br /><li>You are not in compliance with Applicable Law.</li>
                    </ol>
                    <strong>2. Your Responsibilities</strong>
                    <p>2.1You warrant and represent that you will:</p>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <li>Only create Listings for properties that are available to buy or rent.</li>
                        <br /> <li>Be the holder of a valid license as a real estate broker, or real estate developer in the Location.</li>
                        <br /><li>Obtain and possess necessary permits to market and publish the Listings on our Platforms. Upon request, you will provide certified proof of any required permits to us. You acknowledge that we hold no responsibility for your obtaining and possessing any of the required permits for publishing and marketing the Listings.</li>
                        <br /> <li>Ensure that you do not make (or authorize the insertion of) any reference to us, our Brand, or our Platforms in any document (including promotional or merchandising material) or any website without our prior written consent.</li>
                        <br /> <li>Comply with and abide by all Applicable Laws, including circulars and guidelines concerned with real estate, or the subject matter of your Contract.</li>
                        <br /> <li>Immediately remove the Listing or mark it as “Under Offer” upon (i) a property going “under offer”, (ii) a sale or leasing contract being entered into in respect of a property, or (iii) a property being taken off the market.</li>
                        <br /> <li>Publish only one (1) Listing for a particular property (including through your Affiliates).</li>
                        <br /> <li>Hold all necessary licenses, authorizations, and consents to use, display, reproduce, and publish the Content and have the capacity, authority, and right(s) to grant us a license for the Content.</li>
                        <br /> <li>Not infringe and/or use our Intellectual Property Rights, including in a manner that prejudices us or is harmful, defamatory, or derogatory to our Brand or our reputation.</li>
                        <br /> <li>Not misuse or corrupt (or allow to be misused or corrupted) any of the Services provided to you.</li>
                        <br /> <li>Provide us with a valid telephone number where we can contact you. Where a trackable telephone number has been allocated to you, you will allow us to monitor telephone calls between you and any other party who has contacted you through our Platforms. You will take no actions that would interfere with such monitoring, and you agree that we and our suppliers may record such telephone calls.</li>
                        <br /> <li>Ensure that only your authorized persons have access to the Services.</li>
                        <br /> <li>Provide Content in a format compatible with any technical specifications issued by us from time to time.</li>
                        <br /> <li>If you are acting on behalf of a property owner, provide all documentation as requested by us to prove your authority to act on behalf of the property owner (including but not limited to any power of attorney).</li>
                        <br /> <li>Have, and during the Listing Term maintain, the rights to market, rent, or sell the properties specified in your Listings and will inform us promptly if you lose the rights to market, rent, or sell the property set out in the Listing.</li>
                        <br /> <li>Act in a manner that is consistent and compliant with Data Protection Laws and our Privacy Policy.</li>
                    </ol>
                    <p>2.2You acknowledge and agree that:</p>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <li>You have read and agree to be bound by the terms of all legal notices posted on the Platforms, including our Privacy Policy and our Cookie Policy.</li>
                        <br /> <li>By using the Service, you grant us an irrevocable, world-wide, royalty-free license to commercialize, reproduce, display, publish, copy, share, license to other persons, and otherwise use the Content (or data or other information derived therefrom) for the purpose of performing our obligations under the Contract, to provide the Services, create suitable algorithms/products necessary to improve the Services, and/or for general analytical purposes. This license shall survive the termination of the Contract. You agree that unless required by Applicable Laws, we will not be required to identify you as the source of the Content in any medium through which the Content or any derivative thereof is published or displayed.</li>
                        <br /> <li>We are not responsible for any errors or omissions in the Content, and we make no warranty nor any representations about the accuracy or completeness of your Content.</li>
                        <br /> <li>You are responsible for, and must pay the cost of, all telecommunications and Internet access charges incurred when using our Platforms.</li>
                        <br /><li>You are responsible for the security and integrity of your Content and data generally.</li>
                        <br /> <li>Transmission of data over the Internet can be subject to errors and delays.</li>
                        <br /> <li>We may contact you from time to time to make you aware of the opportunities, products, and services offered by us or our affiliates and our business partners. You further give us your permission to contact you via telephone or any electronic media for that purpose.</li>
                        <br /><li>We may immediately remove a Listing that is subject to the Sole Listing Rights of another agent.</li>
                        <br /><li>You will not use any tracking data obtained by cookies or using tracking tools on our Platforms to target, re-target, advertise, or interact in any way with visitors or users of the Platforms.</li>
                        <br /> <li>The Services will not be activated, and Credits (or Listing Tokens) shall not be delivered to your Account until payment of the Fee (or periodic installments thereof) has been received by us.</li>
                        <br /> <li>If you wish to use a third-party CRM provider to publish data on the Platforms using an XML feed, such CRM provider must (i) be on our pre-approved list of CRM providers; (ii) must expressly agree to and comply with our terms and conditions for CRM providers, published on our Platforms (“CRM Conditions”). A breach of the CRM Conditions by the CRM provider could result in a suspension of the feed. It is your responsibility to assure that your CRM provider complies with the CRM Conditions. A CRM provider who provides any CRM services will be deemed to have acknowledged and accepted the CRM Conditions.</li>
                    </ol>
                    <strong>3. Content Quality Responsibilities</strong>
                    <p>3.1You warrant and represent that your Content and Listing:</p>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <li>Is not (i) unlawful (nor does it encourage unlawful conduct), defamatory, false, misleading, obscene (including pornographic, hateful, racially, or ethnically offensive material) and/or otherwise inappropriate, and (ii) having regard for the purpose of our Platforms, is not likely to cause offence or is against public policy, and (iii) will not expose us to any liability, legal proceedings or other sanction.</li>
                        <br /> <li>Does not, in any manner, infringe our Intellectual Property Rights or the Intellectual Property Rights of any third party.</li>
                        <br /> <li>Is in all respects true, complete and accurate and that you will promptly update or correct the Content on becoming aware of any errors or inaccuracies.</li>
                        <br /><li>Complies, and will continue to comply, with any guidelines we issue from time to time.</li>
                        <br /> <li>Complies, and will continue to comply, with any Listing requirements defined by us.</li>
                        <br /> <li>Refers only to details of a particular property and any reference to material other than the details for a property must receive our prior written approval.</li>
                        <br /> <li>Does not directly or indirectly involve advertising or marketing anything other than a particular property.</li>
                        <br /> <li>Does not violate the terms, policies or agreements of third parties, including but not limited to our third-party service provider’s terms of use.</li>
                    </ol>
                    <p>3.2You acknowledge and agree that:</p>
                    <ol style={{ paddingLeft: "20px;" }}>
                        <li>We are not under any obligation to monitor or censor the Content generated by you. However, we reserve the right to do so, in our absolute discretion, without notice to you.</li>
                        <br /> <li>We may remove, cause to be removed, amend or decline to display any Content or Listing on the Platforms, or direct you to modify the Content or Listing to meet the requirements of this Agreement or Applicable Laws.</li>
                        <br />  <li>We reserve the right to change, at any time, the rules, regulations, restrictions, or procedures for any Listing or Content uploaded or submitted to us for uploading (including the type of Content or the amount of Content).</li>
                        <br /> <li>Using the Service to advertise your business other than for property is not acceptable. Any reference to material other than details for a property or real estate development must receive our prior written approval.</li>
                    </ol>
                    <strong>4. Credits and Listings</strong>
                    <p>4.1For you to use a Credit or Listing Token towards a Listing you must:</p>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <li>Be registered as an “Administrator” or “Agent” in our customer management system.</li>
                        <br /><li>Have sufficient Credits in your Account (to be utilized within the Credit Term).</li>
                        <br /><li>Upload a Listing via our Customer management system.</li>
                    </ol>
                    <p>4.2You acknowledge and agree that:</p>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <li>We do not, in any manner, promise or guarantee any leads or minimum number of leads or impressions.</li>
                        <br /> <li>You cannot pause, cancel, or get a refund for any Credit, including but not limited to if a Listing is not displayed during a part or for the entirety of the Listing Term, or you decide to change the type of product or service used for such Listing (for example, by upgrading from a ‘Standard’ listing to a ‘Featured’ or ‘Premium’ listing).</li>
                        <br /><li>Any loss of a Credit that results from a Disqualifying Event shall not qualify for a refund or compensation of any kind.</li>
                        <br /><li>Once a Listing Request has been accepted, you cannot pause the Service or utilize such Credits for another Listing.</li>
                        <br /><li>Once a Listing Request has been accepted, we do not promise or guarantee that any subsequent changes made by you will be reflected on the Platforms.</li>
                        <br /><li>All Credits which have not been utilized by the end of the Credit Term will expire and will not roll over to the next Credit Term.</li>
                        <br /> <li>All Top-Up Credits which have not been utilized before the end of the Credit Term will expire at the end of such Credit Term.</li>
                        <br /><li>All Credits which remain unused at the end of the Contract Term will be deemed to have been used and will automatically and permanently expire.</li>
                    </ol>
                    <p>4.3Without prejudice to any other rights available to us under this Agreement, we may remove any Listing from our Platform if any of the following events (each a <strong>“Disqualifying Event”</strong>) occur:</p>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <li>The details of the real estate property in a Listing are inaccurate or misleading, or if the property is unavailable for any reason.</li>
                        <br /> <li>The property set out in the Listing has been sold, rented or foreclosed upon, or is going under offer.</li>
                        <br /> <li>Failure of the Listing to satisfy the Listing requirements defined by us.</li>
                        <br /><li>The price or rent of a property set out in the Listing does not match the request of the property owner or the property owner’s representative.</li>
                        <br /> <li>The Listing is subject to the Sole Listing Rights of another agent.</li>
                    </ol>
                    <strong>5. Fees and Payment</strong>
                    <br /><br />
                    5.1You shall pay the Fees to us through such methods of payment as we deem appropriate. Such methods of payment will be displayed to you in the customer management system, at the time you order the Services through an Order Form.
                    <br /><br />
                    5.2The Fees shall become due and payable by you at the start of the Contract Term. If a periodic payment method is chosen by you, a pro-rata portion of the Fee shall become due and payable at the start of each Credit Term.
                    <br /><br />
                    5.3Subject to your payment of the Fee (or any instalment thereof) on or before it’s due date, your Account will be delivered with the number of Credits (or Listing Tokens, in case of a Listing Package) set forth in the Order Form at the beginning of your Credit Term.
                    <p>5.4In order to secure all your payment obligations, we may demand, and you provide within a period of seven (7) days from the start of your Contract Term (or such other period that we determine), a cheque for an amount equivalent to the total Fee. However, we may, at our sole discretion, limit access to your Account after five (5) days (i.e., before the expiry of the seven (7) day period).</p>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <li>In the event that you fail to fulfill any of your payment obligations to us, we shall be entitled to present such a cheque to the bank for encashment. In such case, we will recover the entire Fee due to us by you, including any fines levied by us in respect such non-payment. Any excess amount uncashed by us shall be returned to you.</li>
                        <br /> <li>In the event the cheque bounces for whatever reason, we shall have the right to claim the value of the cheque in accordance with applicable Laws (for example, Law of the United Arab Emirates, if your Contract is based in the United Arab Emirates), and you shall not dispute our right in any way, including but not limited to any subjective dispute, commercial dispute etc.</li>
                    </ol>
                    <p>5.5During the Contract Term, you are permitted to purchase Top-Up Credits and Upgrade Credits by submitting a new Order Form, which is subject to acceptance by us.</p>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <li>Upgrade Credits are considered to be an upgrade to your initial Credits package, and you shall receive (subject to payment being received by us) the Credits identified in your initial package as well as the Upgrade Credits which will be available during your Contract Term.</li>
                        <br /> <li>Top-Up Credits are additional Credits bought via our customer management system or an Order Form that must be activated within the Credit Term and will be accessible and usable by you during the Credit Term.</li>
                    </ol>
                    5.6In all instances of payments, you acknowledge and agree that you are liable for any charges levied by the bank(s) or the credit card clearance providers.
                    <br /><br />
                    5.7You agree and acknowledge that it is your responsibility to submit adequate proof of payment (including any transaction reference numbers along with the unique identification number provided by us to you that may be required by us to reconcile the payment) to ensure there is no interruption in your Service.
                    <br /><br />
                    5.8In the event of payment through inter-bank / intra-bank transfers, you acknowledge and agree that Credits will only be released once such payment is received into our account, and it is your responsibility to initiate payments on-time to ensure there is no interruption in your Service.
                    <br /><br />
                    5.9You are required to present a cheque for the entire contract amount (total Fees) in addition to any additional fees and/or fines that we determine. The cheque shall not include any word or indication that prevents its encashment or limits our legal rights to collect its value under the Laws in force (for example, under the Laws of the United Arab Emirates, if your Contract is based in the United Arab Emirates). If this happens, your liability to us will fully remain for the entire value of the cheque, in addition to the fees and fines that we determine. We shall have the right to take any legal action we deem appropriate to protect our rights (including our financial rights against your payment obligations to us) under applicable Laws and procedures, and you shall dispute such right in any manner.
                    <br /><br />
                    <strong>6. Prices and Taxes </strong>
                    <br /><br />
                    6.1We reserve the right to modify, from time to time, the prices for any Subscription, Credit, Credit Package, Listing Package, Product and/or Services. The Fee payable by you for your Credit Package and/or Listing Package shall not be modified by us during the Contract Term. However, you acknowledge and agree that the prices of any Upgrade Credits, Top-Up Credits, ad-hoc Products and Services that you purchase during the Contract Term are subject to change, and we may vary such prices at our sole discretion.
                    <br /><br />
                    6.2You acknowledge and agree that the number of Credits applicable to Listings is dynamic in nature. Accordingly, you agree and acknowledge that the number of Credits deducted for a Listing and/or types of Listings, may be modified by us, at our sole discretion, during the Contract Term.
                    <br /><br />
                    6.3As part of your Subscription, you may receive certain Products and Services free of cost. This is not guaranteed, and we reserve the right to charge you for such Products and Services, at our sole discretion. If a charge is levied by us, these Products and Services will not be made available to you until we receive the corresponding payment.
                    <br /><br />
                    6.4The prices offered by us are exclusive of any applicable taxes, and your Order Form will reflect the amount of taxes payable in respect of your Subscription. You agree that you will always be responsible for paying any taxes on respect of our Subscription.
                    <br /><br />
                    6.5In the event that the Government of the Location elects to charge any taxes, including value added tax or other similar tax or duty on the provision, sale or supply of any goods and/or services which are the subject matter of your Contract (“Future Taxes”), then we reserve the right, at our sole discretion, or if required by law, to add Futures Taxes at the appropriate rate for such goods and/ or services to any invoices issued under or in connection with your Contract from the relevant time and you acknowledge and accept that you shall pay and be solely liable for any such Future Taxes in addition to the Fee for such goods and services.
                    <br /><br />
                    <strong>7. Limited Access, Suspension, Termination and Expiry</strong>
                    <br /><br />
                    7.1Except as permitted by Applicable Laws, you have no right to terminate this Contract before the end of the Contract Term under any circumstances. Further, you are not permitted to downgrade your Subscription (or any components thereof, including your Credit Package) during the Contract Term, unless otherwise agreed in writing by us.
                    <br /><br />
                    7.2We may, at our sole discretion, limit your access to your Account, in the event your Fee (or a component thereof) remains unpaid for a period of three (3) days from the due date.
                    <br /><br />
                    7.3We may, at our sole discretion, suspend your Account, in the event your Fee (or a component thereof) remains unpaid for a period of seven (7) days from the due date. In such case all your Listings, details of any property, agent, or other information, uploaded by you to our Platforms will be removed from our Platform, until the Fee, which is due to us by you, is paid.
                    <br /><br />
                    7.4If access to your Account is limited by us or if your Account or Subscription is suspended for any reason during the Contract Term, you will still be liable to pay any Fees due to us regardless of you receiving the Services.
                    <br /><br />
                    7.5We may, at our sole discretion, terminate your Subscription, in the event your Fee (or a component thereof) remains unpaid for a period of sixty (60) days from the due date.
                    <br /><br />
                    7.6Upon termination of your Subscription, the entire unpaid balance of your Fee shall immediately become due and payable by you to us. We reserve the right to bring appropriate legal proceedings against you and your Affiliates (if applicable) to enforce your payment obligation.
                    <br /><br />
                    7.7Upon termination of your Subscription, we may at our sole discretion, refuse to provide future Services to you or your Affiliates, unless (i) all amounts due and payable by you; and (ii) a penalty (that we in our sole discretion determine), is paid to us prior to entering into a new contract with you.
                    <p>7.8Without limiting our rights, we may immediately:</p>
                    <ol type="i" style={{ paddingLeft: "40px;" }}>
                        <li>sanction you;</li>
                        <br /> <li>suspend or limit the Service;</li>
                        <br /> <li>remove from our Platforms your Listings, details of any property, agent, or other information, uploaded by you to our Platforms;</li>
                        <br /> <li>terminate your Contract; and/or</li>
                        <br /> <li>impose a fine, claim damages and/or seek compensation from you (equivalent to, or greater than the total Fee payable by you to us under your Order Form(s)), if:</li>
                    </ol>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <br /> <li>you fail to pay any Fees or charges due to us by the due date;</li>
                        <br /> <li>you fail to provide, or fail to provide in a manner or for an amount deemed appropriate by us, any cheques to act as security for your payment obligations;</li>
                        <br /> <li>you breach any part of your Contract and fail to rectify that breach within seven (7) days of us giving notice requiring rectification;</li>
                        <br /> <li>you violate this Agreement (or any terms thereof);</li>
                        <br /> <li>you fail to comply with any of the Content Quality Responsibilities;</li>
                        <br /> <li>you engage in any unlawful actions while using or accessing our Services and the Platforms;</li>
                        <br /> <li>you enter bankruptcy, liquidation, administration, receivership, a composition of arrangement with your creditors, or appoint a receiver or manager over all or any part of your assets or become or are deemed to become insolvent; or</li>
                        <br /> <li>you die, or if you are in a partnership, are dissolved or an application to dissolve is filed, or if you are a company, are wound up or an application for winding up is filed.</li>
                    </ol>
                    7.9If your Subscription has been suspended and/or terminated for any reason during the Contract Term, you will still be liable to pay any Fee/s due to us regardless of you receiving the Services.
                    <br /><br />
                    7.10Following the expiry of the Contract Term, you may renew your Subscription by entering into a new contract, in which case you understand and agree that the terms and package (including the price for a Credit, Service or Product) may change.
                    <br /><br />
                    7.11Upon the expiry of the Contract Term, unless you have renewed your Subscription by entering a new contract with us, you agree and acknowledge that all Services will cease immediately, and all active Listings will immediately be taken down from our Platforms.
                    <br /><br />
                    <strong>8. Refund</strong>
                    <br /><br />
                    8.1You do not have a right to claim a refund of the Fee (or any component thereof).
                    <br /><br />
                    8.2We will only offer a refund if a duplicate transaction is received by us. In such an event, only the duplicate portion of the payment will be refunded. In such an event, the duplicate portion of your payment will be refunded to you through your original mode of payment.
                    <br /><br />
                    8.3In the cases of fraudulent or disputed transactions you should contact your bank or credit card provider.
                    <br /><br />
                    <strong>9. Data Protection</strong>
                    <br /><br />
                    9.1You consent to the storage and processing of your Content on the Platform. If we pass on details of Property Seekers and such details contain Personal Data (as defined by Data Protection Laws), you acknowledge that you will be the Controller (as defined by Data Protection Laws) of the copy such Personal Data and shall comply with applicable Data Protection laws.
                    <br /><br />
                    9.2You warrant and represent that, if you provide us (directly or indirectly) with Personal Data relating to any individual, you shall have obtained that individual's consent to (i) process, use and store that Personal Data for the purpose of providing of the Services and (ii) transmit that Personal Data to us and other third parties (including to countries that do not maintain adequate data protection standards) to process, use and store for the purpose of providing the Services.
                    <br /><br />
                    9.3Credit card and debit card details will not be stored, shared, rented or leased to any third parties.
                    <br /><br />
                    <strong>10. Limitation of Liability</strong>
                    <p>10.1We will not be liable under the Contract for any liability caused by:</p>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <li>any breach of your obligations under your Contract; or</li>
                        <br /> <li>any delay in performance or breach of your Contract which arises as a result of any matter beyond our control including but not limited to viruses, other defects or failure of the server hosting our Platforms.</li>
                    </ol>
                    <p>10.2We specifically:</p>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <li>exclude consequential, special or indirect loss or damage (including but not limited to loss of opportunity, loss of revenue, and loss of profits);</li>
                        <br /> <li>limit our liability for breach of any condition or warranty that we cannot exclude to the greater of (at our option):</li>
                        <ol style={{ paddingLeft: "40px;" }}>
                            <br />  <li>resupplying the Service; or</li>
                            <br /> <li>paying the cost of having the Service resupplied;</li>
                        </ol>
                        <br /> <li>limit our liability in respect of any other claim in connection with the Contract whether the claim is based in contract, tort (including negligence), or statute to the amount paid to us by you under your Order Form.</li>
                    </ol>
                    <strong>11.Indemnification</strong>
                    <br /><br />
                    11.1You will indemnify, defend, protect, and hold harmless, us and our Affiliates and each of their respective directors, officers, employees, contractors and agents (each an “Indemnified Party”) to the fullest extent permitted by law, from and against any actual, alleged, threatened, pending or completed disputes, claims (including without limitation, claims by any third party, any governmental or regulatory agency), actions, investigations (each, a “Claim” and, collectively, “Claims”), damages (including punitive, special, consequential, indirect, exemplary or incidental damages), losses, reasonable attorneys’ fees, costs, expenses, and settlement amounts (each, a “Loss” and, collectively, “Losses”), whether or not well founded in law or fact, which arise out of or are directly or indirectly related to (i) your Contract; (ii) any inaccuracy, untruthfulness or the breach or alleged breach by you of any representation or warranty; (iii) the performance or non-performance by you of any obligations under your Contract: regardless of when the Loss occurs or the Claim is asserted, commenced or threatened.
                    <br /><br />
                    <strong>12. Disclaimers</strong>
                    <br /><br />
                    12.1THE SERVICES ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, OR TO THE EXTENT ANY STATUTORY RIGHTS APPLY THAT CANNOT BE EXCLUDED, LIMITED OR WAIVED, WE AND OUR AFFILIATES AND LICENSORS (A) MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE REGARDING THE SERVICES OR THE THIRD-PARTY CONTENT, AND (B) DISCLAIM ALL WARRANTIES, INCLUDING ANY IMPLIED OR EXPRESS WARRANTIES (I) OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR QUIET ENJOYMENT, (II) ARISING OUT OF ANY COURSE OF DEALING OR USAGE OF TRADE, (III) THAT THE SERVICES OR THIRD-PARTY CONTENT WILL BE UNINTERRUPTED, ERROR FREE OR FREE OF HARMFUL COMPONENTS, AND (IV) THAT ANY CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR ALTERED.
                    <br /><br />
                    <strong>13. Governing Law and Dispute Resolution</strong>
                    <p>13.1Unless specified in your Order Form, by default, the Law governing your Contract and the dispute resolution mechanism, is as under:</p>
                    <ol style={{ paddingLeft: "40px;" }}>
                        <li>
                            The Contract and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by and interpreted in accordance with the laws of the United Arab Emirates. The Parties irrevocably submit to the exclusive jurisdiction of the courts of the United Arab Emirates.
                        </li>
                        <br /> <li>
                            The enforcement of our rights arising out of or in connection with a cheque provided by you, or on your behalf (when such cheque is not honored by a bank in the United Arab Emirates, for any reason) shall be governed by and interpreted in accordance with the laws of the United Arab Emirates. In such event, the Parties irrevocably submit to the exclusive jurisdiction of the courts of the United Arab Emirates.
                        </li>
                    </ol>
                    13.2We may enforce our rights under the Contract through the Housez Contracting Entity or through any of our affiliates.
                    <br /><br />
                    <strong>14. General Terms</strong>
                    <br /><br />
                    14.1We may change this Agreement at any time on written notice. You acknowledge that our publishing of the amended version of this Agreement on our Platforms constitutes written notice to you of such changes. You are encouraged to frequently visit our website in order to be updated about the changes to this Agreement.
                    <br /><br />
                    14.2We will not deal with or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctioned countries in accordance with Applicable Law.
                    <br /><br />
                    14.3Notwithstanding anything to the contrary in this Agreement, we reserve the right to change the algorithm’s used in any Services or products and which may form part of your Contract, at any time during the Contract Term, at our sole discretion.
                    <br /><br />
                    14.4We reserve the right to add any new products to the Services at any time. Your Contract will not include any such new products unless we both agree.
                    <br /><br />
                    14.5No delay or failure by us to enforce any provision of the Contract will be deemed a waiver or create a precedent or will prejudice our rights.
                    <br /><br />
                    14.6Any notice/s must be sent by e-mail, prepaid post or delivery courier to your or our last known address, in the English language.
                    <br /><br />
                    14.7You must not assign any part of the Service and/or your obligations set out in the Contract without our prior written consent.
                    <br /><br />
                    14.8We treat all Personal Data in accordance with the Applicable Laws and our Privacy Policy.
                    <br /><br />
                    14.9If any term of the Contract becomes for any reason invalid or unenforceable under Applicable Law or by a court having jurisdiction over Applicable Law, then the validity and enforceability of the remainder will not be affected.
                    <br /><br />
                    14.10In case of a discrepancy between the Arabic and English versions of this Agreement, as published on our Platform, the Arabic version shall prevail.
                    <br /><br />
                    <strong>15. Definitions</strong>
                    <br /><br />

                    15.1
                    <br /><br />
                    In this Agreement, the following definitions apply:
                    <br /><br />
                    <strong>“Account”</strong> means the master or primary account in respect of your Subscription;
                    <br /><br />
                    <strong>“Applicable Law(s)”</strong> means any law, statute, rule, regulation, code, decree, judgment, directive, by-law, order, guideline or other legislative measure (whether or not having the force of law but which, if not having the force of law, is generally complied with) of any government, statutory, regulatory, self-regulatory or similar body, authority or court, as amended, replaced, re-enacted, restated or reinterpreted from time to time;
                    <br /><br />
                    <strong>“Brand”</strong> means the Housez brand, and all Intellectual Property Rights owned or used by Housez;
                    <br /><br />
                    <strong>“Content”</strong> means any and all content in respect of (i) a Listing, including all its details such as photographs, images, videos, prices, amenities, distances, plans etc.; (ii) your details, including the photographs and details of your employees, contractors, licensed agents and/or brokers; and (iii) your Intellectual Property Rights;
                    <br /><br />
                    <strong>“Contract”</strong> means, your universal contract with Housez and includes your Order Form(s), this Agreement, and where applicable, Product Specific Terms and Conditions;
                    <br /><br />
                    <strong>“Contract Term”</strong> means, the duration of the Contract, as specified in your Order Form;
                    <br /><br />
                    <strong>“Credit(s)”</strong> means each pre-paid credit delivered to your Account, to be used by you to display your Listings;
                    <br /><br />
                    <strong>“Credit Package”</strong> means the package of Credits purchased by you, to be delivered to your Account periodically or on a lumpsum basis;
                    <br /><br />
                    <strong>“Credit Term”</strong> means the periodic cycle as specified in your Order Form, at the beginning of which, a pro rata portion of the Credits from your Credits Package are delivered to your Account, and by the end of which such Credits must be used by you to display a Listing;
                    <br /><br />
                    <strong>“Customer Tier”</strong> means the segment or tier assigned by us to your Subscription, based on criteria that we determine and modify, from time to time, in our sole discretion. Your Customer Tier will be specified on your Order Form;
                    <br /><br />
                    <strong>“Data Protection Laws”</strong> means those laws applicable to the collection and processing of personal data in relevant jurisdictions;
                    <br /><br />
                    <strong>“Disqualifying Event”</strong> has the meaning set out in Section 4.3;
                    <br /><br />
                    <strong>“Division Account”</strong> means the sub-account that is provided by us, at our sole discretion, to you in relation to your Subscription;
                    <br /><br />
                    <strong>“Fee(s)”</strong> means the total amount due to Housez by you under the Contract and specified in the Order Form, and which may be divided into periodic payments, all of which are payable by you to us, including upon termination of the Contract;
                    <br /><br />
                    <strong>“Intellectual Property Rights”</strong> or <strong>“IP”</strong>means all patents, rights to inventions, utility models, copyright and related rights, trademarks, trade names, logos, service marks, trade, business and domain names, rights in trade dress or get-up, rights in goodwill or to sue for passing off, unfair competition rights, rights in designs, rights in computer software, database right, topography rights, moral rights, rights in confidential information (including know-how and trade secrets) and any other intellectual property rights, in each case whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world;
                    <br /><br />
                    <strong>“Listing”</strong> means the Content in respect of a real estate property displayed by you on our Platforms;
                    <br /><br />
                    <strong>“Listing Package”</strong> means the pre-determined number of Listings (whether ‘Standard’, ‘Featured’, ‘Premium’ or a combination thereof) ordered by you, as reflected in your Order Form;
                    <br /><br />
                    <strong>“Listing Term”</strong> means the period during which your Listing will be displayed on our Platform;
                    <br /><br />
                    <strong>“Listing Token(s)” </strong>means, in case of a Listing Package, the number of Listings that can be displayed by you on our Platform, as specified in your Order Form;
                    <br /><br />
                    <strong>“Location(s)”</strong> means the physical location identified on your Order Form or a location where we deem you have the right to promote your Listings;
                    <br /><br />
                    <strong>“Mobile Application”</strong> means the mobile application developed and operated by Housez, including its regional releases;
                    <br /><br />
                    <strong>“Order Form”</strong> means the order form indicating (i) the Services under your Subscription; (ii) the Fee payable by you; and (iii) other material terms relevant to your Subscription;
                    <br /><br />
                    <strong>“Platform(s)”</strong> means the Mobile Application, Website and any other technological platforms or innovations used and/or operated by us to deliver the Services;
                    <br /><br />
                    <strong>“Product(s)”</strong> means the technological innovation owned and developed by us to provide the Service (or a portion thereof) to you;
                    <br /><br />
                    <strong>“Product Documentation”</strong> means the documentation containing the description and specification of each of the Services;
                    <br /><br />
                    <strong>“Property Seeker(s)”</strong> means visitors or consumers who use the Platforms to browse, buy and/or rent real estate properties;
                    <br /><br />
                    <strong>“Product Specific Terms and Conditions”</strong> means additional terms and conditions that relate to specific Product and/or Services and, in addition to this Agreement, apply to you and your Subscription;
                    <br /><br />
                    <strong>“Service(s)”</strong> means the service(s) provided by Housez, as specified in your Order Form, and described in detail in the Product Documentation;
                    <br /><br />
                    <strong>“Sole Agent Form”</strong> means a form approved by us and signed by the property owner or the property owner’s authorized representative granting you or your agent the exclusive right to market, rent or sell a specific real estate property;
                    <br /><br />
                    <strong>“Sole Listing Rights”</strong> means the right to have a particular property advertised exclusively through you or your agent, as set out in a Sole Agent Form;
                    <br /><br />
                    <strong>“Subscription”</strong> means your entitlement to the Service(s), subject to payment of the Fee and the terms of your Contract;
                    <br /><br />
                    <strong>“Top-Up Credit”</strong> means any additional Credit(s) that you purchase within a Credit Term, to be activated and utilized during the Credit Term;
                    <br /><br />
                    <strong>“Upgrade Credit”</strong> means an upgrade to your Credit Package which increases the Credits delivered to you (and the amount payable by you) during each Credit Term of the remaining Contract Term;
                    <br /><br />
                    <strong>“User”</strong>means a legal person (i) who is authorized by you to use a Service; (ii) for whom you have ordered the Service; (iii) and to whom a user identification and password has been created by you, as part of your Subscription;
                    <br /><br />
                    <strong>“User Account”</strong> means the sub-account that is created by you for your Users in relation to your Subscription;
                    <br /><br />
                    <strong>“Website”</strong> means <a  style={{ color: "#01BAFF" }} href="https://www.housez.ae">https://www.housez.ae</a>, and includes all regional domains (ccTLDs) registered to Housez;
                    <br /><br />
                    <strong>“we”, “our”, “us”,</strong> or <strong>“Housez”</strong>  means the applicable Housez contracting party (see Section 15 below) and its affiliates, subsidiaries, holding company and their respective employees, officers, agents, and/or assigned parties;
                    <strong>“you”, “your”,</strong> or the <strong>“Customer”</strong> means you, who is either a licensed real estate broker or agent; or a licensed real estate brokerage firm, developer and/or hotel apartment provider, and subject to your Contract, a recipient of the Service(s);
                    <strong>“Your Affiliates”</strong> means a company or any other legal person affiliated to you, including your affiliates, holding company, subsidiaries, branch office within your group, and includes their respective employees, officers, agents and permitted assigns. <br /><br /><br /><br />
                </>
            ) : (
                <>
                    <p><strong>شركة </strong><strong>هاوسيز</strong><strong> إتفاقية عميل</strong></p>
                    <p>تنص اتفاقية العميل الماثلة ("الاتفاقية") على الشروط والأحكام التي تحكم وصولك واستخدامك للخدمات والمنصات (المعرّفة أدناه)، وهي اتفاقية بين الطرف المتعاقد الذي يمثل <strong>هاوسيز</strong> وفقًا للمحدد في القسم 15 أدناه ("<strong>هاوسيز</strong> "، "نحن" أو ضمائر الجمع المتكلم) وبينك أو الجهة التي تمثلها ("أنت" أو ضمائر المخاطب)، وتسري الاتفاقية الماثلة عند الضغط على زرّ "أقبل" أو وضع علامة داخل مربع القبول الوارد مع هذه الشروط أو التوقيع على نموذج الطلب الذي يتضمن الاتفاقية الماثلة ("تاريخ السريان")، علمًا بأنك تضمن أن لديك الأهلية القانونية لإبرام العقود (على سبيل المثال وليس الحصر بألا تكون قاصرًا)، وإذا كنت تُبرم الاتفاقية الماثلة نيابةً عن جهة، كالشركة التي تعمل فيها، فإنك تضمن لنا أن لديك الصلاحية القانونية لإلزام تلك الجهة، وحال وجود تعارض بين الاتفاقية الماثلة ونموذج الطلب، تسري الاتفاقية الماثلة. يُرجى الاطلاع على القسم 15 لمعرفة تعاريف المصطلحات البارزة المستخدمة في الاتفاقية الماثلة.</p>
                    <strong>1.</strong>
                     <strong>نطاق الاتفاقية وتطبيقها</strong>
                    <p>1.1يسري العقد بيننا وبينكم، ولا يمتد لتغطية أى شركة أو شخص معنوى اَخر تابع لكم (بما فى ذلك الفرع ) ولا يجوز لأى شركة أو شخص معنوى الإستفادة من تلك الخدمات بالنيابة عنكم، ويتعين عليهم إبرام عقد منفصل معنا.</p>
                    <p>1.2يحكم عقدك شاملًا الاتفاقية الماثلة بما فى ذلك (أ) النشاط على حسابك، والحسابات الثانوية، وحسابات المستخدمين، و(ب) استخدام المنصات من جانب مستخدميك، والتزاماتك بموجب العقد تنطبق على كافة المستخدمين المسجلين علي حسابكم أيضا.</p>
                    <p>1.3تقر بقبول عقدك (أ) بتوقيع نموذج الطلب (سواءً بالتوقيع الإلكتروني أو باليد) أو (ب) باستخدام الخدمات أو (ج) بمتابعة استخدام الخدمات بعد إخطارك بحدوث تغيير في الاتفاقية الماثلة.</p>
                    <p>1.4في بعض الحالات، قد تُبرم العقد نيابةً عن شركة أو أي شخصية اعتبارية تابعة لك، في هذه الحالات:</p>
                    <ol>
                        <li>تقر وتضمن أنك لديك الصلاحيات والرخص والتصاريح والموافقات اللازمة لإبرام العقد نيابةً عن تلك الشركة أو الشخص الاعتباري، ويمكننا أن نطلب منك تقديم الدليل على ذلك.</li>
                        <li>انت ملزم بتقدم (قبل بدء العقد) نسخة من بطاقة الهوية الحكومية ذات الصلة الصادرة لك.</li>
                        <li>تكون أنت والشركة أو الشخص الاعتباري مسؤولين بالتضامن وفرادى فيما يتعلق بجميع الالتزامات بموجب العقد الخاص بك.</li>
                        <li>نحتفظ بالحق في رفض أي نموذج طلب أو رفض تقديم أي خدمة وفقًا لتقديرنا المطلق، ويشمل هذا الحالات التالية:</li>
                        <li>إذا لم تكن وكيلًا أو وسيطًا أو مطورًا عقاريًا أو مقدم خدمات شقق فندقية مرخصًا.</li>
                        <li>إذا لم يكن لديك صلاحية التصرف نيابة عن مالك إحدى العقارات التي يتم نشر القوائم الخاصة بها.</li>
                        <li>إذا لم تكن ملتزمًا بالقوانين المعمول به</li>
                    </ol>

                    <strong>2.</strong>
             <strong>مسؤولياتك</strong>
                    <p>2.1تقر وتضمن أنك ستلتزم بما يلي:</p>
                    <ol>
                        <li>إنشاء إعلانات فقط للعقارات المتاحة للبيع أو الإيجار.</li>
                        <li>امتلاك رخصة سارية كوسيط عقاري أو مزود شقق فندقية أو مطور عقاري في المكان.</li>
                        <li>الحصول على التصاريح اللازمة لتسويق ونشر الإعلانات على منصتنا وامتلاكها، وتقديم دليل موثق يثبت أي تصاريح مطلوبة إلينا عند الطلب، تقر بأننا لا نتحمل أي مسؤولية عن عدم حصولكم أو حملكم أي من التصاريح المطلوبة لنشر الإعلانات وتسويقها..</li>
                        <li>ضمان عدم الإشارة إلينا (أو التصريح بذكرنا) أو علامتنا التجارية أو منصاتنا في أي وثيقة (وتشمل المواد الترويجية أو التسويقية) أو أي موقع إلكتروني، دون موافقة كتابية مسبقة منّا.</li>
                        <li>الالتزام والتقيد بجميع القوانين المعمول بها، بما في ذلك التعميمات والمبادئ التوجيهية المتعلقة بالعقارات أو موضوع عقدك .</li>
                        <li>الحذف الفوري لأي إعلان أو وضع علامة "قيد التفاوض" (أ) إذا صار العقار "قيد التفاوض" أو (ب) أبرم عقد بيع أو إيجار يخص العقار أو (ج) تم سحب العقار من السوق.</li>
                        <li>نشر إعلان واحد عن كل عقار سواء من خلالكم أو من خلال شركاتكم التابعة لكم.</li>
                        <li>امتلاك جميع التراخيص والتصاريح والموافقات اللازمة لاستخدام وعرض ونسخ ونشر المحتوى، مع امتلاك الأهلية والصلاحية والحق في منحنا رخصة باستخدام المحتوى .</li>
                        <li>عدم انتهاك و/أو استخدام حقوق الملكية الفكرية لدينا، بما في ذلك استخدامها بشكل يمسّ بنا أو يعد ضارًا أو مسيئًا أو مهينًا لعلامتنا التجارية أو سمعتنا .</li>
                        <li>عدم إساءة استخدام أو إفساد (أو السماح بإساءة استخدام أو إفساد) أي من الخدمات المقدمة لك .</li>
                        <li>تزويدنا برقم هاتف صحيح يمكننا الاتصال بك من خلاله، وفي حال تخصيص رقم هاتف متتبع لك، فإنك تسمح لنا بمراقبة وتسجيل الاتصالات الهاتفية بينك وبين أي طرف آخر يتصل بك عن طريق منصاتنا، مع عدم اتخاذك أي تصرف للاعتراض على مراقبتنا، كما توافق على أنه يجوز لنا ولموردينا تسجيل هذه المكالمات الهاتفية .</li>
                        <li>التحقق من وصول الأشخاص المفوضين من قبلك فقط لخدماتنا .</li>
                        <li>تقديم المحتوى في صورة تتوافق مع المواصفات الفنية الصادرة من جانبنا من وقتٍ لآخر.</li>
                        <li>إذا كنت تتصرف نيابةً عن مالك عقار، تلتزم بتقديم جميع الوثائق المطلوبة التى نطلبها منك لإثبات أن لديك صلاحية التصرف نيابةً عن مالك العقار (وتشمل على سبيل المثال لا الحصر: وكالة قانونية) .</li>
                        <li>الحصول والحفاظ طيلة مدة الإعلان على حقوق تسويق أو إيجار أو بيع العقارات المحددة في إعلاناتك، وإخطارنا فور فقدانك حقوق تسويق أو إيجار أو بيع العقارات المذكورة في الإعلان .</li>
                        <li>التصرف بصورة متسقة ومتفقة مع قوانين حماية البيانات وسياسة الخصوصية لدينا.</li>
                    </ol>
                    <p>2.2تقر وتوافق على ما يلي:</p>
                    <ol>
                        <li>أنك قرأت وتوافق على الالتزام بشروط جميع الإخطارات القانونية المنشورة على منصاتنا، بما في ذلك سياسة الخصوصية وسياسة ملفات الارتباط لدينا .</li>
                        <li>باستخدام الخدمات، تمنحنا ترخيصًا نهائيًا في جميع أنحاء العالم دون مقابل لحقوق الملكية في تسويق المحتوى (أو البيانات أو المعلومات الأخرى المأخوذة منه) ونسخه وعرضه ونشره ومشاركته وترخيص الغير و/أو استخدام ذلك بصورة أخرى، بغرض أداء التزاماتنا وفقًا للعقد وتقديم الخدمات وإنشاء خوارزميات/ منتجات مناسبة لازمة لتحسين الخدمات و/أو للأغراض التحليلية العامة، ويظل هذا الترخيص ساريًا بعد انتهاء العقد، كما توافق على أنه ما لم تتطلب القوانين المعمول بها ذلك، فإننا لن يُطلب منا تحديدك كمصدر للمحتوى في أي وسيلة إعلامية يُنشر أو يُعرض من خلالها المحتوى أو أي مشتق منه .</li>
                        <li>نحن لسنا مسؤولين عن أي أخطاء أو أخطاء في المحتوى، ولا نقدم أي ضمان أو أي تمثيل حول دقة المحتوى أو اكتماله</li>
                        <li>تتحمل مسؤولية رسوم جميع الاتصالات والوصول إلى الإنترنت عند استخدام منصاتنا ويتعين عليك سداد تكلفتها .</li>
                        <li>تتحمل مسؤولية أمن ومصداقية محتواك وبياناتك بشكل عام .</li>
                        <li>قد تحدث أخطاء أو تأخير في نقل البيانات عبر الإنترنت .</li>
                        <li>يجوز لنا الاتصال بك من وقتٍ لآخر لإعلامك بالفرص والمنتجات والخدمات المقدمة من جانبنا أو من تابعينا أو شركائنا التجاريين، وتمنحنا الإذن بالتواصل معك عبر الهاتف أو أي وسيلة إلكترونية لهذا الغرض .</li>
                        <li>يجوز لنا أن نحذف فورًا أي إعلان يخضع لحقوق الإعلان الحصرية لوكيل آخر.</li>
                        <li>لن تستخدم أي بيانات تتبع تم الحصول عليها عن طريق ملفات الارتباط أو باستخدام أدوات تتبع على منصاتنا لاستهداف أو إعادة استهداف أو إعلان أو التفاعل بأي شكل مع زوّار المنصات أو مستخدميها .</li>
                        <li>لن يتم تنشيط الخدمات ولن تتسلم الرصيد (أو قسائم الإعلان) في حسابك حتى نتسلم قيمة الرسوم (أو أقساطها الدورية) .</li>
                        <li>إذا كنت ترغب في استخدام مزودي خدمة إدارة علاقات العملاء من الغير لنشر البيانات على المنصات باستخدام منشورات XML، يجب على مزود خدمة إدارة علاقات العملاء أن (أ) يكون ضمن قائمة مزودي خدمة إدارة علاقات العملاء المعتمدة من جانبنا (ب) يوافق صراحةً على شروطنا وأحكامنا الخاصة بمزودي خدمة إدارة علاقات العملاء المنشورة على منصاتنا ويمتثل لها ("شروط إدارة علاقات العملاء")، وقد يؤدي انتهاك شروط إدارة علاقات العملاء من قبل مزودي خدمة إدارة علاقات العملاء إلى وقف المنشورات، فعليك أن تتحمل مسؤولية التأكد من امتثال مزود خدمة إدارة علاقات العملاء التابع لك لشروط إدارة علاقات العملاء، وسيعتبر أي مزود خدمة إدارة علاقات العملاء يقدم أي من خدمات إدارة علاقات العملاء مقرًا وموافقًا على شروط إدارة علاقات العملاء.</li>
                    </ol>

                    <strong>3.</strong>
                    <strong>مسؤوليات جودة المحتوى</strong>
                    <p>3.1تقر وتضمن التزام محتواك وإعلانك بما يلي:</p>
                    <ol>
                        <li>ألا يكون غير قانوني (أو يحث على تصرف غير قانوني) أو مسيء أو زائف أو مضلل أو غير لائق (ويشمل هذا المواد الإباحية أو المشجعة على الكراهية أو التي تعتبر مهينة عرقيًا أو أخلاقيًا) و/أو غير ملائمة بأي شكل آخر و(ب) لا يؤدي إلى مخالفة أو انتهاك النظام العام مع مراعاة الغرض من منصاتنا و(ج) لا يعرضنا إلى أي مسؤولية أو إجراءات قانونية أو أي عقوبة أخرى .</li>
                        <li>لا ينتهك بأي شكل حقوق الملكية الفكرية لدينا أو حقوق الملكية الفكرية للغير .</li>
                        <li>أن يكون صحيحًا وكاملًا ودقيقًا من جميع النواحي وأن تقوم بتحديث و/أو تصحيح المحتوى فور العلم بأي أخطاء أو عدم دقة .</li>
                        <li>أن يكون ممتثلًا ويظل ممتثلًا لأي إرشادات نصدرها من وقتٍ لآخر .</li>
                        <li>أن يكون ممتثلًا ويظل ممتثلًا لأي متطلبات خاصة بالإعلانات نحددها .</li>
                        <li>أن يشير فقط إلى تفاصيل العقار المحدد وأي إشارة إلى مواد أخرى خلاف تفاصيل العقار يجب أن تحصل على موافقة كتابية مسبقة منا .</li>
                        <li>ألا يتضمن بشكل مباشر أو غير مباشر الإعلان عن أي شيء آخر خلاف العقار المحدد أو التسويق له .</li>
                        <li>ألا يخالف شروط أو سياسات أو اتفاقات الغير، ويشمل هذا على سبيل المثال لا الحصر: شروط استخدام مزودي الخدمات من الغير.</li>
                    </ol>
                    <p>3.2تقر وتوافق على ما يلي:</p>
                    <ol>
                        <li>لسنا ملزمين بمراقبة أو رقابة المحتوى الصادر من جانبك، إلا أننا يحق لنا القيام بذلك وفقًا لتقديرنا المطلق دون إخطارك .</li>
                        <li>يجوز لنا حذف أو طلب حذف أو تعديل أو رفض عرض أي محتوى أو إعلان على المنصات، أو أن نطلب منك تعديل المحتوى أو الإعلان ليوافق متطلبات الاتفاقية الماثلة أو القوانين المعمول بها .</li>
                        <li>نحتفظ بالحق في تغيير القواعد و/أو اللوائح و/أو القيود و/أو الإجراءات الخاصة بأي إعلان أو محتوى مرفوع أو مقدم إلينا لرفعه (بما في ذلك نوع المحتوى وقيمته) في أي وقت.</li>
                        <li>غير مقبول استخدام الخدمة للإعلان عن نشاطك التجاري خلاف العقار، وأي إشارة إلى مواد أخرى خلاف تفاصيل العقار أو التطوير العقاري يجب أن تحصل على موافقة كتابية مسبقة منا.</li>
                    </ol>
                    
                    <strong>4.</strong>
                  <strong>الرصيد والإعلانات</strong>
                    <p>4.1لاستخدام رصيدك أو قسيمة إعلان لنشر إعلان، يتعين عليك:</p>
                    <ol>
                        <li>أن تكون مسجلًا كـ "مدير" أو "وكيل" في نظام إدارة العملاء لدينا .</li>
                        <li>أن يكون لديك رصيد كافٍ في حسابك (لاستخدامه خلال مدة صلاحية الرصيد) .</li>
                        <li>تحميل الإعلان من خلال نظام إدارة العملاء لدينا.</li>
                    </ol>
                    <p>4.2تقر وتوافق على ما يلي:</p>
                    <ol>
                        <li>لا نعد بأي شكل أو نضمن أي عملاء محتملين أو حد أدنى من العملاء المحتملين أو التفاعل .</li>
                        <li>لا يمكنك وقف أو إلغاء أو طلب رد أي رصيد، بما في ذلك على سبيل المثال لا الحصر: إذا لم يُعرض الإعلان أثناء جزء أو طيلة مدة الإعلان، أو إذا قررت تغيير نوع المنتج أو الخدمة المستخدمة لذلك الإعلان (بالترقية -على سبيل المثال- من إعلان "عادي" إلى إعلان "مميز" أو "بارز").</li>
                        <li>لا يترتب على فقدان أي رصيد نتيجة لحالة استبعاد أي رد للرصيد أو تعويض من أي نوع.</li>
                        <li>بمجرد قبول طلب إعلان، لا يمكنك وقف الخدمة أو استخدام رصيدها لإعلان آخر .</li>
                        <li>بمجرد قبول طلب إعلان، لا نعدك أو نضمن لك ظهور أي تغييرات لاحقة من جانبك على المنصات .</li>
                        <li>تنتهي صلاحية جميع الأرصدة التي لم يتم استخدامها بنهاية مدة صلاحية الرصيد، ولن تُحال إلى مدة صلاحية الرصيد التالية.</li>
                        <li>تنتهي صلاحية جميع الأرصدة الإضافية التي لم يتم استخدامها قبل نهاية مدة صلاحية الرصيد بنهاية مدة صلاحية ذلك الرصيد.</li>
                        <li>ستعتبر جميع الأرصدة التي لا تستخدم بنهاية مدة العقد قد استخدمت وستنتهي صلاحيتها تلقائيًا بشكل نهائي.</li>
                    </ol>
                    <p>4.3دون المساس بأي حقوق أخرى لنا وفقًا للاتفاقية الماثلة، يحق لنا حذف أي إعلان من منصتنا عند وقوع أي من الحالات التالية (يُشار إلى كل منها بـ "حالة استبعاد"):</p>
                    <ol>
                        <li>تفاصيل العقار المذكور في الإعلان غير دقيقة أو مضللة أو إذا كان العقار المعروض غير متاح لأي سبب.</li>
                        <li>في حالة بيع العقار المذكور في الإعلان أو تم تأجيره أو حجزه، أو ما زال في مرحلة التفاوض.</li>
                        <li>عدم استيفاء الإعلان لمتطلبات الإعلان التي حددناها و/أو نحددها.</li>
                        <li>عدم تطابق سعر أو إيجار أحد العقارات المذكورة في الإعلان مع طلب مالك العقار أو ممثل مالك العقار.</li>
                        <li>خضوع الاعلان المدرج لحقوق إعلان حصرية تخص وكيل آخر.</li>
                    </ol>
                
                    <strong>5.</strong>
                   
                     <strong>الرسوم والدفع</strong>
                    <p>5.1تلتزم بأن تدفع لنا الرسوم من خلال طرق الدفع التي نراها مناسبة وسنعرض لك طرق الدفع لك في نظام إدارة العملاء، عند طلب الخدمات من خلال نموذج طلب.</p>
                    <p>5.2تُستحق منك الرسوم في بداية مدة العقد و في حالة اختيارك طريقة دفع دورية، يُستحق دفع الرسوم في بداية كل فترة ائتمانية.</p>
                    <p>5.3مع مراعاة دفعك للرسوم (أو أي قسط منها) في تاريخ استحقاقها أو قبله يُضاف في حسابك عدد من الأرصدة (أو قسائم الإعلان، في حال وجود باقة إعلانات) بحسب المبين في نموذج الطلب في بداية مدة الرصيد لديك.</p>
                    <p>5.4فيما يتعلق بإلتزامات الدفع الخاصة بك، أنت ملزم بتقديم شيك خلال مدة أقصاها سبعة (7) أيام من تاريخ بداية العقد (أو أي فترة أخرى نحددها نحن)، بمبلغ يعادل قيمة العقد بالإضافة لأية رسوم تكون مستحقة عليك ، و يجوز لنا تقييد الوصول الى حسابك بعد خمسة (5) أيام (أي قبل انتهاء فترة السبعة (7) أيام) فى الأحوال التالية :</p>
                    <ol>
                        <li>في حالة إخفاقك في الوفاء بأي من التزامات الدفع الخاصة بك تجاهنا، يحق لنا تقديم الشيك إلى البنك لصرفه وفي مثل هذه الحالة، سوف نقوم باسترداد كامل الرسوم المستحقة لنا بالاضافة الى أي غرامات فرضناها فيما يتعلق بعدم الدفع وسيتم إرجاع أي مبلغ زائد تم تحصيله من قبلنا إليك.</li>
                        <li>في حالة ارتداد الشيك دون صرف لأي سبب، يحق لنا المطالبة بقيمة الشيك وفقًا للأحكام والقوانين المعمول بها (على سبيل المثال لا الحصر قانون دولة الإمارات العربية المتحدة، في حالة إبرام عقدك في دولة الإمارات العربية المتحدة)، ولا يحق لك الاعتراض على حقنا بأي طريقة، بما في ذلك على سبيل المثال وليس الحصر تقديم منازعة موضوعية فى التنفيذ، إقامة نزاع تجاري و/أو نزاع محدد القيمة وما إلى ذلك.</li>
                    </ol>
                    <p>5.5خلال فترة العقد، يسمح لك بشراء الأرصدة (الأرصدة الإضافية) وترقية الأرصدة عن طريق تقديم نموذج طلب جديد، والذي يخضع لقبولنا.</p>
                    <ol>
                        <li>تعتبر (ترقية الرصيد) ترقيةً لباقة الأرصدة الأولية المتوفرة لديك، وسوف تحصل (لدى استلامنا المبلغ) على الأرصدة المحددة في الباقة الأولية الخاصة بك بالإضافة إلى رصيد الترقية الذي سيكون متوفرًا خلال مدة العقد.</li>
                        <li>(الأرصدة الإضافية) هي أرصدة إضافية يتم شراؤها عبر نظام إدارة العملاء لدينا و/أو نموذج طلب يجب تنشيطه ضمن مدة الرصيد وسوف تكون قادرًا على الوصول إليها واستخدامها خلال مدة الرصيد.</li>
                    </ol>
                    <p>5.6في جميع حالات الدفع، تقر وتوافق على أنك مسؤول عن أي رسوم يفرضها البنك (البنوك) أو مقدمو خدمات بطاقة الائتمان.</p>
                    <p>5.7توافق وتقر بأنه من مسؤوليتك تقديم إثبات كافٍ للدفع (بما في ذلك أي أرقام مرجعية للمعاملة جنبًا إلى جنب مع رقم التعريف الفريد الذي قدمناه لك والذي قد نطلبه لتسوية الدفع) لضمان عدم حدوث انقطاع في خدماتك.</p>
                    <p>5.8في حالة الدفع من خلال التحويلات فيما بين البنوك / داخل البنوك، فأنت تقر وتوافق على أنه لن يتم الإفراج عن الأرصدة إلا بعد استلام هذا الدفع في حسابنا، وأنك مسؤول عن الدفع في الوقت المحدد لضمان عدم انقطاع الخدمة.</p>
                    <p>5.9يتعين عليك تقديم شيك بكامل مبلغ العقد (الرسوم الإجمالية) بالإضافة إلى أي رسوم إضافية وغرامات قد نحددها أو كليهما معًا وتلتزم بأن لا يحتوي الشيك على أي كلمة أو إشارة تمنع صرف الشيك، أو إلى الحد من حقوقنا القانونية في تحصيل قيمته بموجب أحكام القوانين المعمول بها (على سبيل المثال، بموجب قوانين دولة الإمارات العربية المتحدة، في حالة إبرام عقدكم في دولة الإمارات العربية المتحدة) وإذا حدث ذلك، تبقى مسؤوليتكم أمامنا بشأن القيمة الكاملة للشيك، بالإضافة إلى الرسوم والغرامات التي نحددها و يحق لنا اتخاذ أي إجراء قانوني نراه مناسبًا لحماية حقوقنا (بما في ذلك حقوقنا المالية مقابل التزاماتكم بالسداد نحونا) بموجب القوانين والإجراءات المعمول بها، و لا يحق لكم الاعتراض على هذا الحق بأي طريقة طبقاً لما هو منصوص عليه فى البند الخامس فقرة (ب) من العقد.</p>
                 
                    <strong>6.</strong>
                     <strong>الأسعار والضرائب</strong>
                    <p>6.1نحتفظ بحقنا في تعديل أسعار أي اشتراك، وقيمة الأرصدة، وباقة الأرصدة، وباقة الإعلانات، والمنتجات والخدمات أو أيًا مما سبق من حين لآخر لن نجري أي تعديل على الرسوم التي تدفعها مقابل باقة الأرصدة وباقة الإعلانات أو أيهما خلال مدة العقد ومع ذلك، أنت تقر وتوافق على أن أسعار أي أرصدة ترقية، والأرصدة الإضافية، والمنتجات والخدمات المخصصة التي تشتريها خلال مدة العقد قد تخضع للتغيير، وقد تتغير هذه الأسعار وفقًا لتقديرنا وحده.</p>
                    <p>6.2تقر وتوافق على أن قيمة الأرصدة المطبقة على "الإعلانات" متغيره بطبيعتها وبناءً على ذلك، أنت توافق على أنه يحق لنا تعديل قيمة الرصيد المقتطع مقابل الإعلان وأنواع الإعلان أو أيهما، وفقًا لتقديرنا وحده، خلال فترة العقد.</p>
                    <p>6.3وباعتبار ذلك جزءًا من اشتراكك، يمكنك الحصول على منتجات وخدمات معينة مجانًا تجدر الإشارة إلى أن هذا الأمر غير مضمون، ونحتفظ بالحق في فرض رسوم عليك مقابل هذه المنتجات والخدمات، وفقًا لتقديرنا وحده في حالة فرض رسوم علينا، لن تكون هذه "المنتجات والخدمات" متاحةً لك حتى دفع مقابل تلك الخدمات أو المنتجات.</p>
                    <p>6.4لا تشمل الأسعار التي نقدمها أية ضرائب واجبة التطبيق، وسيبين نموذج الطلب مبلغ الضرائب مستحقة الدفع فيما يتعلق باشتراكك توافق على تحمل المسؤولية في جميع الأوقات عن دفع أي ضرائب ذات صلة باشتراكك.</p>
                    <p>6.5في حالة اختيار الحكومة الكائنة في المكان الذي نقدم فيه خدماتنا فرض أي ضرائب، بما في ذلك ضريبة القيمة المضافة أو الضرائب المماثلة الأخرى أو الرسوم المفروضة على توفير أو بيع أو توريد أي سلع و/أو خدمات تكون موضوع هذا العقد (يُشار إليها فيما يلي بمصطلح "ضرائب مستقبلية")، فإننا نحتفظ عندئذ بالحق، وفقًا لتقديرنا وحده، أو إذا كان القانون ينص على ذلك، في إضافة ضرائب مستقبلية بمعدل مناسب لتلك السلع و/أو الخدمات علي أي فواتير صادرة بموجب أو فيما يتعلق بالعقد المبرم معك اعتباراً من الوقت ذي الصلة، وتقر وتقبل بأنك ستدفع وتكون مسؤولا فقط عن أي من هذه الضرائب المستقبلية بالإضافة إلى الرسوم المفروضة على هذه السلع والخدمات.</p>
                    
                    <strong>7.</strong>
                     <strong>الوصول المحدود، والتعليق، والإنهاء، وانتهاء العقد</strong>
                 
                    <p>7.1باستثناء الحد المسموح به بموجب لأحكام والقوانين المعمول بها، لا يحق لك إنهاء أو فسخ هذا العقد قبل نهاية مدة العقد تحت أي ظرف من الظروف وعلاوةً على ذلك، لا يسمح لك بتخفيض اشتراكك (أو أي من مكوناته، بما في ذلك باقة الأرصدة الخاصة بك) خلال مدة العقد، ما لم نوافق نحن على خلاف ذلك كتابيا.</p>
                    <p>7.2يجوز لنا، وفقًا لتقديرنا المطلق، أن نحد من وصولك إلى حسابك، في حالة استمرار التأخر في دفع الرسوم و/أو اي غرامات لمدة ثلاثة (3) أيام من تاريخ الاستحقاق.</p>
                    <p>7.3يجوز لنا، وفقًا لتقديرنا المطلق، تعليق حسابك، في حالة استمرار التأخر في دفع الرسوم و/أو أحد مكوناته لمدة سبعة (7) أيام من تاريخ الاستحقاق في هذه الحالة، سيتم إزالة كافة الإعلانات أو تفاصيل الوكيل أو معلومات أخرى، التي قد رفعتها على منصتنا لحين دفع كامل الرسوم والغرامات المستحقة عليكم.</p>
                    <p>7.4إذا قيّدنا الوصول إلى حسابك أو إذا علقنا حسابك أو اشتراكك لأي سبب خلال فترة العقد، تظل مسؤولًا عن دفع كامل قيمة العقد والرسوم والغرامات المستحقة عليك بصرف النظر عن حصولك على الخدمات.</p>
                    <p>7.5يجوز لنا، وفقًا لتقديرنا المطلق، إنهاء اشتراكك، في حالة الاستمرار في التأخر عن دفع الرسوم (أو أحد مكوناته) لمدة ستين (60) يوما من تاريخ الاستحقاق.</p>
                    <p>7.6عند إنهاء اشتراكك، تصبح جميع الرسوم غير المدفوعة مستحقة الدفع على الفور وتلتزم بدفعها لنا، و نحتفظ بحقنا في اتخاذ الإجراءات القانونية المناسبة ضدك وضد الشركات التابعة لك (حسب الاقتضاء) لإنفاذ التزامك بالسداد.</p>
                    <p>7.7عند إنهاء اشتراكك، قد نرفض حسب تقديرنا تقديم خدماتنا مستقبلاً لك أو للشركات التابعة لك، إلا إذا (1) دفعت جميع المبالغ المستحقة عليك؛ و (2) دفع الغرامة (التي نحددها حسب تقديرنا المطلق)، قبل إبرام عقد جديد معك. معك.</p>
                    <p>7.8من دون تقييد حقوقنا، يجوز لنا أن نقوم فورا بما يلي: (1) نفرض عليك عقوبات؛ و/أو (2) نعلق الخدمة أو نقيدها؛ و/أو (3) نزيل من على منصاتنا أي إعلانات باسمك أو تفاصيل عن أي عقار أو وكيل أو معلومات أخرى قد رفعتها على منصاتنا؛ و/أو (4) ننهي عقدك؛ و/أو (5) نفرض عليك غرامة مالية أو نطالبك بتعويض يعادل إجمالي قيمة العقد الموقع بيننا، في حالة:</p>
                    <ol>
                        <li>عدم التزامك بدفع الرسوم و،أو المستحقات بالكامل في تاريخ الاستحقاق.</li>
                        <li>عدم التزامك بتقديم أو عدم تقديم أي الشيك المذكور بالفقرة 5.4</li>
                        <li>مخالفتك لأي جزء من العقد المبرم معك وعدم التزامك بتصحيح هذا الإخلال خلال سبعة (7) أيام من تقديم إشعار يطالبك بالتصحيح .</li>
                        <li>انتهاكك الأحكام الواردة في هذه الاتفاقية (أو أي شروط منها) .</li>
                        <li>عدم التزامك بالمسؤوليات الخاصة بجودة المحتوى .</li>
                        <li>مشاركتك في أي إجراءات غير قانونية أثناء استخدام خدماتنا ومنصاتنا أو الوصول إليها.</li>
                        <li>دخولك في حالة إفلاس أو تصفية أو إدارة أو حراسة قضائية أو اتخاذ ترتيبات تسوية مع دائنيك أو تعيين حارس قضائي أو مدير على كل و،أو أي جزء من أصولك أو تصبح أو تعتبر معسرً أو وفاتك، أو إذا كنت في شراكة، في حالة حل تلك الشراكة أو تم تقديم طلب لحلّها، أو إذا كنت شركة، عند حل تلك الشركة أو تقديم طلب لحلّها.</li>
                    </ol>
                    <p>7.9في حالة تعليق اشتراكك لأي سبب خلال فترة العقد، تظل مسؤولًا عن دفع أي رسوم / رسوم مستحقة عليك بصرف النظر عن حصولك على الخدمات.</p>
                    <p>7.10بعد انتهاء مدة العقد، يمكنك تجديد اشتراكك من خلال إبرام عقد جديد، وفي هذه الحالة، تفهم وتوافق على أن الشروط والباقة (بما في ذلك سعر الرصيد أو الخدمة أو المنتج) قد تتغير.</p>
                    <p>7.11عند انتهاء مدة العقد، ما لم تجدد اشتراكك بإبرام عقد جديد معنا، فأنت توافق وتعترف بأن جميع الخدمات ستتوقف فورًا، وسيتم حذف جميع الإعلانات النشطة فورًا من منصتنا.</p>
                    
                    <strong>8.</strong>

                 <strong>الإسترداد</strong>
                    <p>8.1ليس لديك الحق في المطالبة باسترداد الرسوم (أو أي مكون منها).</p>
                    <p>8.2لن نعرض رد المبلغ إلا في حالة استلامنا معاملة مكررة وفي هذه الحالة لن يُرد سوى الجزء المكرر من المبلغ المدفوع وسيتم رد الجزء المكرر من الدفع لك من خلال طريقة الدفع الأصلية.</p>
                    <p>8.3في حالات المعاملات الاحتيالية أو المتنازع عليها، يجب الاتصال بالبنك أو مقدم خدمات بطاقة الائتمان.</p>
                   
                    <strong>9.</strong>

                       <strong>حماية البيانات</strong>
                    <p>9.1توافق على تخزين المحتوى الخاص بك ومعالجته على منصتنا إذا أرسلنا تفاصيل وبيانات الباحثين عن العقارات وكانت هذه التفاصيل تحتوي على بيانات شخصية (بناءً على تعريفها الوارد في قوانين حماية البيانات)، فأنت تقر بأنك ستكون المتحكم (بناءً على ما هو وراد في قوانين حماية البيانات) لنسخة البيانات الشخصية ويجب أن تلتزم بقوانين حماية البيانات السارية.</p>
                    <p>9.2تضمن وتقر أنك في حالة تزويدنا (بشكل مباشر أو غير مباشر) بالبيانات الشخصية المتعلقة بأي فرد، يجب أن تكون قد حصلت على موافقة ذلك الشخص على (1) معالجة واستخدام وتخزين تلك البيانات الشخصية بغرض تقديم الخدمات و(2) إرسال تلك البيانات الشخصية إلينا وإلى أطراف أخرى (بما في ذلك إلى البلدان التي لا تحافظ على معايير كافية لحماية البيانات) لمعالجتها واستخدامها وتخزينها بغرض توفير الخدمات.</p>
                    <p>9.39.3 لن يتم تخزين تفاصيل بطاقة الائتمان وبطاقة الخصم أو مشاركتها أو تأجيرها لأي طرف ثالث.</p>
                   
                    <strong>10.</strong>

                    <strong>حدود المسؤولية</strong>
                    <p>10.1لن نكون مسؤولين بموجب العقد عن أي مسؤولية تنتج عن:</p>
                    <ol>
                        <li>أي إخلال بالتزاماتك المنصوص عليها بموجب أحكام العقد المبرم معك.</li>
                        <li>أي تأخير في الأداء أو خرق لعقدك ينشأ نتيجةً لأي مسألة خارجة عن سيطرتنا، بما في ذلك على سبيل المثال وليس الحصر الفيروسات أو العيوب الأخرى أو فشل الخادم الذي يستضيف منصتنا.</li>
                    </ol>
                    <p>10.2نحن بالتحديد:</p>
                    <ol>
                        <li>نستبعد الخسائر أو الأضرار التبعية أو الخاصة أو غير المباشرة (بما في ذلك، على سبيل المثال وليس الحصر، فقدان الفرص، وخسارة الإيرادات، وخسارة الأرباح).</li>
                        <li>نقيّد مسؤوليتنا عن خرق أي شرط أو ضمان لا يمكننا استبعاده إلى أقصى حد (حسب اختيارنا):</li>
                        <ol>
                            <li>لإعادة توريد الخدمة.</li>
                            <li>لدفع تكلفة إعادة توريد الخدمة.</li>
                        </ol>
                        <li>نقصر مسؤوليتنا فيما يتعلق بأي مطالبة أخرى تتعلق بالعقد سواءً كانت المطالبة مستندة إلى العقد أو الضرر (بما في ذلك الإهمال) أو القانون على المبلغ الذي دفعته لنا بموجب نموذج الطلب الخاص بك.</li>
                    </ol>
                  
                    <strong>11.</strong>
               <strong>التعويض</strong>
                    <p>11.1أنت توافق على تعويضنا والدفاع عنا وحمايتنا وحماية كل من الشركات التابعة لنا وكل من مديريهم ومسؤوليهم وموظفيهم ومقاوليهم ووكلائهم (يُشار إلى كل منهم باسم "الطرف الحاصل على التعويض") إلى أقصى حد يسمح به القانون، من وضد أي نزاعات ومطالبات فعلية أو مزعومة أو مهدد بها أو معلقة أو مكتملة (بما في ذلك على سبيل المثال لا الحصر، المطالبات المقدمة من أي طرف ثالث أو أي وكالة حكومية أو تنظيمية)، وأي إجراءات وتحقيقات (يُشار إليها بشكل فردي باسم "مطالبة" ومجتمعة باسم "مطالبات") وأي أضرار (بما في ذلك الأضرار التأديبية أو الخاصة أو التبعية أو غير المباشرة أو التحذيرية أو العرضية)، وأي خسائر وأتعاب محاماة معقولة وتكاليف ونفقات ومبالغ تسوية (يُشار إلى كل منها باسم "خسارة" ومجتمعة باسم "خسائر") سواء كان لها أساس من الواقع أو القانون وتنشأ عن أو ترتبط بشكل مباشر أو غير مباشر بأي مما يلي: (1) العقد المُبرم معك (2) خطأ أو عدم مصداقية أو انتهاك أو خرق مزعوم تقوم به لأي تعهد أو ضمان؛ (3) أداء أو امتناع عن أداء أي التزامات بموجب العقد المُبرم معك، وذلك بغض النظر عن وقت حدوث الخسارة أو نشوء المطالبة أو البدء بها أو التهديد بها.</p>
                  
                    <strong>12.</strong>
                     <strong>إخلاء المسؤولية</strong>
                    <p>12.1يتم تقديم الخدمات "كما هي" وباستثناء الحد الذي يحظره القانون أو إلى الحد الذي تنطبق عليه أي حقوق قانونية لا يمكن استبعادها أو تقييدها أو التنازل عنها نحن والشركات التابعة لنا والمرخصون (أ) لا نقدم أي إقرارات أو ضمانات من أي نوع سواء كانت صريحة أو ضمنية أو قانونية أو بخلاف ذلك فيما يتعلق بالخدمات أو محتوى الطرف الثالث (ب) ونُخلي مسؤوليتنا عن جميع الضمانات، بما في ذلك أي ضمانات ضمنية أو صريحة بشأن ما يلي (1) القابلية للتسويق أو الجودة المُرضية أو الملاءمة لغرض معين أو التعدي على طرف ثالث أو التمتع الهادئ (2) ومسار التعامل أو الاستخدام أو الممارسة التجارية (3) واستمرارية أو عدم انقطاع الخدمات أو أي محتوى يقدمه طرف ثالث أو خلوه من الأخطاء أو خلوه من أي مكونات ضارة (4) وأمان أي محتوى وعدم وجود مخاطر بشأن فقده أو تغييره بأي طريقة أخرى.</p>
                   
                    <strong>13.</strong>
                   
                 <strong>القانون الحاكم وحل النزاعات</strong>
                    <p>13.1ما لم يتم تحديد ذلك في نموذج الطلب الذي قُمتَ بتوقيعه، بشكل افتراضي، يكون القانون الذي يحكم عقدك وآلية حل النزاع كما يلي:</p>
                    <ol>
                        <li>يخضع العقد وأي نزاع أو مطالبة (بما في ذلك النزاعات أو المطالبات غير التعاقدية) الناشئة عنه أو فيما يتعلق به أو بموضوعه أو تشكيله لقوانين دولة الإمارات العربية المتحدة ويتم تفسيرها وفقًا لها ويخضع الطرفان خضوعًا نهائيًا لا رجعة فيه للسلطة القضائية الحصرية لمحاكم دولة الإمارات العربية المتحدة.</li>
                        <li>يخضع إنفاذ حقوقنا الناشئة عن أو فيما يتعلق بالشيك الذي قدمتَه او تم تقديمه بالنيابة عنك (عندما لا يتم صرف هذا الشيك من قبل أحد بنوك دولة الإمارات العربية المتحدة لأي سبب من الأسباب) لقوانين دولة الإمارات العربية المتحدة ويتم تفسيرها وفقًا لذلك وفي هذه الحالة يخضع الطرفان خضوعًا نهائيًا لا رجعة فيه للسلطة القضائية الحصرية لمحاكم دولة الإمارات العربية المتحدة.</li>
                    </ol>
                    <p>13.2يجوز لنا تنفيذ حقوقنا بموجب العقد من خلال الجهة المتعاقدة مع <strong>هاوسيز</strong> أو من خلال أي من الشركات التابعة لنا.</p>
                   
                    <strong>14.</strong>
                     <strong>شروط عامة</strong>
                    <p>14.1يجوز لنا تغيير هذه الاتفاقية في أي وقت بموجب إشعار كتابي وأنت توافق بأن نشرنا للنسخة المعدلة من هذه الاتفاقية على منصاتنا يشكل إشعارًا كتابيًا مقدم لك بهذه التغييرات وكما ندعوك إلى زيارة موقعنا الإلكتروني دائمًا حتى تكون على علم بالتغييرات التي تطرأ على هذه الاتفاقية.</p>
                    <p>14.2لن نتعامل مع أو نقدم أي خدمات أو منتجات لأي من الدول الخاضعة لعقوبات مكتب مراقبة الأصول الأجنبية وفقًا للقانون المعمول به.</p>
                    <p>14.3بغض النظر عن أي شيء يتعارض مع هذه الاتفاقية فإننا نحتفظ بالحق في تغيير الخوارزمية المستخدمة في أي خدمات أو منتجات قد تشكل جزءًا من العقد المبرم معك في أي وقت خلال مدة العقد وفقًا لتقديرنا الخاص.</p>
                    <p>14.4نحتفظ بالحق في إضافة أي منتجات جديدة إلى الخدمات في أي وقت ولن يتضمن المعقد المُبرم معك أيًا من هذه المنتجات الجديدة ما لم نتفق عليها معًا.</p>
                    <p>14.5لن يعتبر أي تأخير أو إخفاق من جانبنا في تنفيذ أي حكم من أحكام العقد تنازلاً أو إنشاء سابقة أو مساسًا بحقوقنا.</p>
                    <p>14.6يتم إرسال أي إشعار/ إشعارات عن طريق البريد الإلكتروني أو البريد المدفوع مسبقًا أو خدمة التوصيل إلى عنوانك أو آخر عنوان نعرفه لك باللغة الإنجليزية.</p>
                    <p>14.7لا يجوز لك التنازل عن أي جزء من الخدمة و/أو التزاماتك المنصوص عليها في العقد دون الحصول على موافقة كتابية مسبقة منا.</p>
                    <p>14.8إننا نتعامل مع جميع البيانات الشخصية وفقًا للقوانين المعمول بها وسياسة الخصوصية المطبقة لدينا.</p>
                    <p>14.9إذا أصبح أي شرط من شروط العقد غير صالح أو غير قابل للتنفيذ لأي سبب من الأسباب بموجب القانون المعمول به أو بموجب حكم محكمة ذات ولاية قضائية على القانون المعمول به، فلن تتأثر صحة وقابلية تنفيذ باقي الشروط.</p>
                    <p>14.10أى تعارض بين النسخة المنشورة على موقعنا باللغة العربية واللغة الإنجليزية يتم العمل بالنسخة المنشورة باللغة العربية .</p>
                    <ol start={15}>
                        <li><strong>التعاريف</strong></li>
                    </ol>
                    <p>15.1</p>
                    <p>تنطبق التعاريف التالية في هذا الاتفاق:</p>
                    <p>"<strong>الحساب</strong>" يُقصد به الحساب الرئيسي أو الأساسي فيما يتعلق باشتراكك.</p>
                    <p>"<strong>القانون (القوانين) المعمول بها</strong>" يُقصد بها أي قانون أو تشريع أو قاعدة أو لائحة أو مدونة أو مرسوم أو حكم أو توجيه أو لائحة داخلية أو أمر أو دليل توجيهي أو أي إجراء تشريعي آخر (سواء كان له قوة القانون أم لا، وإذا لم يكن يتمتع بقوة القانون، فإنه يجري العمل عموماً على الالتزام به) لأي هيئة حكومية أو قانونية أو تنظيمية أو ذاتية التنظيم أو هيئة مماثلة أو سلطة أو محكمة، بصيغتها المعدلة أو المستبدلة أو المعاد سنها أو المعاد صياغتها أو إعادة تفسيرها من وقت لآخر.</p>
                    <p>"<strong>العلامة التجارية</strong>" يُقصد بها العلامة التجارية لشركة <strong>هاوسيز</strong> وجميع حقوق الملكية الفكرية التي تملكها أو تستخدمها <strong>هاوسيز</strong></p>
                    <p>"<strong>المحتوى</strong>" يُقصد به أي وكل المحتويات المتعلقة بما يلي (1) الإعلان، بما في ذلك جميع تفاصيله مثل الصور الفوتوغرافية والصور ومقاطع الفيديو والأسعار والمرافق والمسافات والخطط وغير ذلك (2) بياناتك وتفاصيلك بما في ذلك الصور الفوتوغرافية وتفاصيل الموظفين والمقاولين والوكلاء المرخصين و/أو الوسطاء (3) وحقوق الملكية الفكرية الخاصة بك.</p>
                    <p>"<strong>العقد</strong>" يُقصد به العقد المبرم مع <strong>هاوسيز</strong> ويتضمن نموذج (نماذج) الطلب التي قمتَ بتوقيعها وهذه الاتفاقية والشروط والأحكام المتعلقة بمنتج مُعين حيثما ينطبق ذلك.</p>
                    <p>"<strong>مدة العقد</strong>" يُقصد بها مدة العقد، على النحو المحدد في نموذج الطلب الخاص بك.</p>
                    <p>"<strong>الرصيد</strong><strong>"&nbsp;</strong>يُقصد به كل رصيد مدفوع مسبقًا يتم إضافته إلى حسابك، لاستخدامه لعرض الإعلانات المُدرجة من قبلك.</p>
                    <p>"<strong>باقة الرصيد&nbsp;</strong>" يُقصد بها باقة الرصيد التي قمتَ بشرائها ومن المقرر إضافتها إلى حسابك بشكل دوري أو كمبلغ ثابت دفعة واحدة.</p>
                    <p>"<strong>مدة الرصيد&nbsp;</strong>" يُقصد بها الدورة الموضحة في نموذج طلبك، حيث يتم في بدايتها تسليم جزء تناسبي من الأرصدة من باقة الرصيد المتوفرة لديك إلى حسابك، ويجب عليك في نهايتها استخدام هذه الأرصدة لعرض إعلان.</p>
                    <p>"<strong>فئة</strong><strong>/</strong><strong>طبقة العميل</strong>" يُقصد بها الشريحة أو الطبقة التي نخصصها لاشتراكك بناءً على المعايير التي نحددها ونعدلها، من وقت لآخر، وفقًا لتقديرنا الخاص. وسيتم تحديد فئة العميل الخاصة بك في نموذج طلبك.</p>
                    <p>"<strong>قوانين حماية البيانات</strong>" يُقصد بها القوانين المطبقة على جمع ومعالجة البيانات الشخصية في الولايات القضائية ذات الصلة.</p>
                    <p>"<strong>حالة الاستبعاد</strong>" تحمل المعنى المنصوص عليه في القسم 4-3.</p>
                    <p>"<strong>الحساب الفرعي</strong>" يُقصد به الحساب الفرعي الذي نقدمه لك وفقًا لتقديرنا الخاص فيما يتعلق باشتراكك.</p>
                    <p>"<strong>الرسم (الرسوم)</strong><strong>"</strong>&nbsp;يُقصد بها المبلغ الإجمالي المستحق عليك لشركة <strong>هاوسيز</strong> بموجب العقد على النحو المحدد في نموذج الطلب، ويمكن تقسيمه إلى دفعات دورية يجب عليك سدادها لنا بما في ذلك عند إنهاء عقد.</p>
                    <p>"<strong>حقوق الملكية الفكرية</strong>" يُقصد بها جميع براءات الاختراع وحقوق الاختراعات ونماذج المنفعة وحقوق النشر والحقوق المجاورة والعلامات التجارية والأسماء التجارية والشعارات وعلامات الخدمة والأسماء التجارية وأسماء الأعمال والنطاقات والحقوق في المظهر التجاري وحقوق التغليف وشكل السلعة وحقوق حسن النية أو رفع دعوى قضائية بسبب التقليد وحقوق المنافسة غير المشروعة والحقوق في التصاميم والحقوق في برامج الكمبيوتر وحقوق قاعدة البيانات والحقوق الطبوغرافية والحقوق المعنوية والحقوق في المعلومات السرية (بما في ذلك الدراية والأسرار التجارية) وأي حقوق ملكية فكرية أخرى، في كل حالة، سواء كانت مسجلة أو غير مسجلة، بما في ذلك جميع الطلبات الخاصة بهذه الحقوق وتجديدها أو توسيعها، وجميع الحقوق أو أشكال الحماية المماثلة أو المعادلة في أي جزء من العالم.</p>
                    <p>"<strong>الإعلان</strong>" يُقصد به المحتوى المتعلق بالعقار الذي تعرضه على منصاتنا.</p>
                    <p>"<strong>باقة الإعلانات</strong>" يُقصد بها العدد المحدد مسبقًا من الإعلانات (سواء كانت "ثابتة" أو "مميزة" أو "متكاملة" أو خليط منها) التي طلبتها، على النحو الموضح في نموذج الطلب الخاص بك؛</p>
                    <p>"<strong>مدة الإعلان</strong>" يُقصد بها الفترة التي سيتم خلالها عرض إعلانك على منصتنا.</p>
                    <p>"<strong>قسائم الإعلان</strong>" يُقصد به، في حالة باقة الإعلانات، عدد الإعلانات التي يمكنك عرضها على منصتنا على النحو المحدد في نموذج الطلب الخاص بك.</p>
                    <p>"<strong>الموقع (المواقع</strong>)" يُقصد به الموقع الفعلي المحدد في نموذج الطلب الخاص بك أو الموقع الذي نرى أنه يحق لك فيه الترويج لإعلانك.</p>
                    <p>"<strong>تطبيق الهاتف المحمول</strong>" يُقصد به تطبيق الهاتف المحمول الذي قامت شركة <strong>هاوسيز</strong> بتطويره وتشغيله بما في ذلك إصداراتها الإقليمية.</p>
                    <p>"<strong>نموذج الطلب</strong>" يُقصد به نموذج الطلب الذي يشير إلى (1) الخدمات المقدمة وفقًا لاشتراكك؛ (2) الرسوم المستحقة عليك؛ و(3) الشروط المادية الأخرى ذات الصلة باشتراكك.</p>
                    <p>"<strong>المنصة (المنصات</strong>)" يُقصد بها تطبيق الهاتف المحمول والموقع الإلكتروني وأي منصات أو ابتكارات تكنولوجية أخرى نستخدمها و/أو نديرها لتقديم الخدمات.</p>
                    <p>"<strong>المنتج (المنتجات</strong>)" يُقصد به الابتكار التكنولوجي الذي نمتلكه ونطوره لتقديم الخدمة (أو جزء منها) لك.</p>
                    <p>"<strong>وثائق المنتج</strong>" يُقصد بها الوثائق التي تحتوي على وصف ومواصفات كل خدمة من الخدمات.</p>
                    <p>"<strong>الباحثون عن عقارات</strong>" يُقصد به الزوار أو المستهلكين الذين يستخدمون المنصات لتصفح وشراء و/أو استئجار العقارات.</p>
                    <p>"<strong>الشروط والأحكام الخاصة بالمنتج</strong>" يُقصد بها الشروط والأحكام الإضافية التي تتعلق بمنتج و/أو خدمات محددة، بالإضافة إلى هذه الاتفاقية، التي تنطبق عليك وعلى اشتراكك.</p>
                    <p>"<strong>الخدمة (الخدمات</strong>)" يُقصد بها الخدمة (الخدمات) التي تقدمها <strong>هاوسيز</strong> على النحو المحدد في نموذج الطلب الخاص بك الموصوفة بالتفصيل في وثائق المنتج.</p>
                    <p>"<strong>نموذج الوكيل الحصري</strong>" يُقصد به النموذج المعتمد لدينا الذي يوقع عليه مالك العقار أو الممثل المعتمد لمالك العقار، حيث يمنحك أو يمنح وكيلك الحق الحصري في تسويق عقار محدد أو تأجيره أو بيعه.</p>
                    <p>"<strong>حقوق الإعلان الحصرية</strong>" يُقصد بها الحق الحصري لك أو لوكيلك في الاعلان عن عقار معين على النحو المنصوص عليه في نموذج الوكيل الحصري.</p>
                    <p>"<strong>الاشتراك</strong>" يُقصد به استحقاقك للخدمة (الخدمات)، ويخضع لدفع الرسوم وشروط العقد المبرم معك.</p>
                    <p>"<strong>زيادة الرصيد&nbsp;</strong>" يُقصد به أي رصيد (أرصدة) إضافية تشتريها خلال مدة الرصيد، ليتم تفعيلها واستخدامها خلال مدة الرصيد.</p>
                    <p>"<strong>ترقية الرصيد</strong>" يُقصد بها ترقية باقة رصيدك وبالتالي زيادة الأرصدة المضافة في حسابك (والمبلغ المستحق عليك) خلال كل مدة ائتمان من مدة العقد المتبقية.</p>
                    <p>"<strong>المُستخِدم</strong>" يُقصد به الشخص الاعتباري (1) الذي صرحتَ له أنت باستخدام الخدمة؛ (2) الشخص الذي طلبتَ من أجله الخدمة؛ (3) الشخص الذي قمتَ بإنشاء معرف المستخدم وكلمة المرور من أجله، كجزء من اشتراكك.</p>
                    <p>"<strong>حساب المستخدم</strong>" يُقصد به الحساب الفرعي الذي قمتَ بإنشائه للمستخدمين لديك فيما يتعلق باشتراكك؛</p>
                    <p>"<strong>الموقع الإلكتروني</strong>" يُقصد به&nbsp;<a  style={{ color: "#01BAFF" }} href="https://www.housez.ae">https://www.housez.ae</a>، ويتضمن جميع النطاقات الإقليمية (ccTLDs)المسجلة لدى <strong>هاوسيز</strong> ؛</p>
                    <p>"<strong>نحن</strong>" أو "<strong>لدينا</strong>" أو "<strong> هاوسيز</strong> يُقصد بها الطرف المتعاقد المعني في <strong>هاوسيز</strong> (انظر القسم 15 أدناه) والشركات التابعة لها وفروعها والشركة القابضة وموظفيها ومسؤوليها ووكلائها و/أو الأطراف التي تم تعيينها؛</p>
                    <p>"<strong>أنت</strong>" أو "<strong>الخاص بك</strong>" أو "<strong>العميل</strong>" يُقصد به الشخص الموجه له هذه الوثيقة، الذي يكون إما وسيطًا أو وكيلًا عقاريًا مرخصًا؛ أو شركة وساطة عقارية مرخصة و/أو مطور و/أو مزود شقق فندقية، ويكون - وفقًا لعقدك - متلقي للخدمة (الخدمات).</p>
                    <p>"<strong>الشركات التابعة لك</strong>" يُقصد بها أي شركة أو شخص اعتباري آخر تابع لك، بما في ذلك الشركات التابعة لك والشركة القابضة والشركات التابعة والمكاتب الفرعية داخل مجموعتك وموظفيها ومسؤوليها ووكلائها والمتنازل لهم المسموح بهم.</p>
                    <p>15.2فيما يتعلق بعقدك، يعتمد الطرف المتعاقد في البحث عن الممتلكات وعنوانه وقانون الإدارة وآلية تسوية المنازعات على الموقع، ويكون على النحو التالي:</p>
                    <p><strong>الموقع:</strong> الإمارات العربية المتحدة</p>
                    <p><strong>الطرف المتعاقد مع هاوسيز:</strong> هاوسيز منطقة حرة - ذ.م.م</p>
                    <p><strong>عنواننا:</strong> كما هو منصوص عليه في نموذج الطلب</p>
                    <p><strong>القانون الحاكم:</strong> انظر الفرع 13 أعلاه</p>
                    <p><strong>المحاكم ذات الولاية:</strong> انظر الفرع 13 أعلاه</p>

                    <p><strong>الموقع:</strong> جمهورية مصر العربية</p>
                    <p><strong>الطرف المتعاقد مع هاوسيز:</strong> هاوسيز مصر ذ.م.م</p>
                    <p><strong>عنواننا:</strong> كما هو منصوص عليه في نموذج الطلب</p>
                    <p><strong>القانون الحاكم:</strong> قوانين جمهورية مصر العربية</p>
                    <p><strong>المحاكم ذات الولاية:</strong> محاكم جمهورية مصر العربية</p>

                    <p><strong>الموقع:</strong> دولة قطر</p>
                    <p><strong>الطرف المتعاقد مع هاوسيز:</strong> بي إف كيو هاوسيز ذ.م.م</p>
                    <p><strong>عنواننا:</strong> كما هو منصوص عليه في نموذج الطلب</p>
                    <p><strong>القانون الحاكم:</strong> قوانين دولة قطر</p>
                    <p><strong>المحاكم ذات الولاية:</strong> محاكم دولة قطر</p>

                    <p><strong>الموقع:</strong> مملكة البحرين</p>
                    <p><strong>الطرف المتعاقد مع هاوسيز:</strong> بي إف بي البحرين ذ.م.م</p>
                    <p><strong>عنواننا:</strong> كما هو منصوص عليه في نموذج الطلب</p>
                    <p><strong>القانون الحاكم:</strong> قوانين مملكة البحرين</p>
                    <p><strong>المحاكم ذات الولاية:</strong> محاكم مملكة البحرين</p>

                    <p><strong>الموقع:</strong> المملكة العربية السعودية</p>
                    <p><strong>الطرف المتعاقد مع هاوسيز:</strong> شركة الباحث المؤومتي (Al Bahith Al Maoumati Co)</p>
                    <p><strong>عنواننا:</strong> كما هو منصوص عليه في نموذج الطلب</p>
                    <p><strong>القانون الحاكم:</strong> قوانين المملكة العربية السعودية</p>
                    <p><strong>المحاكم ذات الولاية:</strong> محاكم المملكة العربية السعودية</p>

                    <p>&nbsp;</p>
                    <p>15.3أنت تقر وتوافق على أنه يجوز لنا، وفقًا لتقديرنا الخاص ودون موافقتك التنازل عن حقوقنا والتزاماتنا بموجب العقد المبرم معك إلى أي عضو في مجموعة <strong>هاوسيز</strong> وفي هذه الحالة تستمر التزاماتك بموجب عقدك لصالح هذا العضو في مجموعة <strong>هاوسيز</strong>.</p>
                    <p>&nbsp;</p>
                    <br /><br /><br />
                </>
            )}

        </>
    )
}