import './companies.scss';
import { useLanguage } from "../../context/LanguageContext";
import { PuffLoader } from "react-spinners";
import { useFetchCompanySuggestions, useGetCompanies, useGetCompanyTypes } from "../../services/companiesServices";
import CompanyCard from "../../components/companyCard/companyCard";
import PaginationComponent from '../../components/Pagination/PaginationComponent';
import FilterContainer from '../../components/filters/FilterContainer';
import { useGetEmirates } from '../../services/filtersServices';
import FilterCountDisplay from '../../components/filters/FilterCountDisplay/FilterCountDisplay';
import { useTranslation } from 'react-i18next';
import { useFilterState } from './filterState';
import { useState } from 'react';

export default function Companies() {

    const { language } = useLanguage();
    const { t } = useTranslation();
    const itemsPerPage = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 8;

    const [filterParams, updateFilters] = useFilterState({
        page: 0,
        size: itemsPerPage,
        language: language.toUpperCase(),
    });

    const { emirates } = useGetEmirates({ language: language.toUpperCase() });
    const [textSearchCompanyName, setTextSearchCompanyName] = useState<string>("");

    const { companies: companiesName } = useFetchCompanySuggestions(textSearchCompanyName);
    const { result: companyTypes } = useGetCompanyTypes();
    const { result, loading } = useGetCompanies(filterParams);
    const companies = result?.result;
    const totalItems = result?.pageInfo?.totalElements || 0;

    const handlePageChange = (page: number) => {
        updateFilters({ page: page - 1 });
    };

    // Function to reset filter parameters
    const resetFilterParams = () => {
        setTextSearchCompanyName("")
        updateFilters({
            ...filterParams,
            companyName: "",
            typeID: undefined,
            emirateIDs: [],


        });

    };

    const filtersConfig = [
        {
            type: 'text' as const,
            param: 'companyName',
            placeholder: t('companies.CompanyName'),
            value: filterParams.companyName,
            options: companiesName,
        },
        {
            type: 'select' as const,
            param: 'typeID',
            options: companyTypes?.result || [],
            placeholder: t('filters.companyType'),
            value: filterParams.typeID,
            multiple: false,
            className: "company custom-select",

        },
        {
            type: 'select' as const,
            param: 'emirateIDs',
            options: emirates?.result || [],
            placeholder: t('filters.Emirate'),
            value: filterParams.emirateIDs,
            multiple: true,
            className: "company custom-select"
        },
    ];
    return (
        <>
            <FilterContainer filtersConfig={filtersConfig} onFilterChange={updateFilters} onSearch={updateFilters}
                setText={setTextSearchCompanyName}

                onReset={() => {

                    resetFilterParams()
                }} />
            <div className="companies">
                {loading ? (
                    <div className="loader">
                        <PuffLoader color="#54B0ED" loading={loading} />
                    </div>
                ) : (
                    <>
                        <FilterCountDisplay
                            title={t('companies.RealEstateAgenciesInUAE')}
                            entityName={t("companies.Companies")}
                            currentPage={filterParams.page}
                            itemsPerPage={filterParams.size}
                            totalItems={totalItems}
                        />
                        <div className="companies-grid">
                            {companies?.map((company: any, index: any) => (
                                <div className="item" key={index}>
                                    <CompanyCard company={company} />
                                </div>
                            ))}
                        </div>
                        <PaginationComponent
                            current={filterParams.page}
                            total={totalItems}
                            pageSize={filterParams.size}
                            onChange={handlePageChange}
                        />
                    </>
                )}
            </div>
        </>
    );
}
