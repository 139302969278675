import { useRef, useState, useEffect } from "react";
import './popularInEmirates.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import PopularCard from "../popularCard/popularCard";
import { useTranslation } from "react-i18next";
import SliderControls from "../sliderControls/sliderControls";
import { Emirate } from "../../../helpers/types/general";
import { useLanguage } from "../../../context/LanguageContext";

interface PopularInEmiratesProps {
    emirates: Emirate[];
    onSearchClick?: (search: Emirate) => void;
    onViewAllClick?: () => void;
}

const PopularInEmirates: React.FC<PopularInEmiratesProps> = ({ emirates, onSearchClick, onViewAllClick }) => {
    const swiperRef = useRef<any>(null);
    const { t } = useTranslation();
    const { language } = useLanguage();
    const direction = language === 'ar' ? 'rtl' : 'ltr';
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handlePrev = () => {
        if (swiperRef.current?.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNext = () => {
        if (swiperRef.current?.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handleItemClick = (item: Emirate) => {
        if (onSearchClick) {
            onSearchClick(item);
        }
    };

    useEffect(() => {
        if (swiperRef.current?.swiper) {
            const swiperInstance = swiperRef.current.swiper;
            const updateNavigationState = () => {
                setIsBeginning(swiperInstance.isBeginning);
                setIsEnd(swiperInstance.isEnd);
            };

            swiperInstance.on('slideChange', updateNavigationState);
            swiperInstance.on('reachEnd', updateNavigationState);
            swiperInstance.on('reachBeginning', updateNavigationState);

            updateNavigationState();

            return () => {
                swiperInstance.off('slideChange', updateNavigationState);
                swiperInstance.off('reachEnd', updateNavigationState);
                swiperInstance.off('reachBeginning', updateNavigationState);
            };
        }
    }, [emirates?.length, direction]);

    const [spaceBetween, setSpaceBetween] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setSpaceBetween(window.innerWidth <= 766 ? 1 : 26);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="popular">
            <h2>{t("discover.DiscoverYourDreamHomeWithEase")}</h2>

            <div className="popular-residential">
                <div className="slider">
                    <div className="navigates">
                        {swiperRef.current?.swiper?.rtl ? (
                            <>
                                <img
                                    className={`navigate ${isBeginning ? 'disabled' : ''}`}
                                    onClick={handlePrev}
                                    src={'/images/arrow-narrow-circle-broken-left.svg'}
                                    alt="Next"
                                />
                                <img
                                    id="svg-icon"
                                    className={`navigate ${isEnd ? 'disabled' : ''}`}
                                    onClick={handleNext}
                                    src={'/images/arrow-narrow-circle-broken-right.svg'}
                                    alt="Previous"
                                />
                            </>
                        ) : (
                            <>
                                <img
                                    id="svg-icon"
                                    className={`navigate ${isBeginning ? 'disabled' : ''}`}
                                    onClick={handlePrev}
                                    src={'/images/arrow-narrow-circle-broken-right.svg'}
                                    alt="Previous"
                                />
                                <img
                                    className={`navigate ${isEnd ? 'disabled' : ''}`}
                                    onClick={handleNext}
                                    src={'/images/arrow-narrow-circle-broken-left.svg'}
                                    alt="Next"
                                />
                            </>
                        )}
                    </div>
                    <Swiper
                        key={direction}
                        ref={swiperRef}
                        slidesPerView={'auto'}
                        spaceBetween={spaceBetween}
                        modules={[Navigation]}
                    >
                        {emirates?.map((option, index) => (
                            <SwiperSlide key={index} onClick={() => handleItemClick(option)} >
                                <PopularCard areaName={option.name} img={option?.image || "/images/abuDhabi.png"} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <SliderControls isAtStart={isBeginning} isAtEnd={isEnd} className={"mobile-show"} handlePrev={handlePrev} handleNext={handleNext} onViewAllClick={onViewAllClick} />
            </div>
        </div>
    );
};

export default PopularInEmirates;
