import './downloadSection.scss';
import { useTranslation } from "react-i18next";

export default function DownloadSection() {
    const { t } = useTranslation();
    return (
        <div className="download">
            <img className="img" src={"/images/download-3.png"}/>
            <div className="details">
                <div className="title">
                    <h2>{t("download.title")}</h2>
                    <span>{t("download.description")}</span>
                </div>
                <div className="download-btns">
                    <div className="btns">
                        <button>
                            <img src={"/images/googlePlay.svg"}/>
                        </button>
                        <button>
                            <img src={'/images/appStore.svg'}/>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}