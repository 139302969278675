import React, { useState } from 'react';
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';
import './mapComponent.scss';
import { useTranslation } from 'react-i18next';

interface MapComponentProps {
    address: string;
    latitude: number;
    longitude: number;
    googleMapsApiKey: string;
}

const ContactUsMap: React.FC<MapComponentProps> = ({ address, latitude, longitude, googleMapsApiKey }) => {
    const { t } = useTranslation();
    const [isMapLoaded, setIsMapLoaded] = useState(false);

    const handleMapLoad = () => {
        setIsMapLoaded(true);
    };

    return (
        <div className="map-section-contactUs">
          
            <div className="map-container">
                <LoadScriptNext googleMapsApiKey={googleMapsApiKey}>
                    <GoogleMap
                        center={{ lat: latitude, lng: longitude }}
                        zoom={12}
                        onLoad={handleMapLoad}
                        mapContainerClassName="map"
                    >
                        <Marker position={{ lat: latitude, lng: longitude }} />
                    </GoogleMap>
                </LoadScriptNext>
                {!isMapLoaded && (
                    <div className="loading-placeholder">Loading map...</div>
                )}
            </div>
        </div>
    );
};

export default ContactUsMap;
