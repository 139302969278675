import React, { useRef, useEffect, useState } from 'react';
import './Filter.scss';
import { useLocation, useNavigate } from 'react-router-dom';

interface FilterProps {
    filters: { id: string; name: string }[];
    selectedFilter: string;
    onFilterSelect: (id: string, name: any) => void;
    selectedFilterName?: string;
    setSelectedFilter?: any
    blogSectionName?: any
}

const Filters: React.FC<FilterProps> = ({ blogSectionName, setSelectedFilter, filters, selectedFilter, onFilterSelect, selectedFilterName }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    let isMouseDown = false;
    let startX: number, scrollLeft: number;

    useEffect(() => {
        if (blogSectionName) {
            sessionStorage.setItem('selectedFilterName', blogSectionName && blogSectionName[0]);
        }
        const savedFilter = sessionStorage.getItem('selectedFilter') || 'all';
        const savedFilterName = sessionStorage.getItem('selectedFilterName') || selectedFilterName;
        if (savedFilterName && filters?.length > 0) {
            const matchingFilter = filters.find(filter => filter.name === savedFilterName);
            if (matchingFilter) {
                setSelectedFilter(matchingFilter.id);
                sessionStorage.setItem('selectedFilter', matchingFilter.id);
            } else {
                setSelectedFilter(savedFilter);
                sessionStorage.setItem('selectedFilter', savedFilter);

            }
        } else {
            setSelectedFilter(savedFilter);
            sessionStorage.setItem('selectedFilter', savedFilter);

        }
    }, [filters, selectedFilterName, blogSectionName]);

    const handleFilter = (id: string, name: string) => {
        setSelectedFilter(id);
        onFilterSelect(id, name);
        sessionStorage.setItem('selectedFilter', id);
        sessionStorage.setItem('selectedFilterName', name);

        if (location.pathname !== '/blogs' && id !== selectedFilter) {
            navigate('/blogs');
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        isMouseDown = true;
        startX = e.pageX - (scrollContainerRef.current?.offsetLeft || 0);
        scrollLeft = (scrollContainerRef.current?.scrollLeft || 0);
    };

    const handleMouseLeave = () => {
        isMouseDown = false;
    };

    const handleMouseUp = () => {
        isMouseDown = false;
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isMouseDown) return;
        e.preventDefault();
        const x = e.pageX - (scrollContainerRef.current?.offsetLeft || 0);
        const walk = (x - startX) * 3;
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = scrollLeft - walk;
        }
    };
    return (
        <div className='blogs-filters blogs-categories'>
            <div
                ref={scrollContainerRef}
                className='scrollItems'
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
            >
                {filters?.map((filter) => (
                    <button
                        className={`filter-button ${selectedFilter.toString() === filter.id.toString() ? 'active' : ''}`}
                        key={filter.id}
                        onClick={() => handleFilter(filter.id, filter.name)}
                    >
                        {filter.name}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Filters;
