import { useLocation } from "react-router-dom";

export const useNativeShare = (message: any, id?: any, checkPage?: boolean) => {
  const location = useLocation();
  const currentUrl = window.location.href;
  const handleNativeShare = () => {
    let updatedUrl = currentUrl;
    if (location.pathname === '/') {
      updatedUrl = `${currentUrl}property`;
    } else if (sessionStorage.getItem('aiModal') === "true") {
      updatedUrl = `${window.location.origin}/property`;
    } else if (updatedUrl.includes("properties")) {
      updatedUrl = `${window.location.origin}/property`;
    } else if (updatedUrl.includes("blogs")) {
      updatedUrl = updatedUrl.replace("blogs", "blog");
    }
    else if (updatedUrl.includes("agentProfile") && checkPage === true) {
      updatedUrl = updatedUrl.replace(/agentProfile\/\d+/, "property");
    }

    if (navigator.share) {
      navigator.share({
        title: message,
        text: message,
        url: `${updatedUrl}/${id ? id : ''}`,
      }).catch(err => {
        console.error("Error sharing", err);
      });
    }
  };
  return handleNativeShare;
};
