import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './terms&conditions.scss'

export default function TC() {
    const { i18n } = useTranslation()

    return (
        <>
            {i18n.dir() === 'ltr' ? (
                <>
                    <strong> 1. Who we are and how to contact us </strong>
                    <br /><br />
                    <a href="www.Housez.ae" style={{ color: "#01BAFF" }}>www.Housez.ae</a> is a website (the <strong>“Website”</strong>) owned and operated by Housez FZ LLC and its worldwide affiliates (<strong>“we”</strong>, <strong>“us”</strong> and<strong>“our”</strong> ).
                    <br /><br />
                    To contact us, please email <a style={{ color: "#01BAFF" }} href="mailto:info@Housez.ae">info@Housez.ae </a>
                    <br /><br />
                    <strong>2. You accept these Terms by using the Website</strong>
                    <br /><br />
                    These Website terms of use (<strong>“Terms”</strong>) constitute a legal agreement between us and you. By using the Website, you confirm that you accept these Terms and that you agree to comply with them. If you do not accept and agree to comply with these Terms, then you must not use the Website.
                    <br /><br />
                    You may not use the Website and may not accept these Terms if (a) you are not of eighteen (18) years of age, or (b) you are a person who is either barred or otherwise legally prohibited from receiving or using the Website under the laws of the country in which you are a resident or from which you access or use the Website.
                    <br /><br />
                    You are responsible for ensuring that all persons who use the Website through your network are aware of these Terms and other applicable terms and conditions, and that they comply with them.
                    <br /><br />
                    <strong>3. These are other terms that may apply to you</strong>
                    <br /><br />
                    These Terms incorporate the following additional terms, which also apply to your use of the Website: our Privacy Policy; and if you download our mobile app, our App Terms of Use.
                    <br /><br />
                    <strong>4. How we process your personal information </strong>
                    <br /><br />
                    We will only collect and process your personal information in compliance with our Privacy Policy.
                    <br /><br />
                    <strong>5. How you may use material on the Website </strong>
                    <br /><br />
                    We are the owner or the licensee of all intellectual property rights in the Website and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
                    <br /><br />
                    We, together with our suppliers and licensors expressly reserve all intellectual property rights in all text, programs, products, processes, technology, content and other materials, which appear on the Website. Access to the Website does not confer and shall not be considered as conferring upon anyone any license under any of our or any third party's intellectual property rights. Any use of the Website or its contents, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use, is prohibited without our permission. You may not modify, distribute or re-post anything on the Website for any purpose.
                    <br /><br />
                    Our names and logos and all related product and service names, design marks and slogans are the trademarks or service marks of us or our licensors. No trademark or service mark license is granted in connection with the materials contained on the Website.
                    <br /><br />
                    Access to the Website does not authorize anyone to use any name, logo or mark in any manner whatsoever.
                    <br /><br />
                    You may temporarily download copies of the materials (information or software) on the Website for personal, non-commercial transitory viewing only and you may draw the attention of others within your organization to content posted on the Website.
                    <br /><br />
                    You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
                    <br /><br />
                    Our status (and that of any identified contributors) as the authors of content on the Website must always be acknowledged.
                    <br /><br />
                    You must not use any part of the content on the Website for commercial purposes without obtaining a license to do so from us or our licensors.
                    <br /><br />
                    If you print off, copy or download any part of the Website in breach of these Terms, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials in your possession whether in electronic or printed format.
                    <br /><br />
                    <strong>6. You must keep your account details safe </strong>
                    <br /><br />
                    If you choose, or if you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.

                    We have the right to disable any user identification, code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these Terms.

                    If you know or suspect that anyone other than you know your user identification, code or password, you must notify us at support@Housez.ae
                    <br /><br />
                    <strong>7. How you must not act when using the Website </strong>
                    <br /><br />
                    <ol>
                        <li>You must not do any act that we would deem to be inappropriate, is unlawful, or is prohibited by any laws applicable to the Website, including, but not limited to:
                            <br /><br />
                            <ol type="a" style={{ paddingLeft: "20px;" }}>
                                <li>using any automated device, software process or means to access, retrieve, scrape, or index the Website or any content on the Website;</li>
                                <br /><li>using any device, software, process or means to interfere or attempt to interfere with the proper working on the Website;</li>
                                <br /> <li>undertaking any action that will impose a burden or make excessive traffic demands on our infrastructure that we consider to be unreasonable or disproportionate Website usage;</li>
                                <br /> <li>attempting to decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Website;</li>
                                <br /> <li>using or indexing any content or data on the Website for purposes of:
                                    <br />  <ol type="i" style={{ paddingLeft: "30px;" }}>
                                        <br />  <li>constructing or populating a searchable database of properties;</li>
                                        <br /> <li>building a database of property information;</li>
                                        <br /> <li>competing with us in any manner that we have not specifically authorized;</li>
                                        <br /><li>transmitting spam, chain letters, contents, junk email, surveys, or other mass messaging, whether commercial in nature or not;</li>
                                        <br /><li>using the Website or any content from the Website in any manner which we determine as not reasonable and/or not for the purpose which it is made available;</li>
                                        <br /> <li>violating the rights of any person, including copyright, trade secret, privacy right, or any other intellectual property or proprietary right;</li>
                                        <br /> <li>posing as any person or entity or attempt to solicit money, passwords, or personal information from any person;</li>
                                        <br /> <li>acting in violation of any such terms or other condition stipulated by us or any applicable law;</li>
                                        <br /> <li>reproducing, republishing, retransmitting, modifying, adapting, distributing, translating, creating derivative works or adaptations of, publicly displaying, selling, trading, or in any way exploiting the Website or any content on the Website, except as expressly authorized by us;</li>
                                        <br /> <li>transmitting or attempting to transmit any computer viruses, worms, defects, or other items of a destructive nature;</li>
                                        <br /> <li>gaining or attempting to gain unauthorized access to the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website; or</li>
                                        <br /> <li>attacking or attempting to attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                    </ol>

                    If necessary, we may put in place measures to prevent unauthorized access and use of the Website, including but not limited to, instituting technological barriers, or reporting unauthorized or illegal conduct to any person or entity.
                    <br /><br />
                    <strong>8. Your interaction with real estate agents through the Website </strong>
                    <br /><br />
                    We are not a real estate agency. We provide a service whereby agents may market or promote, and you may view property details (“Details”) together with other content hosted and developed by us. Agents and third parties are responsible for preparing the Details and fielding enquiries directly from you. Other than to facilitate introductions between you and the relevant agent when you make an enquiry about a property, we do not get involved in any further communications between you and agents and we do not participate in any part of the transaction.
                    <br /><br />
                    Details are hosted by us in good faith but are produced directly by agents and/or third parties and have not been verified by us. You are responsible for making your own enquiries and we provide no guarantee and accept no responsibility for the accuracy or completeness of any information contained within the Details.
                    <br /><br />
                    <ol>
                        <li>You are responsible for:
                            <ol type="a" style={{ paddingLeft: " 20px;" }}>
                                <br /> <li>checking, confirming and satisfying yourself as to the accuracy of any Details;</li>
                                <br /> <li>instructing a surveyor and/or obtaining legal advice before committing to any purchase; and</li>
                                <br /> <li>ensuring that you act in good faith towards any other parties.</li>
                            </ol>
                        </li>
                    </ol>


                    You represent and warrant that your use of the Website will comply at all times with these Terms of Use and any further terms that may apply to you in relation to your use of the Website, including all amendments and revisions to these Terms in accordance with Clause 13 herein.
                    <br /><br />
                    <strong>9. Our responsibility for loss or damage suffered by you</strong>
                    <br /><br />

                    To the extent permitted by law, we will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, 3 arising out of the use or inability to use the Website; or the use of or reliance on any content displayed on the Website.
                    <br /><br />
                    <ol>
                        <li>We will not be liable for any loss or damage arising under or in connection with:
                            <ol type="a" style={{ paddingLeft: " 20px;" }}>
                                <br />  <li>any failures due to software or internet errors or unavailability, or any other circumstances beyond our reasonable control;</li>
                                <br /> <li>any loss of your password or account if caused by a breakdown, error, loss of power or otherwise caused by or to your computer system and/or your account;</li>
                                <br />  <li>the use of, or inability to use, our website;</li>
                                <br /> <li>the reliance on any content or information displayed on our website;</li>
                                <br />  <li>any direct, consequential, special or punitive loss, damage, costs and expenses;</li>
                                <br /> <li>loss of profit;</li>
                                <br />  <li>loss of business;</li>
                                <br /> <li>loss of reputation;</li>
                                <br /> <li>depletion of goodwill; or</li>
                                <br />   <li>loss of, damage to or corruption of data.</li>
                            </ol>
                        </li>
                    </ol>


                    Unless we otherwise expressly agree in writing, you agree not to use the Website for any commercial or business purposes.
                    <br /><br />
                    We do not guarantee that the Website will be secure or free from errors, bugs or viruses. We are not liable to you or anyone else for any loss or damage caused by a virus, distributed denial of service attack or other technological harmful material that may infect your computer equipment, computer programmers, data or other proprietary materials due to your use of the Website or to your downloading of any content on it, or any website linked to it. You are responsible for configuring your information technology, computer programmers and platform to access the Website. You should use your own virus protection software.
                    <br /><br />
                    If you enquire about a property on this Website, you acknowledge and agree that your details will be sent by email or other communication means, such as WhatsApp, directly to the agent, estate agent, landlord, developer marketing the property or properties you are enquiring about. We do not accept any liability for any subsequent communications that you receive directly from that estate agent, landlord or developer and/or any third party.
                    <br /><br />
                    <strong>10. Content on the Website</strong>
                    <br /><br />

                    The materials appearing on the Website could include technical, typographical, or photographic errors. We do not warrant that any of the materials on the Website are accurate, complete, or current. We may make changes to the materials contained or displayed on the Website at any time without notice.
                    <br /><br />
                    <strong>11. Making the Website available to you</strong>
                    <br /><br />

                    We strive to ensure that the Website and the services are always available to you, but we do not guarantee that the Website or the services will operate continuously, without interruptions or be fault free. On occasion, necessary maintenance or upgrade work requires us to make the Website and the services unavailable without notice, but we aim to keep downtime to a minimum. We accept no liability for any interruption or loss of service. We may alter, suspend or discontinue any part of the Website or the services, including your access to it.
                    <br /><br />
                    <strong>12. Links to other websites </strong>
                    <br /><br />

                    The Website may contain links, hyperlinks and pointers to third party products, services and/or websites that are not affiliated with us, and which are provided for your information only. We have no control over the content, products, services of those sites or resources of third parties and we do not guarantee or take responsibility for them. The Website may also contain advertising from third parties, and we are not responsible, nor do we make any warranties or representations for any misleading or inaccurate advertisements which are the sole responsibility of the advertiser.
                    <br /><br />
                    Any links or advertisements on the Website should not be interpreted as approval by us of those linked sites or information you may obtain from them. The Website also contains data provided by third parties and we are not responsible for, nor do we make any warranties or representations for any inaccuracies in such data. You agree to release us from any claims or disputes of any kind arising from or in any way connected to such disputes with third parties.
                    <br /><br />
                    By using the Website, you grant us an irrevocable, world-wide, royalty free license to commercialize, copy, license to other persons, use and adapt for any purpose any material you generate or submit to make use of the Website. We do not warrant that the content, links, or sub- domains contained on, or associated with the Website will be available and accessible to you at all times. Information in our publications, posts, inserts, information, content should not be regarded as a substitute for professional legal, financial or real estate advice.
                    <br /><br />
                    <strong>
                        13. Changes to the Terms
                    </strong>
                    <br /><br />
                    We may revise these Terms from time to time without notice. Each time you wish to use the Website, please check these Terms to ensure that you understand the terms that apply at that time. In addition, we may also update and change the Website from time to time without notice.
                    <br /><br />
                    <strong>14. Your contributions on the Website</strong>
                    <br /><br />

                    In these Terms “Contributions” means any information including data, text, video, still images, audio or other material that we have permitted you to host, share, publish, post, store or upload on the Website.
                    <br />
                    <p>We may at any time, without liability to you, remove, alter or disable access to any or all your Contributions in our sole discretion without prior notice to you. We may also remove or disable access to any or all your Contributions if we consider that:</p>
                    <ol type="a" style={{ paddingLeft: "20px" }}>
                        <li>those Contributions are in breach of any law or regulation;</li>
                        <br /> <li>those Contributions infringe the intellectual property rights of any third party;</li>
                        <br /><li>we are required to do so by a regulatory body or any relevant authority pursuant to an interim or final take-down notice;</li>
                        <br /> <li>those Contributions are:
                            <ol type="i" style={{ paddingLeft: "30px" }}>
                                <br />  <li>misleading or deceptive;</li>
                                <br />  <li>inappropriate having regard to the purpose of the Website;</li>
                                <br /> <li>likely to cause offence;</li>
                                <br /> <li>materially incorrect;</li>
                                <br /> <li>obscene;</li>
                                <br /> <li>defamatory;</li>
                                <br /> <li>otherwise, unlawful and/or against the customs or norms of the region in which this Website is referred to;</li>
                                <br /> <li>corrupted, due to the presence of a virus or other disabling code.</li>
                            </ol>
                        </li>
                    </ol>


                    To the extent that any Contributions are proprietary in nature, you grant us a worldwide, non- exclusive, royalty-free, perpetual, transferable and irrevocable license to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display and publicly perform your Contributions throughout the world in any medium, whether currently in existence or not.
                    <br /><br />
                    <p>You also grant each user of the Website the right to use your name or the name you submit with the Contribution, and the right to represent and warrant that:</p>
                    <ol type="a" style={{ paddingLeft: "20px" }}>
                        <li>you own and control all the rights to the Contributions; or</li>
                        <br /> <li>You have the lawful right including all necessary licenses, rights, consents and permissions to use and authorize us to display the Contributions.</li>
                    </ol>
                    <p>For any Contributions that you may retain moral rights in, you declare that:</p>
                    <ol type="a" style={{ paddingLeft: "20px" }}>
                        <li>you do not require that any personally identifying information be used in connection with the Contribution, or any derivative work, upgrade or update of the Contribution; and</li>
                        <br /> <li>You understand that when accessing the Website, you may be exposed to the Contributions of other users of the Website. You acknowledge and agree that we do not have control of and are not responsible nor do we warrant the veracity of these other Contributions.</li>
                    </ol>
                    <p>You represent and warrant that:</p>
                    <ol type="a" style={{ paddingLeft: "20px" }}>
                        <li>you have the lawful right including all necessary licenses, rights, consents and permissions to use and authorize us to display your Contributions;</li>
                        <br />  <li>you will not make any Contributions that infringe the intellectual property rights of any third party, and you agree to pay all royalties, fees or other monies payable by reason of any Contributions made by you; and</li>
                        <br />  <li>you will not make any Contributions that are:
                            <ol type="i" style={{ paddingLeft: "30px" }}>
                                <br />    <li>misleading;</li>
                                <br />   <li>deceptive;</li>
                                <br />  <li>materially incorrect;</li>
                                <br />  <li>likely to cause offence;</li>
                                <br />  <li>directly or indirectly involve the advertising or marketing of any products or services;</li>
                                <br />  <li>obscene, including pornographic, hateful, racially or ethnically offensive material;</li>
                                <br />  <li>defamatory;</li>
                                <br />  <li>otherwise, unlawful or encourage unlawful conduct; or</li>
                                <br />  <li>otherwise, inappropriate having regard to the purpose of our website.</li>
                            </ol>
                        </li>
                    </ol>

                    <br /><br />
                    <strong>15. Communicating with you </strong>
                    <br /><br />
                    When you use the Website or send emails to us, you are communicating with us electronically. You hereby consent to receive electronically any communications related to your use of the Website. We will communicate with you by email or other communication means, such as WhatsApp, or by posting notices on the Website. You acknowledge and agree that all agreements, notices, disclosures and other communications that are provided to you electronically satisfy any legal requirement that such communications be in writing. All notices from us intended for receipt by you shall be deemed delivered and effective when sent to the email address or the mobile number you provide on the Website for your account.
                    <br /><br />
                    <strong>16. Indemnity </strong>
                    <br /><br />
                    You agree to indemnify and hold us and our affiliates (and our officers, agents, partners and employees) against any and all loss, liability, claim or demand, including reasonable attorney’s fees, arising out of, or in connection with your use of and access to the Website or making Contributions not in accordance with the Terms.
                    <br /><br />
                    <strong>17. Disclaimer </strong>
                    <br /><br />

                    The materials on the Website are provided on an "as is" and “as available” basis and we make no warranties, expressed or implied, and hereby disclaim and negate all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, we do not warrant or make any representation concerning the accuracy, likely results, or reliability of the use of the materials on the Website or otherwise relating to such materials or on any site linked to the Website.
                    <br /><br />
                    <strong>18. We may monitor and record telephone calls</strong>
                    <br /><br />

                    Real estate brokers and developer clients who have subscribed to our call tracking service have elected to have their call enquiries generated through the Website. Such calls may be tracked and recorded for training and customer service assessment purposes. You consent in advance to any such recording. We will remind you of our recording before each phone conversation.
                    <br /><br />
                    <strong>19. Governing law and jurisdiction</strong>
                    <br /><br />

                    These Terms and any non-contractual obligations arising in connection with these Terms and your access to the Website shall be governed by and construed in accordance with the laws of the Dubai International Financial Centre (DIFC) in the Emirate of Dubai, United Arab Emirates. You agree to submit to the exclusive jurisdiction of the Courts of the Dubai International Financial Centre (DIFC).
                    <br /><br />
                    <strong>20. General terms</strong>
                    <br /><br />

                    If any term of these Terms is or may become for any reason invalid or unenforceable at law, the validity and enforceability of the remainder will not be affected.

                    These Terms and the documents referred to or incorporated in them constitute the entire agreement between us and replace all previous agreements and understandings between them, relating to their subject matter.
                    <br /><br />
                    <strong>
                        21. Contact us
                    </strong>
                    <br /><br />
                    Our subscribers are licensed real estate brokers, developers and hotel apartment providers. Our advertisers are contractually obligated to only list properties that are available for sale or lease with the proper authority from the owner and all other required governing bodies. Properties listed on the Website should be a fair and accurate portrayal of the property itself and the proposed transaction. To report any suspected fraudulent or misleading property postings on our site please send us an email with the details to <a style={{ color: " #01BAFF" }} href="mailto:support@Housez.ae ">support@Housez.ae </a>
                    <br /><br />
                    If you have any queries, complaints or recommendations about these Terms of Use, please contact us at the following address:<a href="mailto:support@Housez.ae " style={{ color: " #01BAFF" }}>support@Housez.ae </a> <br /><br /><br />
                </>
            ) : (

                <>
                    <p><strong>شروط الاستخدام</strong></p>

                    <strong>1.</strong><strong>نبذة عنا وكيفية التواصل معنا  </strong>

                    <p><a style={{ color: "#01BAFF" }} href="http://www.housez.ae">www.housez.ae</a>&nbsp;هو موقع إلكتروني ("الموقع الإلكتروني") تملكه وتديره وتشغله شركة "housez منطقة حرة ذ.م.م" والشركات التابعة لها في جميع أنحاء العالم (يُشار إليها فيما يلي باسم "نحن" و"لنا" و"الخاصة بنا" و"شركة housez</p>
                    <p>للتواصل معنا، يرجى إرسال رسالة إلكترونية إلى&nbsp;<a href="mailto:info@housez.ae" style={{ color: "#01BAFF" }}>info@housez.ae</a></p>
                   <strong>2.</strong>
                   <strong>باستخدام الموقع الإلكتروني، يوافق المستخدم على الشروط التالية</strong><strong>:</strong>
                 
                    <p>تشكل هذه الشروط الخاصة باستخدام الموقع الإلكتروني (يُشار إليها فيما يلي باسم "الشروط") سندًا قانونيًا مُلزِمًا بين الشركة وبين المستخدم. وباستخدام الموقع الإلكتروني، يؤكد المستخدم قبوله هذه الشروط وموافقته على الالتزام بها بالكامل. وفي حال عدم قبول المستخدم هذه الشروط وعدم الموافقة على الالتزام بها، يجب عليه التوقف عن استخدام الموقع الإلكتروني.</p>
                    <p>لا يجوز للمستخدم استخدام الموقع الإلكتروني ولا يجوز له قبول هذه الشروط إذا: (أ) لم يتجاوز عمره ثمانية عشر (18) عامًا، أو (ب) كان ممنوعًا أو محظورًا قانونيًا من تلقي أو استخدام الموقع الإلكتروني بموجب قوانين الدولة التي يقيم فيها أو الذي يصل منه إلى الموقع الإلكتروني أو يستخدمه.</p>
                    <p>يتحمل المستخدم المسؤولية عن التأكد من أن جميع مستخدمي الموقع الإلكتروني عبر شبكة المستخدم على دراية بهذه الشروط والأحكام والشروط الأخرى المعمول بها وأنهم ملتزمون بها.</p>
                    
                    <strong>3.</strong>
                   
                     <strong>شروط أخرى قد تنطبق على المستخدم</strong>
                
                    <p>تتضمن هذه الشروط شروطًا أخرى تنطبق أيضًا على استخدام المستخدم للموقع، وهي: سياسة الخصوصية لدى شركتنا؛ وفي حال قام المستخدم بتنزيل تطبيق الهاتف المحمول لدينا، ستنطبق شروط استخدام تطبيق housez للجوال.</p>
                   
                    <strong>4.</strong>
                   
             <strong>كيفية جميع البيانات والمعلومات الشخصية واستخدامها</strong>
              
                    <p>يخضع استخدامنا لبياناتك ومعلوماتك الشخصية لسياسة الخصوصية لدينا.</p>
                    
                    <strong>5.</strong>
                  
                      <strong>كيفية استخدامك للمواد المتاحة على الموقع الإلكتروني</strong>
                
                    <p>نحن المالك الوحيد أو المرخص له بجميع حقوق الملكية الفكرية في الموقع الإلكتروني وفي المواد المنشورة عليه. وهذه المصنفات محمية بقوانين حقوق التأليف والنشر والاتفاقيات المعمول بها في جميع أنحاء العالم. وجميع الحقوق محفوظة.</p>
                    <p>نحتفظ نحن، والجهات المتعاملة معنا كافة من موردين وأصحاب تراخيص، بجميع حقوق الملكية الفكرية في جميع النصوص والبرامج والمنتجات والعمليات والتقنيات والمحتويات والمواد الأخرى التي تظهر على الموقع الإلكتروني أو التي يجري توفيرها عبر خدماتنا. ولا يؤدي الدخول إلى الموقع الإلكتروني أو استخدام خدماتنا إلى منح أي ترخيص بموجب أي من حقوق الملكية الفكرية لدينا أو لدى الغير، ولا يعتبر أنه يؤدي إلى ذلك أيضًا. ويحظر استخدام الموقع الإلكتروني أو محتوياته أو خدماتنا، سواء من خلال نسخها أو تخزينها كليًا أو جزئيًا - بخلاف الاستخدام الشخصي غير التجاري - دون إذن كتابي من الشركة. ولا يجوز للمستخدم تعديل أو توزيع أو إعادة نشر أي شيء على الموقع الإلكتروني لأي غرض أو سبب.</p>
                    <p>إن أسماؤنا وشعاراتنا وجميع أسماء المنتجات والخدمات ذات الصلة بها وعلامات التصميم والشارات هي علامات تجارية أو علامات خدمة خاصة بنا أو بمانحي التراخيص المتعاملين معنا. ولا يُمنح ترخيص أي علامة تجارية أو علامة خدمة فيما يتعلق بالمواد الموجودة على الموقع الإلكتروني.</p>
                    <p>يدرك المستخدم ويوافق على أن الدخول إلى الموقع الإلكتروني أو خدماتنا لا يصرح لأي شخص باستخدام أي اسم أو شعار أو علامة بأي طريقة أو وسيلة.</p>
                    <p>يجوز للمستخدم مؤقتًا، لأغراض الاطلاع الشخصي غير التجاري المؤقت فقط، تنزيل نسخ من المواد (المعلومات أو البرامج) على الموقع الإلكتروني، ويجوز له جذب انتباه الآخرين داخل مؤسسته إلى المحتوى المنشور على الموقع الإلكتروني.</p>
                    <p>لا يجوز للمستخدم تعديل النسخ الورقية أو الإلكترونية لأي مواد أو معلومات قام المستخدم بطباعتها أو تنزيلها بأي شكل من الأشكال، ولا يجوز للمستخدم استخدام أي رسوم توضيحية أو صور فوتوغرافية أو مقاطع فيديو أو تسلسلات صوتية أو أي رسومات منفصلة عن أي نص مرفق بها.</p>
                    <p>يجب الاعتراف بصفتنا (وبصفة أي مساهمين محددين) مؤلفين للمحتوى المنشور على الموقع الإلكتروني.</p>
                    <p>لا يجوز للمستخدم استخدام أي جزء من المحتوى المتاح على الموقع لأغراض تجارية دون الحصول على ترخيص للقيام بذلك منا أو من المرخصين لدينا.</p>
                    <p>إذا قام المستخدم بطباعة أو نسخ أو تنزيل أي جزء من الموقع الإلكتروني بما يخالف هذه الشروط، يسقط حق المستخدم في استخدام الموقع الإلكتروني على الفور، ومن ثم يتعين على المستخدم، حسب خيارنا، إرجاع أو إتلاف أي نسخ من المواد التي بحوزته سواء في شكل إلكتروني أو ورقي.</p>
                 
                    <strong>6.</strong>
           
                  <strong>يجب أن تظل تفاصيل حساب المستخدم آمنة</strong>
              
                    <p>إذا اختار المستخدم أو حصل على معرف مستخدم أو كلمة مرور أو أي جزء آخر من المعلومات كجزء من إجراءاتنا الأمنية، يتعين عليه التعامل مع هذه المعلومات على أنها سرية والامتناع عن الكشف عنها للغير.</p>
                    <p>نحتفظ بالحق في أي وقت في تعطيل أي معرّف للمستخدم أو رمز أو كلمة مرور، سواء كانت من اختيار المستخدم أو أرسلناها إليه، إذا رأينا بصورة معقولة أن المستخدم قد أخفق في الامتثال لأي من هذه الشروط.</p>
                    <p>إذا كان المستخدم يعلم أو يشك بأن أي شخص آخر غيره يعرف رقم معرّف المستخدم أو الرمز أو كلمة المرور الخاصة بالمستخدم، يجب على المستخدم إخطارنا على&nbsp;<a href="mailto:support@housez.ae">support@housez.ae</a></p>
                   
                    <strong>7.</strong>
               <strong>مسؤوليات المستخدم عند استخدام الموقع الإلكتروني</strong>
                    <p>يدرك المستخدم ويوافق على امتناعه عن تنفيذ أو القيام بأي إجراء أو المشاركة في أي سلوك غير لائق أو غير قانوني أو محظور بموجب أي قوانين تنطبق على الموقع الإلكتروني، وتشمل على سبيل المثال لا الحصر:</p>
                    <ol type="a">
                        <li>استخدام أي جهاز آلي أو عملية أو برنامج أو وسيلة للدخول إلى الموقع الإلكتروني أو استرداده أو نسخه أو فهرسته أو أي محتوى على الموقع الإلكتروني.</li>
                        <li>استخدام أي جهاز أو برنامج أو عملية أو وسيلة للتدخل أو محاولة التدخل في التشغيل الصحيح للموقع الإلكتروني.</li>
                        <li>تنفيذ أي عمل من شأنه أن يوقع أي عبء أو يتسبب في زيادة طلبات حركة البيانات على بنيتنا التحتية على النحو الذي نراه استخداماً غير معقول أو غير مناسب للموقع.</li>
                        <li>محاولة فك شفرة أو إلغاء التحويل البرمجي أو تفكيك أو إلغاء تجميع أو إجراء هندسة عكسية لأي من البرامج التي يتألف منها أي جزء من الموقع الإلكتروني أو تضمه بأي شكل من الأشكال.</li>
                        <li>استخدام أو فهرسة أي محتوى أو بيانات على الموقع الإلكتروني لأي من الأغراض التالية:</li>
                        <ol>
                            <li>بناء أو ملء قاعدة بيانات قابلة للبحث؛ أو</li>
                            <li>بناء قاعدة بيانات للمعلومات المتعلقة بالعقارات؛ أو</li>
                            <li>التنافس معنا بأي طريقة ونحن لم نأذن بذلك على وجه التحديد.</li>
                        </ol>
                        <li>إرسال رسائل غير مرغوب بها أو رسائل متسلسلة أو محتوى أو رسائل عشوائية أو استطلاعات آراء أو رسائل جماعية أخرى، سواء أكانت ذات طابع تجاري أم لا.</li>
                        <li>استخدام الموقع الإلكتروني أو أي محتوى في الموقع الإلكتروني على أي نحو قد نرى في تقديرنا المطلق أنه غير معقول و/أو لا يخدم الغرض من إنشاء الموقع الإلكتروني.</li>
                        <li>التعدي على حقوق الغير، بما فيها حقوق التأليف والنشر والأسرار التجارية والحق في الخصوصية أو أي ملكية فكرية أو حقوق ملكية أخرى.</li>
                        <li>انتحال صفة أي شخص أو كيان أو محاولة الحصول على مال أو كلمات مرور أو معلومات شخصية من أي شخص.</li>
                        <li>التصرف بطريقة تخالف أي من الشروط أو الأحكام الأخرى التي نفرضها أو المفروضة بموجب أي قانون معمول به.</li>
                        <li>نسخ الموقع الإلكتروني أو أي محتوى عليه أو إعادة نشره أو إعادة إرساله أو تعديله أو استخدامه أو توزيعه أو ترجمته أو اشتقاق أي أعمال منه أو تحويره أو عرضه للجمهور أو بيعه أو المتاجرة به أو استغلاله بأي شكل آخر، باستثناء ما نأذن به صراحةً.</li>
                        <li>إرسال أو محاولة إرسال أي فيروسات حاسوبية أو فيروسات متنقلة (ديدان) أو عيوب أو أي مواد تدميرية أخرى.</li>
                        <li>اكتساب أو محاولة اكتساب دخول غير مصرح به إلى الموقع الإلكتروني أو الخادم المخزن عليه الموقع الإلكتروني أو أي خادم أو حاسوب أو قاعدة بيانات متصلة بالموقع الإلكتروني.</li>
                        <li>مهاجمة الموقع الإلكتروني أو محاولة مهاجمته من خلال هجوم لقطع الخدمة أو هجوم موزع لقطع الخدمة.</li>
                    </ol>
                    <p>عند الضرورة، قد نضع تدابير معينة لحجب الدخول غير المصرح به واستخدام الموقع الإلكتروني، من ذلك على سبيل المثال لا الحصر، إنشاء حواجز تنظيمية أو تقنية أو الإبلاغ عن السلوك غير المصرح به أو غير القانوني أو غير اللائق إلى أي شخص أو كيان.</p>
                  
                    <strong>8.</strong>
                
               <strong>تفاعلك مع وكلاء العقارات من خلال الموقع الإلكتروني</strong>
                 
                    <p>لا نزاول نشاطنا على أننا وكالة عقارية. بل نقدم خدمة يمكن للوكلاء من خلالها التسويق للعقارات ويمكن للمستخدم عرض ومشاهدة بياناتها ("التفاصيل")، وذلك إضافة إلى أي محتويات أخرى تستضيفها شركتنا وتطورها. يتحمل الوكلاء والأطراف الثالثة مسؤولية إعداد التفاصيل والرد على الاستفسارات الموجهة من المستخدم مباشرةً. بخلاف تسهيل التعارف بين المستخدم وبين الوكيل المعني عند استفسار المستخدم عن أحد العقارات، فإننا لا نشارك في أي اتصالات أخرى بين المستخدم وبين الوكلاء، ولا نشارك في أي جزء من المعاملة.</p>
                    <p>نحن نحتفظ بالتفاصيل بحسن نية، إلا أن هذه التفاصيل تُستمد مباشرة من الوكلاء أو الغير، لذلك لا يمكننا التحقق منها أو المصادقة عليها. ويتحمل المستخدم مسؤولية طرح استفساراته وأسئلته، ونحن لا نبدي أي ضمان ولا نتحمل أية مسؤولية عن دقة أو اكتمال أي من المعلومات الواردة في التفاصيل.</p>
                    <p>يتحمل المستخدم مسؤولية ما يلي:</p>
                    <ol>
                        <li>التحقق من التفاصيل وتأكيدها والاطمئنان إلى دقتها وصحتها.</li>
                        <li>الاستعانة بمساحين والحصول على المشورة القانونية قبل الالتزام بأي عملية شراء.</li>
                        <li>التصرف بحسن نية تجاه الغير.</li>
                    </ol>
                    <p>يقر المستخدم ويتعهد بأن استخدامه للموقع سوف يمتثل في جميع الأوقات لهذه الشروط الخاصة بالاستخدام وأي شروط أخرى قد تنطبق عليه فيما يتعلق باستخدامه للموقع، بما في ذلك جميع التعديلات والمراجعات على هذه الشروط وفقًا للبند 13 هنا.</p>
                  
                    <strong>9.</strong>
                <strong>مسؤوليتنا عن الخسارة أو الضرر الذي يلحق بالمستخدم</strong>
                    <p>إلى الحد الذي يسمح به القانون، لا نتحمل أي مسؤولية تجاه المستخدم عن أي خسارة أو ضرر، سواءً بموجب أي عقد أو المسؤولية التقصيرية (بما في ذلك الإهمال) أو انتهاك الواجب القانوني أو غير ذلك، حتى لو كان متوقعًا، والذي ينشأ عن الاستخدام أو عدم القدرة على استخدم الموقع الإلكتروني أو استخدام أو الاعتماد على أي محتوى معروض على الموقع الإلكتروني.</p>
                    <p>لا نتحمل المسؤولية عن أي خسارة أو ضرر ينشأ بموجب أو فيما يتعلق بما يلي:</p>
                    <ol type="a">
                        <li>أي عطل بسبب أي أخطاء برمجية أو أي خطأ في الإنترنت أو عدم التوفر أو أي أسباب أخرى خارجة عن إرادتنا المعقولة.</li>
                        <li>فقدان كلمة المرور أو الحساب إذا كان بسبب عطل أو انقطاع الكهرباء أو خطأ ما أو بخلاف ذلك ما ينشأ نتيجة نظام الحاسوب أو حساب المستخدم أو يلحق بهما أي بأي منهما.</li>
                        <li>استخدام الموقع الإلكتروني أو عدم القدرة على استخدامه.</li>
                        <li>الاعتماد على أي محتوى أو معلومات معروضة على الموقع الإلكتروني.</li>
                        <li>أي خسارة مباشرة أو تبعية أو خاصة أو جزائية، أو ضرر أو تكاليف أو نفقات.</li>
                        <li>الكسب الفائت.</li>
                        <li>خسارة الفرص التجارية.</li>
                        <li>خسارة السمعة التجارية.</li>
                        <li>خسارة الشهرة التجارية.</li>
                        <li>فقدان البيانات أو تلفها أو فسادها.</li>
                    </ol>
                    <p>ما لم نتفق صراحةً على خلاف ذلك كتابةً، يوافق المستخدم على عدم استخدام الموقع لأي أغراض تجارية.</p>
                    <p>نحن لا نضمن أن يكون الموقع الإلكتروني آمنًا أو خاليًا من الأخطاء أو العيوب البرمجية أو الفيروسات الحاسوبية. نحن لسنا مسؤولين أمام المستخدم أو الغير عن أي خسارة أو ضرر ناتج عن فيروس حاسوبي أو هجوم موزع لقطع الخدمة أو أي مواد أو برامج ضارة أخرى قد تصيب أجهزة الحاسب الآلي أو برامج الحاسب الآلي أو البيانات أو المواد الأخرى الخاصة بالمستخدم نتيجة استخدام الموقع الإلكتروني أو تنزيل أي محتوى عليه أو أي موقع الكتروني مرتبط به. ويتحمل المستخدم المسؤولية عن تكوين تقنية المعلومات وبرامج الحاسب الآلي والمنصة للدخول إلى الموقع الإلكتروني. ويجب على المستخدم استخدام أي من برامج الحماية من الفيروسات.</p>
                    <p>إذا كان المستخدم يستفسر عن عقار ما على الموقع الإلكتروني، فإن المستخدم يقر ويوافق على أنه سيتم إرسال تفاصيل المستخدم عبر البريد الإلكتروني أو وسائل الاتصال الأخرى، مثل "واتس أب"، مباشرة إلى الوكيل أو الوكيل العقاري أو المالك أو المطوّر الذي يسوّق للعقار أو العقارات التي يستفسر المستخدم عنها. ونحن لا نتحمل أي مسؤولية عن أي مراسلات لاحقة يتلقاها المستخدم مباشرة من الوكيل العقاري أو المالك أو المطوّر أو الغير.</p>
                   
                    <strong>10.</strong>
            
                   <strong>محتوى الموقع الإلكتروني</strong>
                
                    <p>يمكن أن تتضمن المواد التي تظهر على الموقع الإلكتروني أخطاء فنية أو مطبعية أو فوتوغرافية. ونحن لا نُبدي أي ضمانات بأن أيًا من المواد الموجودة على الموقع الإلكتروني دقيقة أو كاملة أو حديثة. وقد نقوم بإجراء تغييرات على المواد الواردة أو المعروضة على الموقع الإلكتروني في أي وقت دون إشعار مسبق.</p>
                 
                    <strong>11.</strong>
                 
              <strong>إتاحة الموقع الإلكتروني للاستخدام</strong>
              
                    <p>نسعى جاهدين لضمان أن الموقع الإلكتروني متاح للاستخدام دائمًا، إلا أننا لا نضمن أن الموقع الإلكتروني سيعمل بصورة مستمرة أو دون انقطاع أو خلوه من الأخطاء أو العيوب البرمجية. ففي بعض الأحيان، هناك بعض أعمال الصيانة أو الترقيات الضرورية التي تقتضي إيقاف الموقع الإلكتروني والخدمات دون إشعار مسبق، ولكننا نسعى دائمًا إلى تقليل وقت التوقف إلى أدنى حد ممكن. نحن لا نتحمل أي مسؤولية عن أي انقطاع أو فقدان في الخدمة. نحتفظ بالحق في تغيير أو تعليق أو إيقاف أي جزء من الموقع الإلكتروني أو الخدمات، بما في ذلك الدخول إلى أي منها.</p>
                    <strong>12.</strong>
                    <strong>الروابط الإلكترونية الأخرى الموجودة في موقعنا الإلكتروني</strong>
                   
                    <p>قد يحتوي موقعنا على روابط وارتباطات تشعبية وإشارات إلى منتجات وخدمات خارجية و/أو المواقع غير التابعة لنا وهي مُتاحة لغرض الاستخدام كمرجع فقط. وليس لدينا أي سيطرة على منتجات أو خدمات أو مواقع هذه الأطراف ولا نضمنها أو نتحمل المسؤولية عنها. وقد يحتوي موقعنا أيضاً إعلانات من أطراف خارجية ونحن غير مسؤولين، ولا نقدم أي ضمانات أو إقرارات لأية إعلانات مضللة أو غير دقيقة وهي تحت المسؤولة الحصرية للمعلِن.</p>
                    <p>لا يجوز اعتبار أي روابط أو إعلانات على موقعنا بمثابة موافقة منا على هذه المواقع أو المعلومات المرتبطة التي قد يحصل عليها المستخدم منا . علاوة على ذلك، يحتوي موقعنا الإلكتروني على بيانات مقدمة من أطراف خارجية ولا نتحمل أي مسؤولية، ولا نقدم أي ضمانات أو إقرارات حيال أي أخطاء في هذه المواد. ويقر المستخدم ويتعهد بإخلاء طرفنا من أي مطالبات أو منازعات من أي نوع تنشأ من أو بأي طريقة متعلقة بهذه المنازعات مع أطراف خارجية.</p>
                    <p>باستخدام الموقع، يوافق المستخدم على منحنا رخصة عالمية، لا رجعة فيها، وبلا أي إتاوات، لتسويق ونسخ والترخيص للأشخاص الآخرين، واستخدام أي مواد تستخرجونها أو تقدمونها لاستخدام الموقع. ولا نضمن إتاحة المحتوى والروابط، أو المجالات الفرعية المضمنة هنا، أو المرتبطة بموقعنا في جميع الأوقات. ولا يجوز اعتبار المعلومات حول منشوراتنا، والمطبوعات والإدراجات والمحتوى بمثابة بديل عن المشورة المهنية القانونية والمالية أو العقارية.</p>
                   
                    <strong>13.</strong>
                
               <strong>التغييرات على هذه الشروط</strong>
             
                    <p>يجوز لنا تعديل هذه الشروط من حين لآخر دون إشعار. وفي كل مرة يرغب فيها المستخدم في استخدام التطبيق، يرجى الاطلاع على هذه الاتفاقية للتأكد من فهمه للشروط النافذة في ذلك الوقت. بالإضافة إلى ذلك، يجوز لنا أيضًا تحديث التطبيق وتعديله من وقت لآخر دون إشعار.</p>
                    
                    <strong>14.</strong>
                 
 <strong>مساهمات المستخدم في الموقع الإلكتروني لشركتنا</strong>
        
                    <p>يُقصد بلفظ "المساهمات" في هذه الشروط أي معلومات بما في ذلك البيانات والنصوص وأشرطة الفيديو ولقطات التصوير أو غيرها من المواد التي قد سمحنا للمستخدم باستضافتها ومشاركتها ونشرها وعرضها أو تخزينها أو تحميلها على موقعنا على الإنترنت.</p>
                    <p>يجوز لشركتنا في أي وقت، دون تحمل أي مسؤولية تجاه المستخدم، إزالة أو تغيير أو تعطيل الوصول إلى أي جزء أو كل مساهمات المستخدم وفق تقديرنا دون إرسال إشعار مسبق للمستخدم. ويجوز لنا أن نزيل أو نعطل الوصول إلى أي من أو كافة مساهمات المستخدم إذا رأينا ما يلي:</p>
                    <ol type="a">
                        <li>أن تكون تلك المساهمات بمثابة خرقا لأي قانون أو لائحة،</li>
                        <li>أن تنتهك تلك المساهمات حقوق الملكية الفكرية لأي طرف ثالث،</li>
                        <li>إذا كان ذلك مطلوباً من هيئة تنظيمية أو أية سلطة ذات صلة، وبموجب إشعار مؤقت أو نهائي</li>
                        <li>أن تتصف هذه المساهمات بأي مما يلي:</li>
                        <ol type="i">
                            <li>مضللة أو خادعة،</li>
                            <li>غير مناسبة بالنظر إلى الغرض من موقعنا على الإنترنت،</li>
                            <li>يحتمل أن تسبب جريمة،</li>
                            <li>غير صحيحة بالأساس؛</li>
                            <li>فاحشة؛</li>
                            <li>تشهيرية؛</li>
                            <li>غير قانونية و/أو تخالف الأعراف أو العادات السائدة في المنطقة التي يشير إليها الموقع أيضاً؛ أو،</li>
                            <li>تالفة، نظراً لوجود فيروس أو تعليمات برمجية تعطيلية أخرى.</li>
                        </ol>
                    </ol>
                    <p>وبالقدر الذي تكون فيه أي مساهمات خاضعة لحقوق ملكية بطبيعتها، فإن المستخدم يمنح الشركة رخصة عالمية غير حصرية وأبدية وبلا إتاوة وقابلة للتحويل ولا رجعة فيها لاستخدام ونسخ وتعديل وتحوير وترجمة وتوزيع ونشر وإنشاء أعمال مشتقة من وعرض وأداء مساهامات المستخدم علناً في جميع أنحاء العالم وبأي وسيلة، سواء أكانت موجودة أم لا.</p>
                    <p>يقر المستخدم بمنح أي مستخدم آخر لموقعنا الحق في استخدام اسم المستخدم أو الاسم الذي يقدمه المستخدم مع، والحق في الإقرار وضمان ما يلي:</p>
                    <ol type="a">
                        <li>بأن المستخدم يملك ويسيطر على جميع حقوق المساهمات، أو</li>
                        <li>بأن المستخدم لديه حق قانوني بما في ذلك جميع التراخيص اللازمة والحقوق والموافقات والأذونات لاستخدام والسماح لنا بعرض المساهمات.</li>
                    </ol>
                    <p>فيما يتعلق بأي مساهمات يحتفظ فيها المستخدم بحقوق معنوية، فإن المستخدم يقر بما يلي:</p>
                    <ol type="a">
                        <li>عدم الحاجة إلى استخدام أية معلومات تعريفية شخصية فيما يتعلق بالمساهمة، أو أي أعمال مشتقة من ذلك أو ترقية أو تحديث للمساهمة،</li>
                        <li>يدرك المستخدم أنه عند وصوله إلى موقعنا الإلكتروني قد، يتم عرض مساهمات المستخدمين الآخرين لموقعنا عليه. ويقر ويوافق على أننا لا نملك السيطرة ولسنا مسؤولين ولا نضمن صحة هذه المساهمات الأخرى</li>
                    </ol>
                    <p>يقر المستخدم ويتعهد بما بالآتي:</p>
                    <ol type="a">
                        <li>بأنه يملك الحق القانوني بما في ذلك جميع التراخيص اللازمة والحقوق، والموافقات والأذونات لاستخدام والسماح لنا بعرض المساهمات.</li>
                        <li>بأنه لن يقدم أي مساهمات تتعدى على حقوق الملكية الفكرية لأي أطراف أخرى، ويوافق على دفع جميع الإتاوات أو الرسوم أو الأموال الأخرى المستحقة بسبب أي مساهمات قدمها المستخدم؛</li>
                        <li>بأنه لن يقدم أي مساهمات من شأنها:</li>
                        <ol type="i">
                            <li>أن تكون مضللة،</li>
                            <li>أن تكون خادعة،</li>
                            <li>غير صحيحة بالأساس،</li>
                            <li>يحتمل أن تسبب جريمة،</li>
                            <li>تنطوي بطريقة مباشرة أو غير مباشرة على دعاية أو تسويق لأي منتجات أو خدمات،</li>
                            <li>فاحشة، بما في ذلك المواد الإباحية، والتي تحض على الكراهية، أو تحتوى على إساءات عرقية أو إثنية،</li>
                            <li>تشهيرية،</li>
                            <li>غير مشروعة أو تشجع على السلوك غير المشروع، أو</li>
                            <li>غير مناسبة بالنظر إلى الغرض من موقعنا على الإنترنت.</li>
                        </ol>
                    </ol>
                  
                    <strong>15.</strong>
               
              <strong>التواصل مع المستخدم</strong>
            
                    <p>عند استخدام الموقع الإلكتروني أو إرسال رسائل بريد إلكتروني إلينا، فإن هذا التواصل معنا يكون إلكترونيًا. ويوافق المستخدم بموجبه على تلقي أي اتصالات إلكترونية تتعلق باستخدامه الموقع الإلكتروني. وسوف نتواصل معك عن طريق البريد الإلكتروني أو وسائل التواصل الأخرى، مثل "واتس أب"، أو عن طريق نشر الإشعارات على الموقع الإلكتروني. ويقر المستخدم ويوافق على أن جميع الاتفاقيات والإشعارات وبيانات إخلاء المسؤولية وغيرها من البيانات والمراسلات الموجهة إلى المستخدم إلكترونيًا تفي بأي شرط قانوني ينص على أن تكون هذه المراسلات كتابية. وتُعتبر جميع الإشعارات الموجهة إلى المستخدم سارية ونافذة عند إرسالها إلى عنوان البريد الإلكتروني أو رقم الهاتف المتحرك الذي يوفره المستخدم على الموقع الإلكتروني أو تطبيق الهاتف المتحرك الخاص بحسابك.</p>
                  
                    <strong>16.</strong>
       
                <strong>التعويض</strong>
                  
                    <p>يوافق المستخدم على تعويض الشركة وشركاتها التابعة، وكذلك مسؤولينا ووكلائنا وشركائنا وموظفينا، ودرء الضرر عنهم من أي وجميع الخسائر أو المسؤوليات أو المطالبات أو الطلبات، بما في ذلك أتعاب المحاماة المعقولة، والتي تنشأ عن أو فيما يتعلق باستخدام الموقع الإلكتروني أو تقديم مساهمات لا تستوفي الشروط.</p>
                  
                  
                    <strong>17.</strong>
           
             <strong>إخلاء المسؤولية</strong>
              
                    <p>تُقدم المواد المُتاحة على الموقع الإلكتروني لشركتنا "كما هي" و"حسب توفرها" ولا نقدم أي ضمانات، صريحة أو ضمنية، وننكر بموجبه وننفي جميع الضمانات الأخرى، بما في ذلك على سبيل المثال لا الحصر، الضمانات الضمنية أو شروط القابلية للتسويق والملاءمة لغرض معين، أو عدم التعدي على الملكية الفكرية أو غيرها من أوجه انتهاك الحقوق. إضافة إلى ذلك، نحن لا نقدم أي ضمان أو إقرار حيال دقة المواد المتاحة على الموقع والنتائج المحتملة لها وموثوقية استخدامها أو أي شيء يتعلق بهذه المواد أو على أي موقع مرتبط بهذا بالموقع الإلكتروني لشركتنا.</p>
                  
                  
                    <strong>18.</strong>

<strong>مراقبة المكالمات الهاتفية وتسجيلها</strong>
                  
                    <p>لقد اختار عملاؤنا من وسطاء العقارات والمطورين الذين اشتركوا في خدمتنا الخاصة بتتبع المكالمات الهاتفية أن يتم الرد على أي استفسارات موجهة إليهم من العملاء عن طريق الموقع الإلكتروني لشركتنا. لذا يجوز تتبع مثل هذه المكالمات وتسجيلها لأغراض تقويم خدمة العملاء والتدريب. ويوافق المستخدم مقدماً على هذا التسجيل. وسنذكر المستخدم دائمًا بتسجيل المكالمات قبل كل مكالمة.</p>
                   
                    <strong>19.</strong>
       
              <strong>القانون الحاكم</strong>
              
                    <p>تخضع هذه الشروط وأي التزامات غير تعاقدية تنشأ فيما يتعلق بها وبدخول المستخدم إلى الموقع الإلكتروني وتُفسر وفقًا لقوانين مركز دبي المالي العالمي، إمارة دبي، الإمارات العربية المتحدة. ويوافق المستخدم على الخضوع إلى الاختصاص القضائي الحصري لمحاكم مركز دبي المالي العالمي.</p>
                  
                    <strong>20.</strong>
          
                     <strong>أحكام عامة</strong>
                    <p>إذا كان أو أصبح أي شرط من الشروط غير صالح أو ساري أو غير قابل للتطبيق وفقاً للقانون لأي سبب من الأسباب، فلن يؤثر ذلك في صحة ونفاذ الشروط الأخرى. These Terms and the documents referred to or incorporated in them constitute the entire agreement between us and replaces all previous agreements and understandings between them, relating to its subject matter.</p>
                    <p>تشكل هذه الشروط والمستندات المشار إليها أو المدرجة فيها الاتفاق الكامل بين الشركة والمستخدم وتحل محل جميع الاتفاقات والتفاهمات السابقة بينهما، وذلك فيما يتعلق بموضوعها.</p>
                   
                    <strong>21.</strong>
            <strong>اتصل بنا</strong>
                    <p>إن المشتركين في الموقع الإلكتروني لشركتنا هم موفرو خدمات وساطة عقارية ومطورو شقق فندقية مرخص لهم. والمعلنون لدينا ملزمون تعاقدياً بإدراج العقارات المتاحة للبيع أو الإيجار مع الحصول على التفويض المناسب من المالك وسائر الأجهزة الحكومية المطلوبة. ويجب أن تعكس العقارات المدرجة في الموقع الإلكتروني لشركتنا صورة نزيهة ودقيقة للعقار نفسه والصفقة المقترحة. وللإبلاغ أي عقارات معلن عنها على موقعنا يشتبه أن تكون احتيالية أو مضللة، يرجى موافاتنا بالتفاصيل على العنوان التالي&nbsp;<a href="mailto:support@housez.ae" style={{ color: "#01BAFF" }}>support@housez.ae</a></p>
                    <p>إذا كان لديكم أي استفسارات أو شكاوى أو ملاحظات بخصوص شروط الاستخدام هذه، تفضلوا بالتواصل معنا على العنوان التالي:&nbsp;<a href="mailto:support@housez.ae" style={{ color: "#01BAFF" }}>support@housez.ae</a></p>
                    <br /><br /><br />
                </>
            )

            }

        </>
    )
}