import "./recommendedSearches.scss";
import { useTranslation } from "react-i18next";

interface RecommendedSearchesProps {
    onSearchClick?: (bedroomNums: number) => void;
}

export default function RecommendedSearches({ onSearchClick }: RecommendedSearchesProps) {
    const { t } = useTranslation();
    const handleClick = (bedroomNums: number) => {
        if (onSearchClick) onSearchClick(bedroomNums);
    };

    return (
        <div className="recommended-searches">
            <span className="title">{t("RecommendeSearches")}</span>
            <div className="searches">
                <span onClick={() => handleClick(1)}>1 {t('property.BedroomPropertiesForRentInUAE')}</span>
                <span onClick={() => handleClick(2)}>2 {t('property.BedroomPropertiesForRentInUAE')}</span>
                <span onClick={() => handleClick(3)}>3 {t('property.BedroomPropertiesForRentInUAE')}</span>
                <span onClick={() => handleClick(4)}>4 {t('property.BedroomPropertiesForRentInUAE')}</span>
            </div>
        </div>
    )
}