import './sliderControls.scss';
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../context/LanguageContext"; 

interface SliderControlsProp {
    className?: string;
    handlePrev?: () => void;
    handleNext?: () => void;
    onViewAllClick?: () => void;
    isAtStart?: boolean;
    isAtEnd?: boolean;
}

export default function SliderControls({
    className,
    handlePrev,
    handleNext,
    onViewAllClick,
    isAtStart,
    isAtEnd
}: SliderControlsProp) {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const direction = language === 'ar' ? 'rtl' : 'ltr'; 

    return (
        <div className={`slider-controls ${className}`}>
            <button
                className="prev-btn"
                onClick={handlePrev}
                disabled={isAtStart} 
                style={isAtStart ? { opacity: 0.5,cursor:"unset" } : undefined}
            >
                <img
                    src={direction === 'rtl' ? "/images/home/arrow-right-5-svgrepo-com.svg" : "/images/home/arrow-left-5-svgrepo-com.svg"}
                    alt="Previous"
                />
            </button>
            <button className="view-btn" onClick={onViewAllClick}>
                {t('discover.ViewAllCities')}
            </button>
            <button
                className="next-btn"
                onClick={handleNext}
                disabled={isAtEnd} 
                style={isAtEnd ? { opacity: 0.5,cursor:"unset" } : undefined}
            >
                <img
                    src={direction === 'rtl' ? "/images/home/arrow-left-5-svgrepo-com.svg" : "/images/home/arrow-right-5-svgrepo-com.svg"}
                    alt="Next"
                />
            </button>
        </div>
    );
}
