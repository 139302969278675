import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { PropertyFilterParam } from "../../helpers/types/filter";

export const useFilterState = (initialFilterParams: PropertyFilterParam) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterParams, setFilterParams] = useState<PropertyFilterParam>(initialFilterParams);

    useEffect(() => {
        const bathroomNumsFromQuery = searchParams.get("bathroomNums")?.split(',') || [];
        const bedroomNumsFromQuery = searchParams.get("bedroomNums")?.split(',') || [];
        const rentOrSellFromQuery = searchParams.get("rentOrSell");
        const emiratesFromQuery = searchParams.get("emirateIDs")?.split(',') || [];
        const areasFromQuery = searchParams.get("areaIDs")?.split(',') || [];
        const typesFromQuery = searchParams.get("typeIDs");
        const featureIDsFromQuery = searchParams.get("featureIDs")?.split(',') || [];
        const usageIDsFromQuery = searchParams.get("usage")?.split(',') || [];
        const frequencyFromQuery = searchParams.get("frequency")?.split(',') || [];

        const maxPriceFromQuery = searchParams.get("maxPrice");
        const minPriceFromQuery = searchParams.get("minPrice");
        const maxSizeFromQuery = searchParams.get("maxSize");
        const minSizeFromQuery = searchParams.get("minSize");
        const pageFromQuery = searchParams.get("page");

        const placeIdFromQuery = searchParams.get("placeId");
        const placeNameFromQuery = searchParams.get("placeName");
        const latitudeFromQuery = searchParams.get("latitude");
        const longitudeFromQuery = searchParams.get("longitude");
        const premiseFromQuery = searchParams.get("premise");
        const streetNumberFromQuery = searchParams.get("streetNumber");
        const routeFromQuery = searchParams.get("route");
        const sublocalityLevel1FromQuery = searchParams.get("sublocalityLevel1");
        const localityFromQuery = searchParams.get("locality");
        const adminLavel1FromQuery = searchParams.get("adminLavel1");
        const countryFromQuery = searchParams.get("country");

        setFilterParams((prevParams) => ({
            ...prevParams,
            bedroomNums: bedroomNumsFromQuery ? bedroomNumsFromQuery.map(Number) : prevParams.bedroomNums,
            bathroomNums: bathroomNumsFromQuery ? bathroomNumsFromQuery.map(Number) : prevParams.bathroomNums,
            rentOrSell: rentOrSellFromQuery || prevParams.rentOrSell,
            emirateIDs: emiratesFromQuery.length ? emiratesFromQuery.map(Number) : prevParams.emirateIDs,
            areaIDs: areasFromQuery.length ? areasFromQuery.map(Number) : prevParams.areaIDs,
            typeIDs: typesFromQuery ? typesFromQuery.split(',').map(Number) : prevParams.typeIDs,

            usageIDs: usageIDsFromQuery.length ? usageIDsFromQuery.map(Number) : prevParams.usageIDs,
            frequency: frequencyFromQuery.length ? frequencyFromQuery.map(Number) : prevParams.frequency,
            maxPrice: maxPriceFromQuery ? Number(maxPriceFromQuery) : prevParams.maxPrice,
            minPrice: minPriceFromQuery ? Number(minPriceFromQuery) : prevParams.minPrice,
            maxSize: maxSizeFromQuery ? Number(maxSizeFromQuery) : prevParams.maxSize,
            minSize: minSizeFromQuery ? Number(minSizeFromQuery) : prevParams.minSize,
            featureIDs: featureIDsFromQuery.length ? featureIDsFromQuery.map(Number) : prevParams.featureIDs,
            page: pageFromQuery ? Number(pageFromQuery) : prevParams.page,

            placeId: placeIdFromQuery || prevParams.placeId,
            placeName: placeNameFromQuery || prevParams.placeName,
            latitude: latitudeFromQuery ? Number(latitudeFromQuery) : prevParams.latitude,
            longitude: longitudeFromQuery ? Number(longitudeFromQuery) : prevParams.longitude,
            premise: premiseFromQuery || prevParams.premise,
            streetNumber: streetNumberFromQuery || prevParams.streetNumber,
            route: routeFromQuery || prevParams.route,
            sublocalityLevel1: sublocalityLevel1FromQuery || prevParams.sublocalityLevel1,
            locality: localityFromQuery || prevParams.locality,
            adminLavel1: adminLavel1FromQuery || prevParams.adminLavel1,
            country: countryFromQuery || prevParams.country,

        }));
    }, [searchParams]);

    const updateFilterParams = (newParams: Partial<PropertyFilterParam>) => {
        setFilterParams((prevParams) => {
            const updatedParams = { ...prevParams, ...newParams };
            const newSearchParams: Record<string, string> = {};

            Object.entries(updatedParams).forEach(([key, value]) => {
                if (Array.isArray(value) && value.length > 0) {
                    newSearchParams[key] = value.join(',');
                } else if (typeof value === "number" && value !== null) {
                    newSearchParams[key] = String(value);
                } else if (typeof value === "string" && value) {
                    newSearchParams[key] = value;
                }
            });

            setSearchParams(newSearchParams);

            return updatedParams;
        });
    };

    return [filterParams, updateFilterParams] as const;
};
