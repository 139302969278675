import { CreateAxiosGetRequest, CreateAxiosPostRequest } from "./GeneralService";
import { useLanguage } from "../context/LanguageContext";

export const usePostEditUserProfile = (params?: any, token?: string) => {
  const { language } = useLanguage();
  const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
    url: `account/editUserProfile`,
    enabled: false,
    data: params,
    headers: {
      language: language.toUpperCase(),
      channel: "w",
      authorization: `Bearer ${token}`,
    },
  });

  return { responseData, loading, error, refetch };
};


export const useGetMyFavouritesProperties = (params?: any, token?: string) => {
  const { language } = useLanguage();
  const { data: properties, loading, error, refetch } = CreateAxiosGetRequest({
    url: `prop/loadUserFavProperties`,
    params: params,
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
      authorization: `Bearer ${token}`,
    },
  });

  return { properties, loading, error, refetch };
};


export const useAddToFavourites = (params?: any, token?: string) => {
  const { language } = useLanguage();
  const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
    url: `account/addToFavourite`,
    enabled: false,
    data: params,
    headers: {
      language: language.toUpperCase(),
      channel: "w",
      authorization: `Bearer ${token}`,
    },
  });

  return { responseData, loading, error, refetch };
};


export const useDeleteFavourites = (params?: any, token?: string) => {
  const { language } = useLanguage();
  const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
    url: `account/deleteFavourite`,
    enabled: false,
    data: params,
    headers: {
      language: language.toUpperCase(),
      channel: "w",
      authorization: `Bearer ${token}`,
    },
  });

  return { responseData, loading, error, refetch };
};


export const useAddSaveSearch = (params?: any, token?: string) => {
  const { language } = useLanguage();
  const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
    url: `account/saveSearch`,
    enabled: false,
    data: params,
    headers: {
      language: language.toUpperCase(),
      channel: "w",
      authorization: `Bearer ${token}`,
    },
  });

  return { responseData, loading, error, refetch };
};


export const useEditSaveSearch = (params?: any, token?: string) => {
  const { language } = useLanguage();
  const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
    url: `account/renameSavedSearch`,
    enabled: false,
    data: params,
    headers: {
      language: language.toUpperCase(),
      channel: "w",
      authorization: `Bearer ${token}`,
    },
  });

  return { responseData, loading, error, refetch };
};


export const useDeleteSaveSearch = (params?: any, token?: string) => {
  const { language } = useLanguage();
  const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
    url: `account/deleteSavedSearch`,
    enabled: false,
    data: params,

    headers: {
      language: language.toUpperCase(),
      channel: "w",
      authorization: `Bearer ${token}`,
    },
  });

  return { responseData, loading, error, refetch };
};


export const useGetSavedSearch = (params?: any, token?: string, enabled: boolean = true) => {
  const { language } = useLanguage();
  const { data, loading, error, refetch } = CreateAxiosGetRequest({
    url: `account/loadSavedSearch`,
    params: params,
    enabled: Boolean(enabled && token),
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
      authorization: `Bearer ${token}`,
    },
  });

  return { data, loading, error, refetch };
};



export const useUserDeleteAccount = (params?: any, token?: string) => {
  const { language } = useLanguage();
  const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
    url: `account/deleteAccount`,
    enabled: false,
    data: params,
    headers: {
      language: language.toUpperCase(),
      channel: "w",
      authorization: `Bearer ${token}`,
    },
  });

  return { responseData, loading, error, refetch };
};