import { CompaniesResponse } from "../helpers/types/company";
import { CreateAxiosGetRequest } from "./GeneralService";
import { useLanguage } from "../context/LanguageContext";
import dataEn from "../Data/data.json";
import dataAr from "../Data/dataAr.json";


export const useGetDeleteReasons = () => {
  const { language } = useLanguage();
  const { data: result, loading, error, refetch } = CreateAxiosGetRequest<any>({
    url: `systemData/loadDeleteReason`,
    headers: {
      language: language.toUpperCase(),
      channel: "w",
    },
  });

  return { result, loading, error, refetch };
};

export const useGetCurrencies = () => {
  const { language } = useLanguage();
  const { data: result, loading, error, refetch } = CreateAxiosGetRequest<any>({
    url: `systemData/loadCurrencies`,
    
    headers: {
      language: language.toUpperCase(),
      channel: "w",
    },
  });

  return { result, loading, error, refetch };
};

// export const useGetSortLists = () => {
//   const { language } = useLanguage();
//   const { data: result, loading, error, refetch } = CreateAxiosGetRequest<any>({
//     url: `systemData/loadCurrencies`,
//     headers: {
//       language: language.toUpperCase(),
//       channel: "w",
//     },
//   });

//   return { result, loading, error, refetch };
// };


export const useGetSortLists = () => {
  const { language } = useLanguage();

  const loading = false;
  const error = null;

  const result = language === "ar" ? dataAr.sortLists : dataEn.sortLists;

  const refetch = () => {
    return result;
  };

  return { result, loading, error, refetch };
};


export const useGetAreaMeasurement = () => {
  const { language } = useLanguage();
  const { data: result, loading, error, refetch } = CreateAxiosGetRequest<any>({
    url: `systemData/loadUnits`,
    headers: {
      language: language.toUpperCase(),
      channel: "w",
    },
  });

  return { result, loading, error, refetch };
};


export const useGetModes = () => {
  const { language } = useLanguage();

  const loading = false;
  const error = null;

  const result = language === "ar" ? dataAr.changeMode : dataEn.changeMode;

  const refetch = () => {
    return result;
  };

  return { result, loading, error, refetch };
};
