export class StorageService {
    private storage: Storage;

    constructor(storageType: Storage = localStorage) {
        this.storage = storageType; // Can switch to sessionStorage if needed
    }

    setItem(key: string, value: any): void {
        this.storage.setItem(key, JSON.stringify(value));
    }

    getItem<T>(key: string): T | null {
        const item = this.storage.getItem(key);
        return item ? (JSON.parse(item) as T) : null;
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    clear(): void {
        this.storage.clear();
    }
}
