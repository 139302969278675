import React, { useEffect, useState } from 'react';
import './infoSectionComponent.scss';

export interface InfoItem {
    label: string;
    value?: string | number;
    iconUrl?: string;
}

interface InfoSectionComponentProps {
    title: string;
    items: InfoItem[];
    columns?: number;
}

const InfoSectionComponent: React.FC<InfoSectionComponentProps> = ({ title, items, columns = 2 }) => {
    const [dynamicColumns, setDynamicColumns] = useState(columns);

    const updateColumns = () => {
        if (window.innerWidth <= 425) {
            setDynamicColumns(1);  // Mobile view
        } else if (window.innerWidth <= 768) {
            setDynamicColumns(2);  // Tablet view
        } else {
            setDynamicColumns(columns);  // Default columns
        }
    };

    useEffect(() => {
        updateColumns();
        window.addEventListener('resize', updateColumns);

        return () => {
            window.removeEventListener('resize', updateColumns);
        };
    }, [columns]);

    const totalItems = items.length;
    const remainder = totalItems % dynamicColumns === 0 ? dynamicColumns : totalItems % dynamicColumns;
    const lastRowStartIndex = totalItems - remainder;

    // Helper function to check if the value is a URL
    const isValidUrl = (value: string) => {
        try {
            new URL(value);
            return true;
        } catch (error) {
            return false;
        }
    };

    return (
        <div className="info-section-details">
            <span className="section-title">{title}</span>
            <div className={`items-grid ${columns <= 2 ? "small-grid" : ""}`} style={{ gridTemplateColumns: `repeat(${dynamicColumns}, 1fr)` }}>
                {items.map((item, index) => {
                    const isLastRowItem = index >= lastRowStartIndex;
                    return (
                        <div key={index} className={`info-item ${isLastRowItem || !item.value ? 'last-in-column' : ''}`}>
                            {item.iconUrl && (
                                <img src={item.iconUrl} alt={item.label} className="icon" />
                            )}
                            <span className="label">{item.label}</span>
                            {item.value && (
                                isValidUrl(item.value.toString()) ? (
                                    <a href={item.value.toString()} target="_blank" rel="noopener noreferrer" className="value link">
                                        View Link
                                    </a>
                                ) : (
                                    <span className="value">{item.value}</span>
                                )
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default InfoSectionComponent;
