import './freshSale.scss';
import AreaCard from "../../HomeComponents/areaCard/areaCard";
import { useTranslation } from "react-i18next";
import { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Location } from '../../../helpers/types/general';
import { useLanguage } from '../../../context/LanguageContext';

interface FreshSaleProps {
    areas: Location[];
    onSearchClick?: (search: Location) => void;
}

const FreshSale: React.FC<FreshSaleProps> = ({ areas, onSearchClick }) => {
    const swiperRef = useRef<any>(null);
    const { t } = useTranslation();
    const { language } = useLanguage();
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const direction = language === 'ar' ? 'rtl' : 'ltr';

    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const updateButtonState = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            const swiperInstance = swiperRef.current.swiper;
            setIsAtStart(swiperInstance.isBeginning);
            setIsAtEnd(swiperInstance.isEnd);
        }
    };

    const handleSlideChange = () => {
        updateButtonState();
    };

    const handleSwiperInit = () => {
        updateButtonState();
    };

    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handleItemClick = (item: Location) => {
        if (onSearchClick) {
            onSearchClick(item);
        }
    };

    return (
        <div key={language} className="fresh-sale">
            <h2 className="sale-title">
                {t("FreshPropertiesSaleListingsAvailableNow")}
            </h2>
            <div className="popular-residential">
                <div className="slider">
                    <button
                        className="prev-btn"
                        onClick={handlePrev}
                        disabled={isAtStart}
                        style={isAtStart ? { opacity: 0.5 } : undefined}>
                        <img
                            src={direction === 'rtl' ? "/images/home/arrow-right-5-svgrepo-com.svg" : "/images/home/arrow-left-5-svgrepo-com.svg"}
                            alt="Previous"
                        />
                    </button>
                    <Swiper
                        key={direction} 
                        ref={swiperRef}
                        slidesPerView={'auto'}
                        spaceBetween={direction === 'rtl' ? 12 : 30}
                        modules={[Navigation]}
                        onInit={handleSwiperInit}
                        onSlideChange={handleSlideChange}
                    >
                        {areas?.map((option) => (
                            <SwiperSlide key={option?.area.name} onClick={() => handleItemClick(option)}>
                                <AreaCard
                                    slug={option?.area.name}
                                    areaName={option?.area?.name}
                                    propertiesNum={20}
                                    backgroundImageUrl={option?.area?.image || "/images/apts/1.png"}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <button
                        className="next-btn"
                        onClick={handleNext}
                        disabled={isAtEnd}
                        style={isAtEnd ? { opacity: 0.5 } : undefined}>
                        <img
                            src={direction === 'rtl' ? "/images/home/arrow-left-5-svgrepo-com.svg" : "/images/home/arrow-right-5-svgrepo-com.svg"}
                            alt="Next"
                        />
                    </button>
                </div>


                {/* <div className="slider-container">
                <button className="nav-btn left-btn" onClick={handlePrevClick}>
                    <LeftOutlined />
                </button>

                <div className="cards-container" ref={containerRef}>
                    {areas.map((area) => (
                        <AreaCard
                            key={area.area.id}
                            slug={area.area.name}
                            areaName={area.area.name}
                            propertiesNum={Math.floor(Math.random() * 30) + 1} //TODO: Replace this with actual number of properties
                            backgroundImageUrl={area.area.image || "images/default-image.png"}
                        />
                    ))}
                </div>

                <button className="nav-btn right-btn" onClick={handleNextClick}>
                    <RightOutlined />
                </button>
            </div> */}

            </div>

        </div>
    );
}

export default FreshSale;
