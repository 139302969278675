import { Button, Col, Form, Input, Row, Space, message } from 'antd';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import './footer.scss';
import logo from '../../assets/logo.svg';
import { usePostNewsLetter } from '../../services/newsLetterServices';

export default function Footer() {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { refetch, loading } = usePostNewsLetter();

    const handleSubscribe = () => {
        form.validateFields()
            .then(() => {
                const email = form.getFieldValue('email');
                refetch({
                    data: {
                        email: email,
                        sourceId: null,
                        flag: true,
                        preferenceType: 'Newsletter',
                        EmailPreferenceRequest: "name"
                    }
                })
                    .then(() => {
                        message.success(t("newsLetter.SubscriptionSuccess"));
                        form.resetFields();
                    })
                    .catch(() => {
                        message.error(t("newsLetter.SubscriptionError"));
                    });
            })
            .catch((errorInfo) => {
                console.error('Validation Failed:', errorInfo);
            });
    };

    return (
        <div className="footers">
            <div className="container-width">
                <div className="footer">
                    <div className="logo">
                        <Link to="/">
                            <Link to="/"><img src={logo} alt="Logo" width={"147px"} height={"31.26px"} /></Link>
                        </Link>
                        <span className='download-title'>{t("footer.DownloadTheAppByClickingTheLinkBelow")}</span>
                        <div className="download-btn">
                            <img src={'/images/googlePlay.svg'} alt="Google Play" />
                            <img src={'/images/appStore.svg'} alt="App Store" />
                        </div>
                    </div>
                    <div className="devidor" />
                    <div className="footer-menu">
                        <div className="menuLinks">
                            <span className="title">{t("footer.Pages")}</span>
                            <div className="links">
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} sm={11}>
                                        <div className="links">
                                            <Link to="about-us">{t("nav.aboutus")}</Link>
                                            <Link to="partners">{t("Partenrs.Partenrs")}</Link>
                                            <Link to="career">{t("nav.career")}</Link>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <div className="links">
                                        <Link to="contact-us">{t("footer.Contact")}</Link>
                                        <Link to="terms">{t("nav.terms")}</Link>
                                            <Link to="privacy-policy">{t("nav.PrivacyPolicy")}</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="menuLinks">
                            <div className="newsletter-container">
                                <h3 className="newsletter-title"> {t("footer.NewsletterSubscription")} </h3>
                                <Form form={form} layout="inline">
                                    <Space>
                                        <Form.Item
                                            name="email"
                                            className="newsletter-input-container"
                                            rules={[
                                                { required: true, message: t("newsLetter.enterEmail") },
                                                { type: 'email', message: t("newsLetter.invalidEmail") }
                                            ]}
                                        >
                                            <Input
                                                placeholder={t("footer.enterEmail")}
                                                size="large"
                                                className="newsletter-input"
                                            />
                                        </Form.Item>
                                    </Space>
                                    <Button
                                        type="primary"
                                        size="large"
                                        className="subscribe-button"
                                        loading={loading}
                                        onClick={handleSubscribe}
                                    >
                                        {t("footer.Subscribe")}
                                    </Button>
                                </Form>
                            </div>
                            <span className="socialMedia-title">{t("footer.SocialMedia")}</span>
                            <div className="social-links">
                                <Link className='link' to='https://www.facebook.com/profile.php?id=61564134832261&mibextid=ZbWKwL' target="_blank">
                                    <img src="/images/apts/icons/Facebook-icon.svg" alt="Facebook" />
                                </Link>
                                <Link className='link' to='https://www.instagram.com/housez.ae?igsh=OTBycTFydTZ4cTZi' target="_blank">
                                    <img src="/images/apts/icons/instagram-icon.svg" alt="Instagram" />
                                </Link>
                                <Link className='link' to='https://youtube.com/@housezae?si=yHHnolARBaFlwobM' target="_blank">
                                    <img src="/images/apts/icons/Youtube-icon.svg" alt="YouTube" />
                                </Link>
                                <Link className='link' to='https://www.tiktok.com/@housez.ae?_t=8ov4Dexpt7Y&_r=1' target="_blank">
                                    <img src="/images/apts/icons/TikTok-icon.svg" alt="TikTok" />
                                </Link>
                                <Link className='link' to='https://x.com/housezAE?t=KmeN80TMtt2t1745ulpnNg&s=08' target="_blank">
                                    <img src="/images/apts/icons/x-icon.svg" alt="X" />
                                </Link>
                                <Link className='link' to='https://www.linkedin.com/company/housez-ae/' target="_blank">
                                    <img src="/images/apts/icons/linkedIn-Icon.svg" alt="LinkedIn" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="terms">
                    <span dir='ltr'>
                        {t("footer.allRight")}
                    </span>
                </div>
            </div>
        </div>
    )
}
