import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import './Dropdown.scss';

interface DropdownItem {
    label: string;
    path?: string;
    onClick?: () => void;
    icon?: string;
    className?: string;
}

interface DropdownProps {
    title: string;
    icon?: string;
    showDropdownArrow?: boolean;
    items: DropdownItem[];
    className?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
    title,
    icon,
    items,
    showDropdownArrow = true,
    className = ""
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setIsOpen((prev) => !prev);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={`dropdown-container ${className}`} ref={dropdownRef}>
            <div
                className="dropdown-toggle"
                onClick={toggleDropdown}
                aria-expanded={isOpen}
            >
                {icon && <img src={icon} alt="icon" />}
                <span>{title}</span>
                {showDropdownArrow && (
                    <img
                        src={"/images/home/arrow-down.svg"}
                        alt="Arrow"
                        className={`arrow ${isOpen ? "up" : "down"}`}
                    />
                )}
            </div>
            {isOpen && (
                <div className="dropdown-menu">
                    {items.map((item, index) => (
                        item.path ? (
                            <Link key={index} to={item.path} onClick={() => setIsOpen(false)}>
                                {item.icon && <img src={item.icon} alt="item-icon" />}
                                {item.label}
                            </Link>
                        ) : (
                            <div
                                className={`user-dropdown-item ${item.className}`}
                                key={index}
                                onClick={() => {
                                    item.onClick?.();
                                    setIsOpen(false);
                                }}
                            >
                                {item.icon && <img src={item.icon} alt="item-icon" />}
                                {item.label}
                            </div>
                        )
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
