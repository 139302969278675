import React, { createContext, useContext, useState, useEffect } from "react";
import { StorageService } from "../services/storageServices";

export interface SettingsContextProps {
  area: string;
  currency: string;
  mode: string;
  sort: string;
  updateSetting: (key: keyof SettingsContextProps, value: string) => void;
}

const SettingsContext = createContext<SettingsContextProps | undefined>(undefined);

const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const storageService = new StorageService();

  const [settings, setSettings] = useState<SettingsContextProps>({
    area: storageService.getItem("area") || "sqft",
    currency: storageService.getItem("currency") || "AED",
    mode: storageService.getItem("mode") || "light",
    sort: storageService.getItem("sort") || "newest",
    updateSetting: () => { },
  });

  const updateSetting = (key: keyof SettingsContextProps, value: string) => {
    setSettings((prev) => ({ ...prev, [key]: value }));
    storageService.setItem(key, value);
  };

  useEffect(() => {
    setSettings({
      area: storageService.getItem("area") || "sqft",
      currency: storageService.getItem("currency") || "AED",
      mode: storageService.getItem("mode") || "light",
      sort: storageService.getItem("sort") || "newest",
      updateSetting,
    });
  }, []);

  return (
    <SettingsContext.Provider value={{ ...settings, updateSetting }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = (): SettingsContextProps => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};

export default SettingsProvider;
