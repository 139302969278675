import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Button, Select } from 'antd';
import './styles/AdvancedFilter.scss';
import { ReactComponent as DefaultSuffixIcon } from '../../../assets/icons/advanced.svg';
import { FilterType } from '../FilterContainer';
import SelectFilter from './SelectFilter';
import MinMaxInput from './inputs/MinMaxInput';
import CheckboxInput from './inputs/CheckboxInput';
import { useTranslation } from 'react-i18next';

interface SelectOption {
    id: number | string;
    name: string;
}

interface SectionAttribute {
    maxParam: string,
    minParam: string,
    maxValue: number;
    minValue: number;
    defaultMaxValue: number;
    defaultMinValue: number;
    maxPlaceholder: string;
    minPlaceholder: string;
}

export interface Section {
    label: string;
    type: FilterType;
    param: string;
    value?: any;
    showLabel?: boolean;
    showOnlyIcon?: boolean
    options?: SelectOption[];
    className?: string;
    multiple?: boolean;
    attributes?: SectionAttribute;
    onChange?: (param: string, value: any) => void;
    directQueryOnChange?: boolean;
}

interface CheckboxGroupFilterProps {
    className?: string;
    sections: Section[];
    placeholder: string;
    multiple?: boolean;
    witWwhitebackground?: boolean;
    suffixIcon?: React.ReactNode; // Add suffixIcon to props
    onChange: (param: string, selectedOptions: any[], directQueryOnChange?: boolean) => void;
    handleApplayFilterChange?: () => void
}

const AdvancedFilter: React.FC<CheckboxGroupFilterProps> = ({
    sections,
    placeholder,
    multiple = false,
    witWwhitebackground = true,
    onChange,
    className,
    suffixIcon = <DefaultSuffixIcon className="custom-advanced-icon" />, // Default to the original icon if none provided
    handleApplayFilterChange

}) => {
    const { t } = useTranslation();
    const [currentPlaceholder, setCurrentPlaceholder] = useState<string>(placeholder);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const initializeSelectedValues = () => {
        const initialValues: Record<string, any[]> = {};
        sections.forEach((section) => {
            initialValues[section.param] = section.value || [];
        });
        return initialValues;
    };

    const [selectedValues, setSelectedValues] = useState<Record<string, string[]>>(initializeSelectedValues);
    const [selectedValuesFormatted, setSelectedValuesFormatted] = useState<string | undefined>(undefined);

    useEffect(() => {
        setCurrentPlaceholder(placeholder);
    }, [placeholder]);

    const handleCheckboxChange = (sectionParam: string, checkedValues: string[] | any, directQueryOnChange = true) => {
        setSelectedValues(prev => ({
            ...prev,
            [sectionParam]: checkedValues,
        }));
        onChange(sectionParam, checkedValues, directQueryOnChange);
    };

    const handleReset = () => {
        const event = new Event("advancedResetButtonClick");
        document.dispatchEvent(event);
        const resetValues: Record<string, string[]> = {};
        sections?.forEach(section => {
            resetValues[section.param] = [];
        });
        setSelectedValues(resetValues);
        sections?.forEach(section => onChange(section.param, [], true));
        setCurrentPlaceholder(placeholder);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    useEffect(() => {
        const formatSelectedValues = () => {
            if (!sections || sections.length === 0) {
                setSelectedValuesFormatted(undefined);
                return;
            }

            const selectedSections = sections?.map(section => {
                const _selectedValues = selectedValues[section.param] || [];
                if (_selectedValues.length > 0) {
                    const selectedNames = _selectedValues?.map(selectedId => {
                        const option = section?.options?.find(option => option.id === selectedId);
                        return option ? option.name : '';
                    })
                        .filter(Boolean);

                    return `${selectedNames.join(', ')} ${section.showLabel ? section.label : ''}`;
                }
                return '';
            })
                .filter(Boolean)
                .join(' / ');

            if (!selectedSections) {
                setSelectedValuesFormatted(undefined);
            } else {
                setSelectedValuesFormatted(selectedSections);
            }
        };

    }, [selectedValues, sections, placeholder]);

    const renderSectionFilter = (section: Section) => {
        switch (section.type) {
            case 'select':
                return (
                    <SelectFilter
                        className={section.className}
                        options={section.options || []}
                        placeholder={section.showLabel ? section.label : ''}
                        multiple={section?.multiple}

                        onChange={(value) => {
                            handleCheckboxChange(section.param, value, section?.directQueryOnChange);
                            section?.onChange?.(section.param, value);
                        }}
                        value={selectedValues[section.param] || []}
                    />
                );
            case 'checkbox':
                return (
                    <CheckboxInput
                        value={selectedValues[section.param]}
                        options={section?.options || []}
                        multiple={section?.multiple}
                        onChange={(checkedValues) => {
                            handleCheckboxChange(section.param, checkedValues, section?.directQueryOnChange);
                            section?.onChange?.(section.param, checkedValues);
                        }}
                    />
                );
            case 'checkboxGroup':
                return (
                    <Checkbox.Group
                        value={selectedValues[section.param]}
                        onChange={(checkedValues) => {
                            handleCheckboxChange(section.param, checkedValues, section?.directQueryOnChange);
                            section?.onChange?.(section.param, checkedValues);
                        }}
                    >
                        <div className="filter-options">
                            {section?.options?.map((option) => (
                                <Checkbox key={option.id} value={option.id} className="checkbox-option circular-checkbox">
                                    {option.name}
                                </Checkbox>
                            ))}
                        </div>
                    </Checkbox.Group>
                );
            case 'minMax':
                return (
                    <MinMaxInput
                        className={section.className}
                        key={section.param}
                        minPlaceholder={section.attributes?.minPlaceholder || ''}
                        maxPlaceholder={section.attributes?.maxPlaceholder || ''}
                        minValue={section.attributes?.minValue || 0}
                        maxValue={section.attributes?.maxValue || 0}
                        defaultMaxValue={section.attributes?.defaultMaxValue}
                        defaultMinValue={section.attributes?.defaultMinValue}

                        onMinChange={(value) => {
                            handleCheckboxChange(`${section?.attributes?.minParam}`, value, section?.directQueryOnChange);
                            if (section?.attributes?.minParam) section?.onChange?.(section.attributes.minParam, value);
                        }}
                        onMaxChange={(value) => {
                            handleCheckboxChange(`${section?.attributes?.maxParam}`, value, section?.directQueryOnChange);
                            if (section?.attributes?.minParam) section?.onChange?.(section?.attributes?.maxParam, value);
                        }}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className={`group-filter-container advanced-filter-container ${className} ${isDropdownOpen ? 'dropdown-open' : ''}`} ref={dropdownRef}>
                <Select
                    className={'icon-only-select more-filter-select'}
                    mode={multiple ? 'multiple' : undefined}
                    placeholder={currentPlaceholder}
                    onClick={toggleDropdown}
                    open={false}
                    value={selectedValuesFormatted}
                    maxTagCount="responsive"
                    suffixIcon={suffixIcon} // Use the passed suffixIcon prop here
                    filterOption={false}
                    removeIcon={false}

                />
                <button className="btn more-filter-container-btn" onClick={() => { toggleDropdown() }}>

                    {suffixIcon}
                    {/* <span className="text-icon">{t('common.reset')}</span> */}

                </button>
                {isDropdownOpen && (
                    <div className="dropdown-overlay">
                        <div className={`dropdown-content ${witWwhitebackground ? "white-background" : ""}`}>
                            {sections && sections?.map((section, index) => (
                                <div key={index} className={`filter-section-container ${section.className ?? ''}`}>
                                    <div key={index} className="filter-section">
                                        {section.showLabel && <h3>{section.label}</h3>}
                                        {renderSectionFilter(section)}
                                    </div>
                                </div>
                            ))}
                            <div className="filter-buttons">
                                <Button className="reset-btn" onClick={handleReset}>
                                    {t('common.reset')}
                                </Button>
                                <Button type="primary" className="apply-btn" onClick={() => {
                                    handleApplayFilterChange && handleApplayFilterChange()
                                    toggleDropdown()
                                }}>
                                    {t('common.apply')}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </>
    );
};

export default AdvancedFilter;
