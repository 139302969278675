import React from 'react';
import './contactUs.scss';
import HeaderPages from '../../components/pagesFooterHeaderSection/header';
import { Row, Col, Input, Button, Form, message } from 'antd';
import ContactUsMap from './ContactUsMap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePostContactUsPage } from '../../services/contactUsServices';

const { TextArea } = Input;

const ContactUsPage = () => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const { responseData, loading, error, refetch } = usePostContactUsPage();

    const handleSubmit = async (values: any) => {
        try {
            refetch({
                data: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    subject: values.subject,
                    message: values.message,
                }
            })
                .then(() => {
                    message.success(t("ContactUs.RequestSuccessMessage"));
                    form.resetFields();
                })
                .catch((error) => {
                    message.error(t("ContactUs.SubmissionErrorMessage"));
                });

        } catch (error) {
            message.error(t("ContactUs.SubmissionErrorMessage"));
        }
    };

    return (
        <div className='contactUsPage'>
            <HeaderPages h1={t("footer.Contact")} title='' />

            <div className='Contact-us'>
                <p className='contactUs-title'>
                    {t("ContactUs.contactUsForAny")}
                </p>
                <Row gutter={[34, 32]} className="contact-section">
                    <Col xs={24} md={9} className="location-contact">
                        <div className="location">
                            <h3 className='contactUs-headers'> {t("filters.Location")} </h3>
                            <ContactUsMap
                                address={"Discovery Tower - Dubai Studio City - Dubai - United Arab Emirates"}
                                latitude={25.04187909012745}
                                longitude={55.250449282817854}
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
                            />
                        </div>
                        <Row gutter={[16, 16]} align="top">
                            <Col xs={24} sm={12}>
                                <div className="contact-info">
                                    <h3 className='contactUs-headers'> {t("ContactUs.contact")} </h3>
                                    <p><img src="/images/apts/icons/phoneContact.svg" alt="Phone" /> 04 5573151</p>
                                    <p><img src="/images/apts/icons/mailContact.svg" alt="Email" /> Info@housez.ae</p>
                                </div>
                            </Col>
                            <Col xs={24} sm={12}>
                                <div className="social-media" style={{ display: 'flex', flexDirection: "column" }}>
                                    <h3 className='contactUs-headers'> {t("footer.SocialMedia")} </h3>
                                    <div style={{ display: "flex", gap: "16px" }}>
                                        <Link to='https://www.facebook.com/profile.php?id=61564134832261&mibextid=ZbWKwL' target="_blank">
                                            <img src="/images/apts/icons/Facebook-icon.svg" alt="Facebook" />
                                        </Link>
                                        <Link to='https://www.instagram.com/housez.ae?igsh=OTBycTFydTZ4cTZi' target="_blank">
                                            <img src="/images/apts/icons/instagram-icon.svg" alt="Instagram" />
                                        </Link>
                                        <Link to='https://youtube.com/@housezae?si=yHHnolARBaFlwobM' target="_blank">
                                            <img src="/images/apts/icons/Youtube-icon.svg" alt="YouTube" />
                                        </Link>
                                        <Link to='https://www.tiktok.com/@housez.ae?_t=8ov4Dexpt7Y&_r=1' target="_blank">
                                            <img src="/images/apts/icons/TikTok-icon.svg" alt="TikTok" />
                                        </Link>
                                        <Link to='https://x.com/housezAE?t=KmeN80TMtt2t1745ulpnNg&s=08' target="_blank">
                                            <img src="/images/apts/icons/x-icon.svg" alt="X" />
                                        </Link>
                                        <Link to='https://www.linkedin.com/company/housez-ae/' target="_blank">
                                            <img src="/images/apts/icons/linkedIn-Icon.svg" alt="LinkedIn" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} md={15} className="message-form">
                        <h3 className='contactUs-headers'> {t("ContactUs.SendMessage")} </h3>
                        <Form form={form} layout="vertical" onFinish={handleSubmit} requiredMark={false} key={i18n.language}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <div className='input-group'>
                                        <Form.Item
                                            label={t("SignUp.FirstName")}
                                            name="firstName"
                                            rules={[{ required: true, message: t("ContactUs.FirstNamerequired") }]}
                                        >
                                            <Input className='InputBoxes' />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className='input-group'>
                                        <Form.Item
                                            label={t("SignUp.LastName")}
                                            name="lastName"
                                            rules={[{ required: true, message: t("ContactUs.LastNamerequired") }]}
                                        >
                                            <Input className='InputBoxes' />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <div className='input-group'>
                                        <Form.Item
                                            label={t("SignUp.email")}
                                            name="email"
                                            rules={[
                                                { required: true, message: t("ContactUs.Emailrequired") },
                                                { type: 'email', message: t("ContactUs.validemail") }
                                            ]}
                                        >
                                            <Input type='email' className='InputBoxes' />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className='input-group'>
                                        <Form.Item
                                            label={t("ContactUs.Subject")}
                                            name="subject"
                                            rules={[{ required: true, message: t("ContactUs.Subjectrequired") }]}
                                        >
                                            <Input className='InputBoxes' />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>

                            <Form.Item
                                label={t("ContactUs.Message")}
                                name="message"
                                rules={[{ required: true, message: t("ContactUs.Messagerequired") }]}
                            >
                                <TextArea
                                    style={{ borderRadius: "25px", maxHeight: "160px", height: "160px" }}
                                />
                            </Form.Item>

                            <div className='w'>
                                <Button type="primary" htmlType="submit" className="login-button" loading={loading}>
                                    {t("common.submit")}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ContactUsPage;
