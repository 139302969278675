import { ContactUsFormPage, NewsLetter } from "../helpers/types/general";
import { CreateAxiosPostRequest } from "./GeneralService";

export const usePostNewsLetter = (params?: NewsLetter, language: string = 'en', section: string = 'H') => {
    const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
        url: `support/supportContact`,
        data: params,
        headers: {
            language,
            channel: 'W',
            section
        },
        enabled:false
        
    });

    return { responseData, loading, error, refetch };
};