import './infoIconsComponent.scss';


interface InfoIconsComponentProps {
    data: {
        icon: string;
        label: string;
    }[];
    className: string;
}

const InfoIconsComponent = ({ data, className }: InfoIconsComponentProps) => {
    return (
        <div className={`property-info-container ${className}`}>
            {data.map((item, index) => (
                <div key={index} className="property-info-item">
                    <div className="icon-container">
                        <img src={item.icon} alt={item.label} />
                    </div>
                    <span>{item.label}</span>
                </div>
            ))}
        </div>
    );
};

export default InfoIconsComponent;
