import { BaseParam, ContactUsForm, ContactUsFormPage } from "../helpers/types/general";
import { CreateAxiosPostRequest } from "./GeneralService";


export const usePostContactUs = (params?: ContactUsForm, language: string = 'en', section: string = 'H') => {
    const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
        url: `contactUs/contactUs`,
        data: params,
        headers: {
            language,
            channel: 'W',
            section
        },
        enabled:false
    });

    return { responseData, loading, error, refetch };
};

export const usePostContactUsPage = (params?: ContactUsFormPage, language: string = 'en', section: string = 'H') => {
    const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
        url: `support/supportContact`,
        data: params,
        headers: {
            language,
            channel: 'W',
            section
        },
        enabled:false
        
    });

    return { responseData, loading, error, refetch };
};