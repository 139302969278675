interface AuthConfig {
    domain: string;
    clientId: string;
    clientSecret?: string; // Optional for backend service
    audience?: string; // Optional for API access
    redirectUri: string;
}

const authConfig: AuthConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    clientSecret: process.env.REACT_APP_AUTH0_CLIENT_SECRET || '',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE, // Optional
    redirectUri: window.location.origin,
};

export default authConfig;
