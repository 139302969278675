import React from 'react';
import { Modal } from 'antd';
import './termsModal.scss'
import { useTranslation } from 'react-i18next';
interface TermsModalProps {
    visible: boolean;
    onCancel: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ visible, onCancel }) => {
    const {t}=useTranslation()
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            footer={null}
            centered
            className='termsModal'
            width="825px"
            closeIcon={
                <img
                    className="close-icon"
                    src="/images/apts/icons/AiCloseIcon.svg"
                    alt="Close"
                    onClick={onCancel}
                />
            }
        >
          <p className='housezText'>{t('AiTerms.housezText')}</p>
      <p className='descriptionText'>{t('AiTerms.descriptionText1')}</p>
      <p className='descriptionText'>{t('AiTerms.descriptionText2')}</p>
      <p className='titleText'>{t('AiTerms.titleText1')}</p>
      <p className='descriptionText'>{t('AiTerms.descriptionText3')}</p>
      <p className='titleText'>{t('AiTerms.titleText2')}</p>
      <p className='descriptionText'>{t('AiTerms.descriptionText4')}</p>
      <p className='titleText'>{t('AiTerms.titleText3')}</p>
      <p className='descriptionText'>{t('AiTerms.descriptionText5')}</p>
      <p className='descriptionText'>{t('AiTerms.descriptionText6')}</p>
      <p className='descriptionText'>{t('AiTerms.descriptionText7')}</p>
      <p className='descriptionText'>{t('AiTerms.descriptionText8')}</p>
        </Modal>
    );
};

export default TermsModal;
