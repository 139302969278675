import { AgentFilterParam } from "../helpers/types/filter";
import { AgentsResponse } from "../helpers/types/agent";
import { CreateAxiosGetRequest, CreateAxiosPostRequest } from "./GeneralService";
import { BaseParam, ClickAction, ReportCategoryResponse, ReportForm } from "../helpers/types/general";
import { useLanguage } from "../context/LanguageContext";

export const useGetAgents = (filters: AgentFilterParam) => {
  const { language } = useLanguage();

  const { data: result, loading, error, refetch } = CreateAxiosGetRequest<AgentsResponse>({
    url: `agent/loadAgents`,
    params: {
      ...filters,
      language: language.toUpperCase(),
    },
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
    },
  });

  return { result, loading, error, refetch };
};

export const useGetAgent = (id: number | string) => {
  const { language } = useLanguage();

  const { data: agent, loading: agentLoading, error: agentError, refetch } = CreateAxiosGetRequest<AgentsResponse>({
    url: `agent/loadAgent`,
    params: {
      Id: id,
      language: language.toUpperCase(),
    },
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
    },
  });

  return { agent, agentLoading, agentError, refetch };
};


export const useFetchAgentSuggestions = (searchTerm: string, id?: number) => {
  const { language } = useLanguage();
  const { data, loading, error, refetch } = CreateAxiosGetRequest<AgentsResponse>({
    url: `agent/loadByAgentsNames`,
    params: {
      name: searchTerm,
      id,
      language: language.toUpperCase(),
    },
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
    },
  });

  return { agents: data?.result || [], loading, error, refetch };
};

export const useGetAgentReportReason = (params: BaseParam) => {
  const { language } = useLanguage();
  const { data: reasons, loading, error, refetch } = CreateAxiosGetRequest<ReportCategoryResponse>({
    url: `systemData/loadAgentReportCategory`,
    params: {
      ...params,
      language: language.toUpperCase(),
    },
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
    },
  });

  return { reasons, loading, error, refetch };
};

export const usePostAgentReport = (params?: ReportForm) => {
  const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
    url: `agent/agentReport`,
    data: params,
  });

  return { responseData, loading, error, refetch };
};

export const usePostAgentClick = (params?: ClickAction) => {
  const { responseData, loading, error, refetch } =
    CreateAxiosPostRequest<ClickAction>({
      url: `agent/agentClick`,
      data: params,
    });

  return { responseData, loading, error, refetch };
};
