import React from 'react';
import './customArrow.scss';

interface ArrowProps {
    direction: 'prev' | 'next';
    onClick: () => void;
    className: string;
}

const CustomArrow: React.FC<ArrowProps> = ({ direction, onClick, className }) => {
    return (
        <button
            className={`custom-arrow ${direction} ${className}`}
            onClick={onClick}
        >
            {direction === 'prev' ? <img src='/images/apts/icons/aiArrowIcon.svg' /> :
                <img src='/images/apts/icons/aiArrowIconNext.svg' />}
        </button>
    );
};

export default CustomArrow;
