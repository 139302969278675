import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { ReactComponent as SuffixIcon } from '../../../assets/icons/arrow-filter.svg';

interface SelectOption {
    id: number | string;
    name: string;
}

interface SelectFilterProps {
    className?: string;
    options: SelectOption[];
    placeholder: string;
    multiple?: boolean;
    onChange: (value: any) => void;
    value: any;
}

const filterOptions = (options: SelectOption[], searchText: string): SelectOption[] => {
    return options.filter(option =>
        option.name.toLowerCase().includes(searchText.toLowerCase())
    );
};

const SelectFilter: React.FC<SelectFilterProps> = ({ options, placeholder, multiple = false, onChange, value, className }) => {
    const [currentPlaceholder, setCurrentPlaceholder] = useState<string>(placeholder);
    const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(options);

    useEffect(() => {
        setCurrentPlaceholder(placeholder);
    }, [placeholder]);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    const handleSearch = (searchText: string) => {
        if (searchText) {
            const filtered = filterOptions(options, searchText);
            setFilteredOptions(filtered);
        } else {
            setFilteredOptions(options);
        }
    };

    const handleChange = (selectedValue: any) => {
        setFilteredOptions(options);
        onChange(selectedValue)
    };

    const handleFocus = () => {
        setCurrentPlaceholder('');
    };

    const handleBlur = () => {
        if (!value || (Array.isArray(value) && value.length === 0)) {
            setCurrentPlaceholder(placeholder);
        }
    };

    const handleDeselect = (deselectedValue: any) => {
        let updatedValue = value;
        if (Array.isArray(value)) {
            updatedValue = value.filter((item: any) => item !== deselectedValue);
        }
        if (!updatedValue || (Array.isArray(updatedValue) && updatedValue.length === 0)) {
            setCurrentPlaceholder(placeholder);
            onChange([])
        }
    };

    return (
        <Select
            className={className}
            mode={multiple ? 'multiple' : undefined}
            placeholder={placeholder}
            onChange={handleChange}
            onSearch={handleSearch}
            showSearch
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onDeselect={handleDeselect}
            maxTagCount="responsive"
            suffixIcon={<SuffixIcon className="custom-arrow-icon" />}
            filterOption={false}
        >
            {filteredOptions?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                    {option.name}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SelectFilter;
