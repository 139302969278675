import { useState, useEffect } from 'react';
import { authService, User } from './authService';
import { AuthContextType } from './AuthProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserDeleteAccount } from '../services/userServices';

export enum LoginProvider {
    USERNAME_PASSWORD = 'Username/Password',
    GOOGLE = 'Google',
    APPLE = 'Apple',
    AUTH0 = 'Auth0',
    UNKNOWN = 'Unknown',
}

export const useAuth = (): AuthContextType => {
    const [user, setUser] = useState<User | null>(null);
    const [localLoading, setLocalLoading] = useState<boolean>(true);
    const [loginProvider, setLoginProvider] = useState<LoginProvider>(LoginProvider.UNKNOWN);
    const [authToken, setAuthToken] = useState<string | null>(null);

    const { responseData, refetch: refetchDeleteAccount } = useUserDeleteAccount();


    useEffect(() => {
        getAuthToken()
            .then((token) => setAuthToken(token))
            .catch((err) => console.error("Failed to retrieve token:", err));
    }, [user]);


    const {
        loginWithRedirect,
        isAuthenticated,
        user: auth0User,
        getAccessTokenSilently,
        logout: auth0Logout,
        isLoading: auth0Loading,
    } = useAuth0();

    const isLoading = localLoading || auth0Loading;

    const determineLoginProvider = (currentUser: User | null): LoginProvider => {
        if (auth0User) {
            if (auth0User.sub?.startsWith('google-oauth2')) {
                return LoginProvider.GOOGLE;
            }
            return LoginProvider.AUTH0;
        }
        if (currentUser) {
            return LoginProvider.USERNAME_PASSWORD;
        }
        return LoginProvider.UNKNOWN;
    };

    const refreshUser = () => {
        const currentUser = authService.getUser();
        const provider = determineLoginProvider(currentUser);

        console.log("Refreshing user:", currentUser);
        setUser(currentUser);
        setLoginProvider(provider);
        setLocalLoading(false);
    };

    const login = async (email: string, password: string) => {
        setLocalLoading(true);
        try {
            const loggedInUser = await authService.login(email, password);
            setLoginProvider(LoginProvider.USERNAME_PASSWORD);
            setUser(loggedInUser);
            return loggedInUser.emailVerified;
        } finally {
            setLocalLoading(false);
        }
    };

    const signup = async (email: string, password: string, firstName: string, lastName: string) => {
        setLocalLoading(true);
        try {
            await authService.signup(email, password, firstName, lastName);
        } finally {
            setLocalLoading(false);
        }
    };

    const loginWithGoogle = async () => {
        setLocalLoading(true);
        try {
            await loginWithRedirect({
                authorizationParams: { connection: 'google-oauth2' },
            });
        } finally {
            setLocalLoading(false);
        }
    };

    const loginWithApple = async () => {
        setLocalLoading(true);
        try {
            await loginWithRedirect({
                authorizationParams: { connection: 'apple' },
            });
        } finally {
            setLocalLoading(false);
        }
    };

    const logout = () => {
        authService.logout();
        auth0Logout({ logoutParams: { returnTo: window.location.origin } });
        setUser(null);
        setLoginProvider(LoginProvider.UNKNOWN);
    };

    const forgotPassword = async (email: string) => {
        return authService.forgotPassword(email);
    };

    const getAuthToken = async (): Promise<string> => {
        console.log("Current login provider:", loginProvider);
        try {
            if (loginProvider === LoginProvider.GOOGLE || loginProvider === LoginProvider.AUTH0) {
                const token = await getAccessTokenSilently();
                localStorage.setItem('access_token', token);
                console.log("Retrieved auth token:", token);
                return token;
            } else if (loginProvider === LoginProvider.USERNAME_PASSWORD) {
                const token = authService.getToken();
                if (!token) throw new Error("No token available for Username/Password");
                console.log("Retrieved auth token:", token);
                return token;
            } else {
                throw new Error("Unknown login provider");
            }
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        refreshUser();
    }, []);

    useEffect(() => {
        if (isAuthenticated && auth0User) {
            const syncedUser: User = {
                id: auth0User.sub || "",
                email: auth0User.email || "",
                emailVerified: true,
                name: auth0User.name || "",
                firstName: auth0User.given_name || auth0User.nickname || "",
                lastName: auth0User.family_name || "",
                picture: auth0User.picture || "",
                loginProvider: LoginProvider.GOOGLE
            };
            setUser(syncedUser);
            setLoginProvider(LoginProvider.AUTH0); // Adjust dynamically if needed
        }
    }, [isAuthenticated, auth0User]);

    const deleteAccount = async (params?: any) => {
        setLocalLoading(true);
        try {
            await refetchDeleteAccount({
                data: params,
                headers: {
                    channel: "w",
                    authorization: `Bearer ${authToken}`,
                },
            });
            return true;
        } catch (error) {
            console.error("Delete account error:", error);
            return false;
        } finally {
            setLocalLoading(false);
        }
    };

    return {
        user,
        isAuthenticated: !!user,
        isLoading,
        login,
        signup,
        loginWithGoogle,
        loginWithApple,
        logout,
        refreshUser,
        getAuthToken,
        loginProvider,
        forgotPassword,
        authToken,
        deleteAccount,
    };
};
