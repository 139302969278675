import { useEffect, useState } from "react";
import './property.scss';
import ImageGallery from "react-image-gallery";
import Apartments from "../../components/apartments/apartments";
import { PuffLoader } from "react-spinners";
import RecommendedSearches from "../../components/recommendedSearches/recommendedSearches";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/LanguageContext";
import AgentContactCard from "../../components/agentContactCard/agentContactCard";
import { useGetProperties, useGetProperty, usePostPropertyReport } from "../../services/propertiesServices";
import InfoIconsComponent from "../../components/infoIconsComponent/infoIconsComponent";
import InfoSectionComponent from "../../components/infoSectionComponent/infoSectionComponent";
import DescriptionSectionComponenet from "../../components/descriptionSectionComponent/descriptionSectionComponent";
import MapComponent from "../../components/mapComponent/mapComponent";
import InfoSectionQRCodeComponent from "../../components/infoSectionQRCodeComponent/infoSectionQRCodeComponent";
import MainDescription from "../../components/PropertyComponents/mainDescription/mainDescription";
import ImageGalleryComponent from "../../components/PropertyComponents/imageGalleryComponent/imageGalleryComponent";
import ActionButtons from "../../components/PropertyComponents/actionButtons/actionButtons";
import ReportModal from "../../components/Modals/ReportModal/ReportModal";
import { Image, ImageSize, ReportForm } from "../../helpers/types/general";
import { convertObjectToImageString } from "../../helpers/helpers";
import { useGetAgentReportReason } from "../../services/agentsServices";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";


export default function Property() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const id = useParams().id;
    const [isOpen, setIsOpen] = useState(false);
    const [isMapLoaded, setMapIsLoaded] = useState(false);
    const { language } = useLanguage();
    const [price, setPrice] = useState<{ value: number | undefined; per: string; }>({ value: 0, per: '' });
    const { property: propertyInfo, propertyLoading, propertyError, refetch } = useGetProperty(Number(id), { language: language.toUpperCase() })
    const property = propertyInfo?.result[0];
    const { properties: sameProperties, loading: sameLoading, error: sameErrorMessage } = useGetProperties({
        page: 0,
        size: 10,
        rentOrSell: property?.rentSellType[0],
        orderByMode: 'LTH',
        // areaIDs: [Number(property?.location?.area?.id)],
        language: language.toUpperCase(),
        channel: 'W',
    })
    const [isReportModalVisible, setIsReportModalVisible] = useState(false);
    const { reasons: reportReasons } = useGetAgentReportReason({ language: language.toUpperCase() });
    const [formReportRequest, setFormReportRequest] = useState<ReportForm>({
        Id: Number(id),
        catID: 1,
        text: ""
    });
    const { responseData } = usePostPropertyReport(formReportRequest);

    const getPrice = () => {
        if (property?.rentSellType === 'Rent') {
            return { value: property?.priceYearly?.AED ?? 0, per: `/ ${t("property.year")}` };
        }
        return { value: property?.price?.AED, per: '' };
    };

    useEffect(() => {
        setMapIsLoaded(true);
    }, []);

    useEffect(() => {
        setPrice(getPrice());
    }, [property]);

    let images = property?.propImages?.map((img: Image) => ({
        thumbnail: convertObjectToImageString(img, ImageSize.Small),
        original: convertObjectToImageString(img, ImageSize.Large)
    })) || [];

    const handleRecommendedSearchClick = (bedroomNums: number) => {
        navigate(`/properties?rentOrSell=R&bedroomNums=${bedroomNums}`);
    };

    const PropertyInformationList = property?.propInfo?.map(Item => ({
        label: Item.name,
        value: Item.value
    })) || [];
    const FeaturesList = property?.features?.filter(Item => Item.status)?.map(Item => ({
        label: Item.name,
        iconUrl: Item.iconUrl + "light.svg"
    })) || [];
    const NearbyList = property?.nearbys?.filter(Item => Item.status)?.map(Item => ({
        label: Item.name,
        iconUrl: Item.iconUrl + "light.svg"
    })) || [];
    const ExtraUnitBulidinList = property?.extraUnitBulidin?.map(Item => ({
        label: Item.name,
        value: Item.value
    })) || [];
    const ExtraFeesList = property?.extraFees?.map(Item => ({
        label: Item.name,
        value: Item.value
    })) || [];
    let PropRegulatoryList: any[] = property?.propRegulatory?.map(Item => ({
        label: Item.name,
        value: Item.value
    })) || [];

    PropRegulatoryList = [...PropRegulatoryList, { label: t("property.DLDPermitNumber"), qrCodeUrl: 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=DLD-Permit' }]
    const handleFavoriteToggle = (isFavorited: boolean) => {
        // TODO: Add API call to save favorite state here
    };

    const handleReportClick = () => {
        setIsReportModalVisible(true);
    };

    const handleCloseReportModal = () => {
        setIsReportModalVisible(false);
    };

    const tempBreadcrumbs = [{
        "name": "placeName",
        "value": "Lakeside Tower B"
    }, {
        "name": "placeName",
        "value": "Lakeside Tower B"
    }, {
        "name": "placeName",
        "value": "Lakeside Tower B"
    }];
    const infoData = [
        ...((property?.propertiesType !== undefined && property?.propertiesType) ? [{ icon: '/images/apts/icons/type.svg', label: property?.propertiesType ?? "" }] : []),
        ...((property?.bedRoomNums !== undefined && property?.bedRoomNums > 0) ? [{ icon: '/images/apts/icons/bed-single.svg', label: property?.bedRoomNums + ' ' + t("filters.Beds") }] : []),
        ...((property?.bathRoomNums !== undefined && property?.bathRoomNums > 0) ? [{ icon: '/images/apts/icons/bathtub.svg', label: property?.bathRoomNums + ' ' + t("filters.Baths") }] : []),
        ...((property?.size?.sqft !== undefined && property?.size?.sqft > 0) ? [{ icon: '/images/apts/icons/area.svg', label: property?.size?.sqft?.toLocaleString() + ' ' + t("common.sqft") }] : []),
    ];
    return (
        <div className="p-container">
            {
                propertyLoading ? <div className="loader"><PuffLoader color="#54B0ED" loading={propertyLoading} />
                </div> :
                    <>
                        <div className="prop-gallery">
                            <ImageGallery
                                items={images}
                                showIndex={images.length ? true : false}
                                autoPlay={false}
                                lazyLoad={true}
                                showPlayButton={false}
                                showBullets={true}
                                showThumbnails={false}
                                showFullscreenButton={false}
                                showNav={true}
                            />
                            <ActionButtons
                                isMobile={true}
                                latitude={property?.location.latitude}
                                longitude={property?.location.longitude}
                                isFavorited={false}
                                onFavoriteToggle={handleFavoriteToggle}
                                onReportClick={handleReportClick}
                            />
                        </div>
                        <div className="property-container">
                            {/* <Breadcrumbs locationBreadcrumbs={property?.location?.breadcrumbs || []} /> */}
                            <Breadcrumbs
                                locationBreadcrumbs={property?.location?.breadcrumbs || []}
                                rentOrSell={property?.rentSellType === 'Rent' ? t('filters.Rent') : property?.rentSellType === 'Sell' ? t('filters.Sell') : t('filters.Rent')}
                            />
                            <ImageGalleryComponent images={images} />

                            <div className="info">

                                <div className="main">

                                    <MainDescription
                                        property={property}
                                        price={price}
                                        onReportClick={handleReportClick}
                                    />

                                    <DescriptionSectionComponenet
                                        description={property?.description + ""}
                                    />

                                    <InfoIconsComponent className="feature-description" data={infoData} />

                                    {
                                        PropertyInformationList?.length > 0 && (
                                            <InfoSectionComponent
                                                title={t("property.PropertyInformation")}
                                                items={PropertyInformationList}
                                            />
                                        )
                                    }

                                    {
                                        FeaturesList?.length > 0 && (
                                            <InfoSectionComponent
                                                title={t("filters.Features")}
                                                items={FeaturesList}
                                                columns={3}
                                            />
                                        )
                                    }

                                    {
                                        NearbyList?.length > 0 && (
                                            <InfoSectionComponent
                                                title={t("property.Nearby")}
                                                items={NearbyList}
                                                columns={3}
                                            />
                                        )
                                    }

                                    {
                                        ExtraUnitBulidinList?.length > 0 && (
                                            <InfoSectionComponent
                                                title={t("property.ExtraBuildingUnitInformation")}
                                                items={ExtraUnitBulidinList}
                                            />
                                        )
                                    }

                                    {
                                        ExtraFeesList?.length > 0 && (
                                            <InfoSectionComponent
                                                title={t("property.ExtraPaymentsFees")}
                                                items={ExtraFeesList}
                                                columns={2}
                                            />
                                        )
                                    }

                                    <MapComponent
                                        address={property?.location?.location_description ?? ""}
                                        latitude={Number(property?.location?.latitude)}
                                        longitude={Number(property?.location?.longitude)}
                                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
                                    />

                                    {
                                        PropRegulatoryList?.length > 0 && (
                                            <InfoSectionQRCodeComponent
                                                title={t("property.RegulatoryInformation")}
                                                items={PropRegulatoryList}
                                            />
                                        )
                                    }

                                    <div className="more-in-this-area">
                                        <span className="more-title">{t("property.MoreAvailableInTheAameArea")}</span>
                                        {
                                            sameLoading ? <div className="loader" style={{ height: "10vh" }}>
                                                <PuffLoader color="#54B0ED" loading={sameLoading} />
                                            </div> :
                                                <div className="props" style={{ marginTop: "40px" }}>
                                                    <Apartments apartments={sameProperties?.result} className="all" />
                                                </div>
                                        }
                                    </div>
                                </div>

                                <div className="recomended">
                                    <AgentContactCard agent={property?.agent} property={property} />
                                    <div className="company">
                                        <div className="company-logo-container">
                                            <img className="company-logo" src={convertObjectToImageString(property?.company?.logo) ?? "/images/apts/icons/company.svg"} />
                                        </div>
                                        <div className="company-info">
                                            <span>{property?.company?.name ?? "Company Name"}</span>
                                            <span className="view" onClick={() => navigate(`/companyProfile/${property?.company?.id}`)}>{t("FindYourAgent.OpenProfile")}</span>
                                        </div>
                                    </div>
                                    <RecommendedSearches onSearchClick={handleRecommendedSearchClick} />
                                </div>
                            </div>
                        </div >
                    </>
            }

            <ReportModal
                reportReasons={reportReasons?.result || []}
                formReportRequest={formReportRequest}
                setFormReportRequest={setFormReportRequest}
                isVisible={isReportModalVisible}
                onClose={handleCloseReportModal}
                title={t('property.ReportTitle')}
                description={t('property.ReportDescription')}
            />
        </div>

    )
}