import React from 'react';
import './error.scss';

interface ErrorComponentProps {
    message?: string;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ message }) => {
    return (
        <div className="error-component">
            <div className="error-message">
                {message}
            </div>
        </div>
    );
};

export default ErrorComponent;
