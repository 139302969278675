import React, { useState } from 'react';
import './tabs.scss';

interface Tab {
    label: string;
    countLabel: string;
    count: number;
    content: JSX.Element;
}

interface TabsProps {
    tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    return (
        <div className="tabs-container">
            <div className="tabs-header">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        className={activeTabIndex === index ? 'active' : ''}
                        onClick={() => setActiveTabIndex(index)}
                    >
                        <div className='label'>{tab.label}</div>
                        <span>{tab.count} {tab.countLabel}</span>
                        <div className={`underline ${activeTabIndex === index ? 'active' : ''}`}></div>
                    </button>
                ))}
            </div>
            <div className="tabs-content">
                {tabs[activeTabIndex].content}
            </div>
        </div>
    );
};

export default Tabs;
