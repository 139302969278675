import React from 'react';
import { Input } from 'antd';

interface RangeFilterProps {
    className?: string;
    minPlaceholder: string;
    maxPlaceholder: string;
    onMinChange: (value: number) => void;
    onMaxChange: (value: number) => void;
    minValue: number;
    maxValue: number;
}

const RangeFilter: React.FC<RangeFilterProps> = ({ minPlaceholder, maxPlaceholder, onMinChange, onMaxChange, minValue, maxValue, className }) => {
    return (
        <div className={`range-filter ${className || ''}`}>
            <Input
                type="number"
                placeholder={minPlaceholder}
                value={minValue}
                onChange={(e) => onMinChange(parseInt(e.target.value))}
                style={{ marginBottom: '8px' }}
            />
            <Input
                type="number"
                placeholder={maxPlaceholder}
                value={maxValue}
                onChange={(e) => onMaxChange(parseInt(e.target.value))}
                style={{ marginBottom: '8px' }}
            />
        </div>
    );
};

export default RangeFilter;
