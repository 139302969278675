import { User } from './authService';
import React, { createContext, useContext } from 'react';
import { LoginProvider, useAuth } from './useAuth';

export interface AuthContextType {
    user: User | null;
    isAuthenticated: boolean;
    isLoading: boolean;
    login: (email: string, password: string) => Promise<boolean>;
    signup: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
    loginWithGoogle: () => Promise<void>;
    loginWithApple: () => Promise<void>;
    logout: () => void;
    refreshUser: () => void;
    getAuthToken: () => Promise<string>;
    loginProvider: LoginProvider;
    forgotPassword: (email: string) => Promise<string>;
    authToken: string | null;
    deleteAccount: (params?: any) => Promise<boolean>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const auth = useAuth();

    return (
        <AuthContext.Provider value={{ ...auth }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};
