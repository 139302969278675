import { useLanguage } from "../context/LanguageContext";
import { BaseParam } from "../helpers/types/general";
import { CreateAxiosGetRequest } from "./GeneralService";


export const useGetEmirates = (params: BaseParam) => {
    const { language } = useLanguage();

    const { data: emirates, loading, error, refetch } = CreateAxiosGetRequest({
        url: `systemData/loadEmirates`,
        params: {
            ...params,
            language: language.toUpperCase(),
        },
        headers: {
            language: language.toUpperCase(),
            section: "H",
            channel: "w", 
        },
    });

    return { emirates, loading, error, refetch };
};


export const useGetFeatures = (params: BaseParam) => {
    const { language } = useLanguage();
    const { data: features, loading, error, refetch } = CreateAxiosGetRequest({
        url: `systemData/loadFeatures`,
        params: {
            ...params,
            language: language.toUpperCase(),
        },
        headers: {
            language: language.toUpperCase(),
            section: "H",
            channel: "w", 
        },
    });
    return { features, loading, error, refetch };
};

export const useGetFrequencies = (params: BaseParam) => {
    const { language } = useLanguage();
    const { data: frequencies, loading, error, refetch } = CreateAxiosGetRequest({
        url: `systemData/loadRentFrequency`,
        params: {
            ...params,
            language: language.toUpperCase(),
        },
        headers: {
            language: language.toUpperCase(),
            section: "H",
            channel: "w", 
        },
    });
    return { frequencies, loading, error, refetch };
};

export const useGetPropTypesByUsage = (id: number, params: BaseParam) => {
    const { language } = useLanguage();

    const { data: propTypes, loading, error, refetch } = CreateAxiosGetRequest({
        url: `systemData/loadPropTypesByUsage`,
        params:
         { ...params,
             id: id.toString(),
             language: language.toUpperCase(),

             },
             headers: {
                language: language.toUpperCase(),
                section: "H",
                channel: "w", 
            },
    });
    return { propTypes, loading, error, refetch };
};

export const useGetAreasByEmirates = (selectedEmirates: number[], language: string) => {
    const params: BaseParam = {
        language,
        channel: "W",
    };

    const { data: areas, loading, error, refetch } = CreateAxiosGetRequest({
        url: selectedEmirates.length > 0
            ? `systemData/loadAreasByEmirates`
            : undefined,
        params: { ...params, Ids: selectedEmirates.join(",") },
        enabled: selectedEmirates.length > 0, // Enable the request only if there are selected emirates
    });

    if (selectedEmirates.length === 0) {
        return { areas: [], loading: false, error: null, refetch };
    }

    return { areas, loading, error, refetch };
};

export const useGetAgentsLangeuages = (params: BaseParam) => {
    const { data: languages, loading, error, refetch } = CreateAxiosGetRequest({
        url: `systemData/loadLangeuages`,
        params
    });
    return { languages, loading, error, refetch };
};

export const useGetPropUsage = (params: BaseParam) => {
    const { data: propUsage, loading, error, refetch } = CreateAxiosGetRequest({
        url: `systemData/loadPropUsage`,
        params
    });
    return { propUsage, loading, error, refetch };
};