import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import authConfig from './authConfig';
import { AuthProvider } from './AuthProvider';

export const Auth0CustomProvider = ({ children }: { children: React.ReactNode }) => {
    const onRedirectCallback = (appState?: { returnTo?: string }) => {
        window.history.replaceState({}, document.title, appState?.returnTo || '/');
    };

    return (
        <Auth0Provider
            domain={authConfig.domain}
            clientId={authConfig.clientId}
            authorizationParams={{
                redirect_uri: authConfig.redirectUri,
                audience: authConfig.audience,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            <AuthProvider>
                {children}
            </AuthProvider>
        </Auth0Provider >

    );
};
