import React from "react";
import './popularCard.scss';
import { useTranslation } from "react-i18next";
import arrow from '../../../assets/icons/arrow.svg'


interface PopularCardProp {
    areaName: string;
    img: string;
    id?: number;
}

export default function PopularCard({ areaName, img }: PopularCardProp) {
    const { t } = useTranslation();
    const containerStyle = {
        backgroundImage: `url(${img})`,
    };
    return (
        <div className="popular-card">
            <div className="bc-img" style={containerStyle}>
                <div className="wrapper">
                    <div className="title">
                        <span>{t("popularCard.title")}</span>
                        <span className="area">{areaName}</span>
                    </div>
                    <div className="view">
                        <span>{t("popularCard.ViewNow")}</span>
                        <img src={arrow}/>
                    </div>
                </div>
            </div>
        </div>
    )
}