import './agentContactCard.scss';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { convertObjectToImageString, whatsAppME } from "../../helpers/helpers";
import { AgentCardDTO } from '../../helpers/types/agent';
import CustomTooltip from '../Tooltip/tooltip';
import EmailModal from '../emailPopup/emailModal';
import { useState } from 'react';

interface agentProp {
    agent?: AgentCardDTO;
    property?: any
}

export default function AgentContactCard({ agent, property }: agentProp,) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    return (
        <div className="agent-contact">
            <div className="info-sec">
                <img className="agent-img" src={convertObjectToImageString(agent?.image) ?? "/images/agents/1.png"} />
                <div className="profile">
                    <span className="name">{agent?.name ?? ""}</span>
                    <span className="position">{agent?.position ?? ""}</span>
                </div>
            </div>
            <div className="btns">
                <div className="agent">
                    <span className="view" onClick={() => navigate(`/agentProfile/${agent?.id}`)}>{t("FindYourAgent.OpenProfile")}</span>
                </div>
                <div className="contact">
                    <CustomTooltip title={t("common.call")}>
                        <button className="contact-btn">
                            <a href={`tel:${agent?.mobile}`}>
                                <img src="/images/apts/icons/call.svg" />
                            </a>
                        </button>
                    </CustomTooltip>
                    <CustomTooltip title={t("common.email")}>
                        <button className="contact-btn" onClick={showModal}>
                            <img src={'/images/apts/icons/mail.svg'} />
                        </button>
                    </CustomTooltip>
                    <CustomTooltip title={t("common.whatsApp")}>
                        <button className="contact-btn" onClick={() => whatsAppME(agent?.mobile ?? "", agent?.whatsAppMsg)}>
                            <a href=''>
                                <img src={'/images/apts/icons/whatsapp_svgrepo.com.svg'} />
                            </a>
                        </button>
                    </CustomTooltip>
                </div>
            </div>
            <EmailModal property={property?.title} setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />

        </div>
    );
}
