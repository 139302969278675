import React, { useEffect, useState } from "react";
import { Modal, Radio, Button, Input, message } from "antd";
import { useGetDeleteReasons } from "../../../services/settingsServices";
import './DeleteAccountModal.scss'
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../auth/AuthProvider";

interface DeleteAccountModalProps {
    visible: boolean;
    onClose: () => void;
    onAccountDeleted: () => void;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ visible, onClose, onAccountDeleted }) => {
    const [deleteReason, setDeleteReason] = useState<string | number>("");
    const [otherReason, setOtherReason] = useState("");
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { result: deleteReasonsResult, loading: deleteReasonsLoading } = useGetDeleteReasons();
    const { deleteAccount } = useAuthContext();



    useEffect(() => {
        const lockScroll = () => {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden';
        };

        const unlockScroll = () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };

        if (visible) {
            lockScroll();
        } else {
            unlockScroll();
        }
        return () => {
            unlockScroll();
        };
    }, [visible]);


    const handleDeleteAccount = async (
        deleteReason: string | number,
        otherReason: string,
        onAccountDeleted: () => void,
        setLoading: (loading: boolean) => void,
        onClose: () => void
    ) => {
        if (!deleteReason) {
            message.warning(t("DeleteProfile.Pleaseselect"));
            return;
        }
        if (deleteReason === -1 && !otherReason.trim()) {
            message.warning(t("DeleteProfile.Pleasespecify"));
            return;
        }
        const finalReason = deleteReason === -1 ? -1 : deleteReason;
        setLoading(true);
        try {
            let response = await deleteAccount({
                reasonID: finalReason,
                text: deleteReason === -1 ? otherReason : 'noreason'
            })

            if (response) {
                message.success("Account deleted successfully.");
                onAccountDeleted();
            } else {
                message.error("Failed to delete the account. Please try again.");
            }
        } catch (error) {
            message.error("An error occurred. Please try again later.");
        } finally {
            setLoading(false);
            onClose();
        }
    };


    return (
        <Modal
            getContainer={false}
            visible={visible}
            onCancel={onClose}
            footer={null}
            centered
            className="delete-account-modal"
            title={
                <h2 className='signInText'>{t("DeleteProfile.deleteMyProfile")}</h2>
            }
        >
            <Radio.Group
                onChange={(e) => setDeleteReason(e.target.value)}
                value={deleteReason}
                style={{ display: "flex", flexDirection: "column" }}
            >
                {deleteReasonsResult?.result?.map((reason: any, index: any) => (
                    <Radio key={index} value={reason.id} style={{ marginBottom: "10px" }}>
                        {reason.name}
                    </Radio>
                ))}
                <Radio value={-1}>
                    {t("DeleteProfile.otherReason")}
                </Radio>
            </Radio.Group>

            {deleteReason === -1 && (
                <div className="form-group">
                    <div className="input-group">
                        <Input
                            placeholder={t("DeleteProfile.specifyReason")}
                            className="InputBoxes"
                            value={otherReason}
                            onChange={(e) => setOtherReason(e.target.value)}
                            required
                        />
                    </div>
                </div>

            )}

            <div className="submit-div">
                <Button
                    type="primary"
                    block
                    onClick={() => handleDeleteAccount(deleteReason, otherReason, onAccountDeleted, setLoading, onClose)}
                    loading={loading}
                    className="submit-button"
                >
                    {t("common.submit")}
                </Button>
            </div>
        </Modal>
    );
};

export default DeleteAccountModal;
