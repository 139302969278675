import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId, geocodeByLatLng, geocodeByAddress } from 'react-google-places-autocomplete';
import { useLanguage } from '../../../context/LanguageContext';
import './styles/LocationSearchFilter.scss';
import { debounce } from '../../../helpers/helpers';
import { ReactComponent as SearchIcon } from '../../../assets/icons/filter/search.svg';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

export interface PlaceResult {
    address: string;
    lat: number;
    lng: number;
    placeId: string;
    placeName: string;
    premise: string;
    street_number: string;
    route: string;
    plusCode: string;
    locality: string;
    neighborhood: string;
    sublocality: string;
    administrative_area_level_1: string;
    country: string;
    types: string[];
}

interface LocationSearchFilterProps {
    onPlaceSelected: (place: PlaceResult) => void;
    placeholder?: string;
    className?: string;
    value?: PlaceResult; // Initial value passed in
    withWhiteBackground?: boolean;

}

const LocationSearchFilter: React.FC<LocationSearchFilterProps> = ({
    onPlaceSelected,
    placeholder,
    className = '',
    value,
    withWhiteBackground = true,
}) => {
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const googleMapsRegion = process.env.REACT_APP_GOOGLE_MAPS_REGION || 'ae'; // Restrict to UAE region
    const { language } = useLanguage();
    const [initialValue, setInitialValue] = useState<any>(null);
    const [firstValue, setFirstValue] = useState<any>(null);
    const [showClear, setShowClear] = useState(false);
    const location = useLocation();

    // Function to get query parameters from the URL
    const getQueryParams = () => {
        const queryString = location.search; // Get the query string
        const urlParams = new URLSearchParams(queryString); // Create a URLSearchParams object
        return Object.fromEntries(urlParams.entries()); // Convert to an object
    };

    const queryParams = getQueryParams(); // Get the query parameters



    useEffect(() => {
        const fetchPlaceDetails = async () => {
            try {
                let place = null;

                if (value?.placeId || queryParams?.placeId) {
                    const results = await geocodeByPlaceId(value?.placeId || queryParams?.placeId);
                    if (results.length > 0) {
                        place = results[0];
                    }
                } else if (value?.lat && value?.lng) {
                    const latLng = { lat: value.lat, lng: value.lng };
                    const results = await geocodeByLatLng(latLng);
                    if (results.length > 0) {
                        place = results[0];
                    }
                } else if (value) {
                    const constructedAddress = [
                        value.placeName,
                        value.premise,
                        value.street_number,
                        value.route,
                        value.sublocality,
                        value.locality,
                        value.administrative_area_level_1,
                        value.country
                    ].filter(Boolean).join(', ');

                    if (constructedAddress) {
                        const results = await geocodeByAddress(constructedAddress);
                        if (results.length > 0) {
                            place = results[0];
                        }
                    }
                }

                if (place) {
                    setFirstValue("Done");
                    setInitialValue({
                        label: place?.formatted_address,
                        value: {
                            place_id: place?.place_id,
                            description: place?.formatted_address,
                        },
                    });
                    setShowClear(true); // Show the clear button after selecting a place

                    const locationDetails = extractLocationDetails(place, []);
                    onPlaceSelected(locationDetails);
                }
            } catch (error) {
                console.error("Error fetching place details:", error);
            }
        };

        if (!firstValue) {
            fetchPlaceDetails();
        }

    }, [value, queryParams?.placeId]);

    useEffect(() => {
        const handleButtonClick = () => {
            setInitialValue(null)

        };

        // Add event listener to the document
        document.addEventListener("resetPropertyButtonClick", handleButtonClick);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener("resetPropertyButtonClick", handleButtonClick);
        };
    }, []);

    const handlePlaceSelected = debounce(async (selectedValue: any) => {
        const placeId = selectedValue.value.place_id;
        const types = selectedValue.value.types;

        try {
            const results = await geocodeByPlaceId(placeId);
            if (results.length > 0) {
                const place = results[0];
                setInitialValue({
                    label: place?.formatted_address,
                    value: {
                        place_id: place?.place_id,
                        description: place?.formatted_address,
                    },
                });
                setShowClear(true); // Show the clear button after selecting a place

                const locationDetails = extractLocationDetails(place, types);
                onPlaceSelected(locationDetails);
            }
        } catch (error) {
            console.error("Error fetching place details by placeId:", error);
        }
    }, 500);  // Delay of 500ms to reduce unnecessary API calls

    const extractLocationDetails = (place: google.maps.GeocoderResult, types: string[]): PlaceResult => {
        const address = place.formatted_address || '';
        const components = place.address_components || [];
        const lat = place.geometry?.location?.lat() || 0;
        const lng = place.geometry?.location?.lng() || 0;
        const placeId = place.place_id || '';

        const locationDetails: PlaceResult = {
            address,
            lat,
            lng,
            placeId,
            placeName: '',
            types,
            premise: '',
            street_number: '',
            route: '',
            plusCode: '',
            locality: '',
            neighborhood: '',
            sublocality: '',
            administrative_area_level_1: '',
            country: '',
        };

        const getAddressComponent = (type: string): string => {
            const component = components.find(comp => comp.types.includes(type));
            return component ? component.long_name : '';
        };

        locationDetails.premise = getAddressComponent('premise') || getAddressComponent('establishment');
        locationDetails.street_number = getAddressComponent('street_number');
        locationDetails.route = `${getAddressComponent('street_number')} ${getAddressComponent('route')}`.trim();
        locationDetails.plusCode = getAddressComponent('plus_code');
        locationDetails.locality = getAddressComponent('locality');
        locationDetails.neighborhood = getAddressComponent('neighborhood');
        locationDetails.sublocality = getAddressComponent('sublocality') || getAddressComponent('sublocality_level_1');
        locationDetails.administrative_area_level_1 = getAddressComponent('administrative_area_level_1');
        locationDetails.country = getAddressComponent('country');

        return locationDetails;
    };

    const handleClear = () => {
        setInitialValue(null);
        setShowClear(false);
        onPlaceSelected({
            address: '',
            lat: 0,
            lng: 0,
            placeId: '',
            placeName: '',
            premise: '',
            street_number: '',
            route: '',
            plusCode: '',
            locality: '',
            neighborhood: '',
            sublocality: '',
            administrative_area_level_1: '',
            country: '',
            types: [],
        });
    };
    const { t } = useTranslation()

    return (
        <div className={`location-search-filter ${className}`}>
            <GooglePlacesAutocomplete
                apiKey={googleMapsApiKey}
                selectProps={{
                    value: initialValue,

                    onChange: handlePlaceSelected,
                    placeholder: t("filters.Location"),
                    className: "location-search-input",
                    styles: {
                        // menuPortal: (provided: any) => ({

                        // }),
                        // container: (provided: any) => ({

                        // }),
                        // dropdownIndicator: (provided: any) => ({

                        // }),
                        // indicatorsContainer: (provided: any) => ({

                        // }),
                        // option: (provided: any) => ({

                        // }),
                        // group: (provided: any) => ({

                        // }),
                        // control: (provided: any) => ({

                        // }),
                        menu: (provided: any) => ({
                            ...provided,
                            backgroundColor: withWhiteBackground ? "#fefefe" : 'rgba(255, 255, 255, 0.9)',
                            top: "50px",
                            padding: "10px 0px",
                            position: "absolute",
                            borderRadius: '14px',
                            boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.1)",
                            zIndex: 999,
                        }),
                        menuList: (provided: any) => ({
                            ...provided,
                            backgroundColor: 'transparent',
                        }),
                        option: (provided: any) => ({
                            ...provided,
                            backgroundColor: 'transparent',
                            fontSize: 14,
                            fontWeight: 400
                        }),
                    }
                }}
                autocompletionRequest={{
                    types: [],
                    componentRestrictions: { country: [googleMapsRegion] },
                }}
                apiOptions={{
                    region: googleMapsRegion,
                    language: language || 'en',
                }}
                debounce={500}
                withSessionToken={true}
                onLoadFailed={(error) => {
                    console.error('Failed to load Google Maps API:', error);
                }}
            />

            {showClear && (
                <CloseOutlined className="location-clear-icon" onClick={handleClear} />
            )}
        </div>
    );
};

export default LocationSearchFilter;
