import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import sortsIcon from '../../../assets/icons/filter/sorts.svg'
import SettingsModal from '../../Modals/SettingsModal/SettingsModal';



const Sort: React.FC = () => {
    const { t } = useTranslation();
    const [isSettingModalVisible, setIsSettingModalVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string>("");
    const handleCancelSettings = () => {
        setIsSettingModalVisible(false);
    };
    const openSettingModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsSettingModalVisible(true);
    };
    const handleApplySettings = () => {
        handleCancelSettings();
    }
    return (
        <div>
            <div>
                <button className='sort-btn' onClick={openSettingModal}>
                    {t('property.sort')}
                    <img className="img-icon" src={sortsIcon} alt="Save" />
                </button>

            </div>
            <SettingsModal
                visible={isSettingModalVisible}
                onCancel={handleCancelSettings}
                onApply={handleApplySettings}
                activeSetting={"sort"}
                selectedOption={selectedOption}
                onOptionChange={setSelectedOption}
            />
        </div>
    );
};

export default Sort;
