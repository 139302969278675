import React from 'react';
import { Checkbox } from 'antd';

interface CheckboxOption {
    id: number | string;
    name: string;
}

interface CheckboxFilterProps {
    className?: string;
    options: CheckboxOption[];
    onChange: (value: any) => void;
    value: any;
}

const CheckboxFilter: React.FC<CheckboxFilterProps> = ({ options, onChange, value, className }) => {
    return (
        <Checkbox.Group value={value} onChange={onChange} className={className} style={{ width: '100%' }}>
            {options?.map((option) => (
                <Checkbox key={option.id} value={option.id}>
                    {option.name}
                </Checkbox>
            ))}
        </Checkbox.Group>
    );
};

export default CheckboxFilter;
