import React, { useEffect } from "react";
import { Col, Row, Button, Modal, Form, Input, Upload } from "antd";
import './partnerModal.scss'
import { useTranslation } from "react-i18next";
export default function PartnerModal({ isModalVisible, handleCancel }: { isModalVisible: boolean, handleCancel: () => void }) {
  const [form] = Form.useForm();
  const { t } = useTranslation()
  const handleSubmit = (values: any) => {
    console.log("Form submitted:", values);
  };
  useEffect(() => {
    const lockScroll = () => {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.body.style.overflow = 'hidden';
    };

    const unlockScroll = () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };

    if (isModalVisible) {
      lockScroll();
    } else {
      unlockScroll();
    }
    return () => {
      unlockScroll();
    };
  }, [isModalVisible]);

  return (
    <Modal
      getContainer={false}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      className="partner-modal"
      centered
      width="1212px"
    >
      <p className="partner-modal-header"> {t("Partenrs.Partnerwith")} </p>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={102}>
          <Col sm={12} xs={24} className="input-group">
            <span> {t("Partenrs.CompanyName")} </span>
            <Input className='InputBoxes' />
          </Col>
          <Col sm={12} xs={24} className="input-group">
            <span>{t("Partenrs.ContactPerson")}</span>
            <Input className='InputBoxes' />
          </Col>
        </Row>
        <Row gutter={102}>
          <Col sm={12} xs={24} className="input-group">
            <span>{t("Partenrs.EmailAddress")}</span>
            <Input className='InputBoxes' />
          </Col>
          <Col sm={12} xs={24} className="input-group">
            <span>{t("Partenrs.PhoneNumber")}</span>
            <Input className='InputBoxes' />
          </Col>
        </Row>
        <Row gutter={102}>
          <Col sm={12} xs={24} className="input-group">
            <span>{t("Partenrs.Website")}</span>
            <Input className="InputBoxes" />
          </Col>

          <Col sm={12} xs={24} className="input-group">
            <Row gutter={4}>
              <Col sm={15} xs={14}>
                <span>{t("Partenrs.CompanyProfile")}</span>
                <Input className="InputBoxes" />
              </Col>

              <Col sm={9} xs={10}>
                <Upload className="upload" showUploadList={false} action="/upload" listType="text">
                  <Button className="upload-btn" block>
                    {t("Partenrs.Upload")}
                  </Button>
                </Upload>
              </Col>
            </Row>
          </Col>
        </Row>

        <Col span={24} className="input-group">
          <span>{t("Partenrs.Message")}</span>
          <Input.TextArea rows={4} className='InputBoxesMessage' />
        </Col>
        <Button className="partner-button" type="primary" htmlType="submit" block>
          {t("Partenrs.OnlineRegistration")}
        </Button>
      </Form>
    </Modal>
  );
}
