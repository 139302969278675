import { useLanguage } from "../context/LanguageContext";
import { PropertyFilterParam } from "../helpers/types/filter";
import { BaseParam, ReportCategoryResponse, ReportForm } from "../helpers/types/general";
import { PropertyResponse } from "../helpers/types/property";
import { CreateAxiosGetRequest, CreateAxiosPostRequest } from "./GeneralService";


export const useGetProperties = (filters: PropertyFilterParam, token?: string) => {
  const { language } = useLanguage();
  const { data: properties, loading, error, refetch } = CreateAxiosGetRequest({
    url: `prop/loadProperties`,
    params: {
      ...filters,
      language: language.toUpperCase(),
    },
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
      authorization: `Bearer ${token ?? ""}`,
    },
  });

  return { properties, loading, error, refetch };
};

export const useGetProperty = (id: number | string, params: BaseParam) => {
  const { language } = useLanguage();

  const { data: property, loading: propertyLoading, error: propertyError, refetch } = CreateAxiosGetRequest<PropertyResponse>({
    url: `prop/loadProperty`,
    params: {
      ...params,
      Id: id,
      language: language.toUpperCase(),

    },
    headers: {
      language: language.toUpperCase(),
      section: "H",
      channel: "w",
    },
  });

  return { property, propertyLoading, propertyError, refetch };
};

export const useGetPropReportReason = (params: BaseParam) => {
  const { data: reasons, loading: propertyLoading, error: propertyError, refetch } = CreateAxiosGetRequest<ReportCategoryResponse>({
    url: `systemData/loadPropReportCategory`,
    params: { ...params }
  });

  return { reasons, propertyLoading, propertyError, refetch };
};

export const usePostPropertyReport = (params?: ReportForm) => {
  const { responseData, loading, error, refetch } = CreateAxiosPostRequest({
    url: `prop/propReport`,
    data: params
  });

  return { responseData, loading, error, refetch };
};