import './smallAreaCard.scss';
import { useTranslation } from "react-i18next";


interface SmallAreaCardProp {
    areaName: string;
    propertyCount: number;
    img: string;
}

export default function SmallAreaCard({ areaName, propertyCount, img }: SmallAreaCardProp) {
    const { t } = useTranslation();
    return (
        <div className="area-card">
            <div className="image-wrapper">
                <img src={img} alt={areaName} className="property-image" />
            </div>
            <div className="property-info">
                <h3 className="area-name">{areaName}</h3>
                <p className="property-count">{propertyCount} {t("common.Properties")}</p>
            </div>
        </div>
    );
}