import React from 'react';
import { Tag } from 'antd';
import './popularSearches.scss';
import { useTranslation } from 'react-i18next';
import { Location } from '../../../helpers/types/general';

interface PopularSearchesProps {
    searches: Location[];
    onSearchClick?: (search: Location) => void;
}

const PopularSearches: React.FC<PopularSearchesProps> = ({ searches, onSearchClick }) => {
    const { t } = useTranslation();
    const handleTagClick = (search: Location) => {
        if (onSearchClick) {
            onSearchClick(search);
        }
    };

    return (
        <div className="popular-search-container">
            <h2 className="search-title">{t('popularInUAE.popularSearch')}</h2>
            <div className="search-wrapper">
                {searches?.map((location, index) => (
                    <Tag key={index} className="search-tag"
                        onClick={() => handleTagClick(location)}  >
                        {`${location.emirate.name}, ${location.area.name}`} 
                    </Tag>
                ))}
            </div>
        </div>
    );
};

export default PopularSearches;
