import React, { Fragment } from 'react';
import './banner.scss';
import HomeFilter from '../homeFilter/homeFilter';
import { useTranslation } from 'react-i18next';

export default function Banner() {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className='banner'>
                <div className='wrapper'>
                    <h1>
                        <span className='title'>{t("banner.Properties")}, </span>
                        <span className='title'>{t("banner.Possibilities")}.</span>
                    </h1>
                    <HomeFilter />
                </div>
                <div className='banner-filters'>
                    {/* <HomeFilter/> */}
                </div>
            </div>
        </Fragment>
    )
}