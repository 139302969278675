import React from "react";
import { useTranslation } from "react-i18next";


export default function LiveViewingTOU() {
    const { i18n } = useTranslation()
    const dir = i18n.dir()
    return (
        <>
            {dir === 'ltr' ? (
                <>
                    <strong>DISCLAIMER </strong>
                    <br /><br />
                    Live Viewings are conducted by our customers. The information communicated in a Live Viewing does not represent the views of Housez. You rely on a Live Viewing completely at your own risk. Housez makes no warranty as to the accuracy or reliability of the information contained or communicated in a Live Viewing and Housez and its related entities, directors, officers and agents disclaim all liability and responsibility for any direct or indirect loss or damage which may be suffered by you through relying on anything communicated in a Live Viewing.
                    <br /><br />
                    Housez FZ LLC (“Housez”) has launched a virtual property viewing functionality (“Live Viewings”) on the Housez.ae platform, which includes its website and app (“Platform”) to enable more efficient and convenient property viewers for our users.
                    <br /><br />
                    If you sign up for, or attend a Live Viewing on the Platform, you agree to be bound by these Live Viewing Terms of use (“Terms of Use”).
                    <br /><br />
                    These Terms of Use, the Terms and Conditions for Users and our Privacy Policy (together the “Terms”) form the basis on which you may access and use Live Viewings. By registering for or attending a Live Viewing or our Platform, you are deemed to have accepted these Terms.
                    <br /><br />
                    We reserve the right to change any or all of our Terms at any time by publishing the new terms on our Platform. Should you object to any of our Terms your sole option is to immediately cease your use of our Platform.

                    <p>To attend a Live Viewing, you must:</p>
                    <ol type="a" style={{ paddingLeft: "40px;" }}>
                        <li>register to attend the relevant Live Viewing on the Platform;</li>
                        <br /> <li>accept these Terms of Use.</li>
                    </ol>
                    Information disseminated in a Live Viewing or on our Platform should not be regarded as a substitute for professional legal, financial or real estate advice.
                    <br /><br />
                    <strong>Restrictions on use of Live Viewings</strong>
                    <p>In accessing a Live Viewing, you agree that you will not:</p>
                    <ol type="a" style={{ paddingLeft: "40px;" }}>
                        <li>use any automated device, software, process or means to access, record, retrieve, scrape, or index the content in the Live Viewing or on our Platform;</li>
                        <br /> <li>use any device, software, process or means to interfere or attempt to interfere with the proper working of the Live Viewing or on our Platform;</li>
                        <br /> <li>undertake any action that will impose a burden or make excessive traffic demands on our infrastructure that we deem, in our sole discretion to be unreasonable or disproportionate site usage;</li>
                        <br /> <li>use the Live Viewings in any manner which is, in our sole discretion, not reasonable and/or not for the purpose which it is made available;</li>
                        <br /> <li>violate the rights of any person, including copyright, trade secret, privacy right, or any other intellectual property or proprietary right;</li>
                        <br /> <li>pose as any person or entity or attempt to solicit money, passwords or personal information from any person;</li>
                        <br /> <li>act in violation of any of the Terms or any applicable law;</li>
                        <br /> <li>reproduce, republish, retransmit, modify, adapt, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit the content of any Live Viewing, except as expressly authorized by us;</li>
                        <br /> <li>transmit or attempt to transmit any computer viruses, worms, defects, Trojan horses or other items of a destructive nature.</li>
                    </ol>
                    We reserve the right to exercise whatever means we deem necessary to prevent unauthorized access to or use of our Live Viewings functionality or our Platform, including, but not limited to, instituting technological barriers, or reporting your conduct to any person or entity.
                    <br /><br />
                    <strong>Third party links and advertising </strong>
                    <br /><br />

                    Live Viewings may contain advertisements, hyperlinks and pointers to websites operated by third parties, such as the relevant real estate agency / broker conducting the Live Viewing. Those third-party websites do not form part of our Platform and are not under the control of or the responsibility of Housez. Housez makes no warranty as to the accuracy or reliability of the information contained on any third-party websites and disclaims all liability and responsibility for any direct or indirect loss or damage which may be suffered by you through relying on anything contained on or omitted from such third-party websites.
                    <br /><br />
                    <strong>Contributions</strong>

                    <br /><br />
                    In this section ‘Contributions’, means information that you share on our Platform as permitted under the Terms, which may include but is not limited to questions you ask during a Live Viewing.
                    <br /><br />
                    <p>Housez may at any time, without liability to you, remove, alter or disable access to any or all your Contributions in its sole discretion without prior notice to you. Without limiting the previous sentence, Housez may remove or disable access to any or all your Contributions if it considers that:</p>
                    <ol type="a" style={{ paddingLeft: "40px;" }}>
                        <li>those Contributions are in breach of any law or regulation;</li>
                        <br /><li>those Contributions infringe the intellectual property rights of Housez or any other third party;</li>
                        <br /> <li>it is required to do so by a regulatory body or any relevant authority pursuant to an interim or final take-down notice;</li>
                        <br /> <li>those Contributions are misleading or deceptive, inappropriate having regard to the purpose of Live Viewings, likely to cause offence, materially incorrect, obscene, defamatory or otherwise unlawful.</li>
                    </ol>
                    You grant Housez a worldwide, nonexclusive, royalty-free, perpetual, transferable and irrevocable license to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display and publicly perform your Contributions throughout the world in any medium, whether currently in existence or not. You also grant each user of our platform a nonexclusive license to use, reproduce, adapt, translate, distribute, prepare derivative works of, display and perform your Contributions as permitted by Housez and these terms.
                    <br /><br />

                    You acknowledge and accept that to deliver the service to you, Housez must process and store your IP address.
                    <br /><br />
                    You represent and warrant that you own and control all of the rights to the Contributions or you have the lawful right including all necessary licenses, rights, consents, and permissions to use and authorize Housez to display the Contributions.

                    <p>You represent and warrant that you will not make any Contributions that:</p>
                    <ol type="a" style={{ paddingLeft: "40px;" }}>
                        <li>infringe the intellectual property rights of Housez or any other third party;</li>
                        <br /> <li>are misleading or deceptive;</li>
                        <br /> <li>are materially incorrect;</li>
                        <br /> <li>are likely to cause offence;</li>
                        <br /> <li>directly or indirectly involve the advertising or marketing of any products or services;</li>
                        <br /> <li>are obscene, including pornographic, hateful, racially or ethnically offensive material;</li>
                        <br /><li>are defamatory;</li>
                        <br /> <li>are otherwise unlawful or encourage unlawful conduct; or</li>
                        <br /> <li>are otherwise inappropriate having regard to the purpose of our platform.</li>
                    </ol>
                    <strong>Platform providers </strong>
                    <br /><br />

                    Live Viewings are conducted via YouTube and your attendance at a Live Viewing is also subject to YouTube terms of use (<a href="mailto:https://www.youtube.com/t/terms" style={{ color: "#01BAFF" }}>https://www.youtube.com/t/terms</a>).
                    <br /><br />
                    <strong>Indemnity </strong>

                    <br /><br />
                    You agree to indemnify and hold Housez and its affiliates (and their officers, agents, partners and employees) harmless against all loss, liability, claim or demand (including reasonable attorneys’ fees) arising out of, or in connection with your use of and access to Live Viewings and our Platform.
                    <br /><br />
                    <strong>Limitation of liability</strong>
                    <br /><br />

                    IN NO EVENT SHALL HOUSEZ BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOSS OF REVENUE, LOSS OF GOODWILL, LOSS OF CUSTOMERS, LOSS OF CAPITAL, DOWNTIME COSTS, LOSS OF PROFIT, LOSS OF OR DAMAGE TO REPUTATION, LOSS UNDER OR IN RELATION TO ANY OTHER CONTRACT, LOSS OF DATA, LOSS OF USE OF DATA, LOSS OF ANTICIPATED SAVINGS OR BENEFITS SUFFERED OR INCURRED BY OR AWARDED AGAINST YOU UNDER OR IN ANY WAY CONNECTED WITH THIS AGREEMENT OR THE PLATFORM. NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, THE AGGREGATE LIABILITY OF HOUSEZ AND ITS RELATED ENTITIES TO YOU FOR ANY CAUSE OF ACTION WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO AED1000.
                    <br /><br />
                    Live Viewings are provided on an "as is" and “as available” basis and we make no warranties, expressed or implied, and hereby disclaim and negate all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, we do not warrant or make any representation concerning the accuracy, likely results, or reliability of Live Viewings.
                    <br /><br />
                    <strong>Governing Law and Jurisdiction </strong>
                    <br /><br />
                    These Terms and Conditions will be governed by and construed in accordance with the laws of the United Arab Emirates as applicable in Dubai and you irrevocably and unconditionally submit to the jurisdiction of the courts of Dubai.
                    <br /><br />
                    <strong>Contact Details</strong>
                    If you have any queries, complaints or recommendations about the Platform or the Live Viewings feature, please contact us at the following address: <a href="mailto:@Housez.ae" style={{ color: " #01BAFF" }}>@Housez.ae</a>.<br /><br /><br /><br />
                </>
            ) : (
                <>
                    <p><strong>العروض المباشرة &ndash; شروط الاستخدام</strong></p>
                    <p><strong>إخلاء المسؤولية</strong></p>
                    <p>يتم إجراء العروض المباشرة من قبل عملائنا. لا تمثل المعلومات المنشورة في أي عرض مباشر آراء شركة <strong>هاوسيز</strong>. أنت تعتمد على أي عرض مباشر على مسؤوليتك الشخصية بالكامل. لا تقدم شركة <strong>هاوسيز</strong> أي ضمان فيما يتعلق بدقة أو موثوقية المعلومات الواردة أو المنشورة في أي عرض مباشر وتخلي شركة هاوسيز &nbsp;وكياناتها ذات الصلة ومدراءها ومسؤوليها ووكلاءها مسؤوليتهم عن أي خسارة أو ضرر مباشر أو غير مباشر قد تتكبده من خلال الاعتماد على أي شيء منشور في أي عرض مباشر.<br /> </p>
                    <p>أطلقت شركة هاوسيز &nbsp;منطقة حرة ش.ذ.م.م ("شركة هاوسيز ") خاصية العرض الافتراضي للعقارات ("العروض المباشرة") على منصة housez.ae، والتي تتضمن موقعها الإلكتروني وتطبيقها ("المنصة") لإتاحة عروض أكثر فعالية وملاءمة لمستخدمينا.<br /> <br /> إذا قمت بالتسجيل أو حضور عرض مباشر على المنصة، فأنت توافق على الالتزام بشروط استخدام العروض المباشرة ("شروط الاستخدام").<br /> <br /> تشكل شروط الاستخدام هذه والشروط والأحكام الخاصة بالمستخدمين وسياسة الخصوصية الخاصة بنا (ويشار إليها معاً باسم "الشروط") الأساس الذي يجوز لك بموجبه الوصول إلى العروض المباشرة واستخدامها. من خلال التسجيل في أو حضور عرض مباشر أو منصتنا، تعتبر بأنك قبلت شروط الاستخدام هذه.<br /> <br /> نحتفظ بالحق في تغيير أي من أو كل شروطنا في أي وقت عن طريق نشر الشروط الجديدة على منصتنا. في حالة رفضك لأي من شروطنا، لن يكون أمامك سوى التوقف فوراً عن استخدامك لمنصتنا.<br /> <br /> لحضور عرض مباشر، يجب عليك:</p>
                    <ol type="a">
                        <li>التسجيل لحضور العرض المباشر ذي الصلة على المنصة؛ و</li>
                        <li>قبول شروط الاستخدام هذه.</li>
                    </ol>
                    <p>يجب عدم اعتبار المعلومات المنشورة في أي عرض مباشر أو على منصتنا بديلاً للمشورة القانونية أو المالية أو العقارية المتخصصة.</p>
                    <p><strong>قيود على استخدام العروض المباشرة</strong></p>
                    <p>من خلال إجراء عرض مباشر، فإنك توافق على عدم القيام بما يلي:</p>
                    <ol type="a">
                        <li>استخدام أي جهاز أو برنامج أو عملية أو وسيلة مؤتمتة للوصول إلى المحتوى أو تسجيله أو استعادته أو كشطه أو فهرسته في "العرض المباشر" أو على منصتنا؛</li>
                        <li>استخدام أي جهاز أو برنامج أو عملية أو وسيلة للتدخل أو محاولة التدخل في الأداء الملائم للعرض المباشر أو على منصتنا؛</li>
                        <li>اتخاذ أي إجراء من شأنه أن يفرض عبئاً أو يفرض متطلبات حركة زائدة على بنيتنا التحتية والتي نعتبرها، وفقاً لتقديرنا، استخداماً غير معقول أو غير متناسب؛</li>
                        <li>استخدام العروض المباشرة بأي طريقة تعتبر، وفقاً لتقديرنا، غير معقولة و/أو غير مناسبة للغرض الذي تم إتاحتها من أجله؛</li>
                        <li>انتهاك حقوق أي شخص، بما في ذلك حقوق الطبع النشر، والأسرار التجارية، وحقوق الخصوصية، أو أي ملكية فكرية أخرى أو حق ملكية؛</li>
                        <li>انتحال صفة أي شخص أو كيان أو محاولة طلب المال أو كلمات المرور أو المعلومات الشخصية من أي شخص؛</li>
                        <li>التصرف بالمخالفة لأي من الشروط أو أي قانون معمول به؛</li>
                        <li>إعادة إنتاج أو إعادة نشر أو إعادة إرسال أو تعديل أو تكييف أو توزيع أو ترجمة أو إنشاء أعمال مشتقة من أو تعديلات على أو عرض محتوى أي عرض مباشر للجمهور أو بيعه أو تداوله أو استغلاله بأي شكل من الأشكال، باستثناء ما هو مصرح به صراحةً من قبلنا؛ أو</li>
                        <li>إرسال أو محاولة نقل أي فيروسات أو ديدان حاسوبية أو عيوب أو أحصنة طروادة أو مواد أخرى ذات طبيعة مدمرة.</li>
                    </ol>
                    <p>نحتفظ بالحق في ممارسة أي وسيلة نراها ضرورية لمنع الوصول إلى أو الاستخدام غير المصرح به لخاصية العرض المباشر أو منصتنا، بما في ذلك، على سبيل المثال لا الحصر، وضع حواجز تكنولوجية أو الإبلاغ عن سلوكك لأي شخص أو كيان.</p>
                    <p><strong>روابط وإعلانات الطرف الثالث</strong></p>
                    <p>قد تحتوي العروض المباشرة على إعلانات وروابط تشعبية ومؤشرات إلى المواقع الالكترونية التي تديرها أطراف ثالثة مثل وكالة / وسيط العقارات ذي الصلة الذي يجري العرض المباشر. لا تشكل مواقع الطرف الثالث تلك جزءًا من منصتنا ولا تخضع لسيطرة أو مسؤولية شركة هاوسيز . لا تقدم شركة هاوسيز &nbsp;أي ضمان فيما يتعلق بدقة أو موثوقية المعلومات الواردة في أي مواقع الكترونية لأطراف ثالثة، وتخلي مسؤوليتها عن أي خسائر أو أضرار مباشرة أو غير مباشرة قد تتكبدها من خلال الاعتماد على أي شيء وارد في أو محذوف من المواقع الالكترونية للأطراف الثالثة.</p>
                    <p><strong>المساهمات</strong></p>
                    <p>في هذا القسم "المساهمات" تعني المعلومات التي تشاركها أثناء على منصتنا والمسموح بها بموجب الشروط والتي قد تشتمل على سبيل المثال لا الحصر استفسارات قد تطرحها أثناء أي عرض مباشر.<br /> </p>
                    <p>يجوز لشركة هاوسيز ، في أي وقت، ودون تحمل أي مسؤولية تجاهك، إزالة أو تغيير أو تعطيل الوصول إلى أي من مساهماتك أو جميعها وفقاً لتقديرها دون الحاجة لإرسال إشعار مسبق لك. دون تقييد الجملة السابقة، يجوز لشركة هاوسيز &nbsp;إزالة أو تعطيل الوصول إلى أي أو كل من مساهماتك إذا رأت:</p>
                    <ol type="a">
                        <li>أن هذه المساهمات تنتهك أي قانون أو لائحة؛</li>
                        <li>أن هذه المساهمات تنتهك حقوق الملكية الفكرية لشركة هاوسيز &nbsp;أو أي طرف ثالث آخر؛</li>
                        <li>أنها مطالبة بالقيام بذلك من قبل أي هيئة تنظيمية أو أي سلطة ذات صلة وفقاً لإشعار إزالة مؤقت أو نهائي؛</li>
                        <li>أن هذه المساهمات مضللة أو خادعة أو غير مناسبة مع الأخذ في الاعتبار الغرض من العروض المباشرة والتي من المحتمل أن تسبب مخالفة غير صحيحة جوهرياً أو بذيئة أو تشهيرية أو غير قانونية بأي شكل آخر؛</li>
                    </ol>
                    <p>أنت تمنح شركة هاوسيز &nbsp;ترخيصًا عالميًا وغير حصري وخالٍ من الإتاوات ودائمًا وقابلًا للتحويل وغير قابل للإلغاء لاستخدام وإعادة إنتاج وتعديل وتكييف وترجمة وتوزيع ونشر وإنشاء أعمال مشتقة من وعرض مساهماتك وتنفيذها للجمهور في جميع أنحاء العالم في أي وسيط، سواء كان موجودة حالياً أم لا. كما أنك تمنح كل مستخدم لمنصتنا ترخيصًا غير حصري لاستخدام وإعادة إنتاج وتكيف وترجمة وتوزيع وإنشاء أعمال مشتقة من وعرض مساهماتك وتنفيذها على النحو الذي تسمح به شركة هاوسيز &nbsp;وهذه الشروط.<br /> </p>
                    <p>أنت تقر وتوافق على أنه من أجل تقديم الخدمة لك، ستحتاج شركة هاوسيز &nbsp;إلى معالجة عنوان بروتوكول الإنترنت الخاص بك وتخزينه.<br /> </p>
                    <p>أنت تتعهد وتقر بأنك تمتلك وتتحكم في جميع حقوق المساهمات أو أن لديك الحق القانوني بما في ذلك جميع التراخيص والحقوق والموافقات والأذونات اللازمة لاستخدام وتفويض شركة هاوسيز &nbsp;لعرض المساهمات.<br /> </p>
                    <p>علاوة على ذلك، تتعهد وتقر أنك لن تقدم أي مساهمات:</p>
                    <ol type="a">
                        <li>تنتهك حقوق الملكية الفكرية لأي طرف ثالث؛</li>
                        <li>مضللة أو خادعة؛</li>
                        <li>غير صحيحة جوهرية؛</li>
                        <li>من المحتمل أن تسبب جريمة؛</li>
                        <li>تنطوي بشكل مباشر أو غير مباشر على الإعلان عن أو التسويق لأي منتجات أو خدمات؛</li>
                        <li>بذيئة بما في ذلك المواد الإباحية أو التي تحض على الكراهية أو المواد المسيئة عنصرياً أو عرقيا؛</li>
                        <li>تشهيرية؛</li>
                        <li>غير قانونية أو تشجع على السلوك غير المشروع؛ أو</li>
                        <li>غير مناسبة لأي سبب آخر خلاف ذلك فيما يتعلق بالغرض من منصتنا.</li>
                    </ol>
                    <p><strong>مزودي المنصة</strong></p>
                    <p>يتم إجراء العروض المباشرة عبر YouTube ويخضع حضورك لأي عرض مباشر أيضاً لشروط استخدام YouTube التالية (<a href="https://www.youtube.com/t/terms">https://www.youtube.com/t/terms</a>)</p>
                    <p><strong>التعويض</strong></p>
                    <p>توافق على تعويض ودرء الضرر عن شركة هاوسيز &nbsp;وشركاتها التابعة (ومسؤوليها ووكلائها وشركائها وموظفيها) ضد أي وجميع الخسائر أو المسؤوليات أو المطالبات أو الطلبات (بما في ذلك أتعاب المحامين المعقولة) الناشئة عن أو فيما يتعلق باستخدام والوصول إلى العروض المباشرة ومنصتنا وأي من العروض المباشرة التي تجريها.</p>
                    <p><strong>تحديد المسؤولية</strong></p>
                    <p>لن تكون شركة هاوسيز &nbsp;بأي حال من الأحوال مسؤولةً تجاهك أو تجاه أي طرف ثالث عن أي أضرار غير مباشرة أو تبعية أو عرضية أو طارئة أو خاصة، بما في ذلك خسارة الأرباح أو خسارة الإيرادات أو خسارة العائد أو خسارة السمعة أو خسارة العملاء أو خسارة رأس المال أو تكاليف تعطل الأعمال أو أي خسارة أو ضرر يلحق بالسمعة أو أي خسارة بموجب أو فيما يتعلق بأي عقد آخر أو خسارة البيانات أو خسارة استخدام البيانات أو خسارة المدخرات المتوقعة أو الفوائد والتي قد تتكبدها أو تتعرض لها أو يصدر حكم بها ضدك بأي طريقة فيما يتعلق بهذه الاتفاقية أو المنصة. بغض النظر عن أي شيء آخر خلاف ذلك في هذه الاتفاقية، تقتصر المسؤولية الإجمالية لشركة هاوسيز &nbsp;وكياناتها التابعة لك عن أي سبب إجراء أيا كان وبغض النظر عن شكل الإجراء في جميع الأوقات على 1000 درهم.<br /> </p>
                    <p>يتم توفير خاصية العروض المباشرة والمنصة "كما هي" و "على أساس توافرها" ولا نقدم أية ضمانات، صريحة أو ضمنية، ونخلي مسؤوليتنا بموجب هذه الاتفاقية ونرفض جميع الضمانات الأخرى، بما في ذلك على سبيل المثال لا الحصر، الضمانات الضمنية أو شروط قابلية التسويق أو الملاءمة لغرض معين أو عدم انتهاك الملكية الفكرية أو أي انتهاك آخر للحقوق. علاوة على ذلك، لا نضمن أو نقدم أي تعهد فيما يتعلق بدقة أو النتائج المحتملة أو موثوقية خاصية العروض المباشرة والمنصة.<br /> </p>
                    <p><strong>القانون الحاكم والاختصاص القضائي</strong></p>
                    <p>تخضع هذه الشروط والأحكام وتفسر وفقًا لقوانين دولة الإمارات العربية المتحدة المعمول بها في دبي وأنت تخضع بشكل غير قابل للإلغاء وغير مشروط للاختصاص القضائي لمحاكم دبي.</p>
                    <p><strong>بيانات الاتصال</strong></p>
                    <p>إذا كان لديك أي استفسارات أو شكاوى أو توصيات حول المنصة أو خاصية العروض المباشرة، يرجى مراسلتنا عبر العنوان التالي:&nbsp;<a href="mailto:info@housez.ae">info@housez.ae</a></p>
                    <br /><br /><br />
                </>
            )}


        </>
    )
}