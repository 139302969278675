import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import './imageGalleryComponent.scss';

interface ImageGalleryComponentProps {
    images: { original: string }[];
}

const ImageGalleryComponent: React.FC<ImageGalleryComponentProps> = ({ images }) => {
    const [isOpen, setIsOpen] = useState(false);
    const placeholderImage = '/images/apts/placeholder-image.svg';
    const displayedImages = [...images];
    while (displayedImages.length < 3) {
        displayedImages.push({ original: placeholderImage });
    }
    return (
        <div className="image-gallery-section">
            <div className="img-container">
                <img className="main-img" src={displayedImages[0]?.original} alt="Main" loading="lazy" />

                <div className="thumb-img-container">
                    <img className="item" src={displayedImages[1]?.original} alt="Thumbnail 1" loading="lazy" />

                    <div className="overlay item" onClick={() => setIsOpen(true)}>
                        <img src={displayedImages[2]?.original} alt="See More" loading="lazy" />
                        <span>{'+See More'}</span>
                    </div>
                </div>
            </div>

            {/* Popup Image Gallery */}
            {isOpen && (
                <div className="imageGallery">
                    <div className="popup-gallery">
                        <span className="closeButton" onClick={() => setIsOpen(false)}>&times;</span>
                        <ImageGallery
                            items={images}
                            autoPlay={false}
                            lazyLoad={true}
                            showPlayButton={false}
                            showBullets={true}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageGalleryComponent;
