import React, { useState, useRef, useEffect, useState as useStateHook } from 'react';
import './descriptionSectionComponent.scss';
import { useTranslation } from 'react-i18next';

interface DescriptionSectionComponenetProps {
    description: string;
}

const DescriptionSectionComponenet: React.FC<DescriptionSectionComponenetProps> = ({ description }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const descriptionRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (descriptionRef.current) {
            setIsOverflowing(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
        }
    }, [description]);

    const formattedDescription = description.replace(/\n/g, '<br />');
    const {t} = useTranslation()
    return (
        <div className="description-section">
            <div
                ref={descriptionRef}
                className={`description-text ${isExpanded ? 'expanded' : 'collapsed'}`}
                dangerouslySetInnerHTML={{ __html: formattedDescription }}
            />
            {isOverflowing && (
                <div className="read-more" onClick={handleToggle}>
                    {isExpanded ? t("propertyProfile.readLess") : t("propertyProfile.readMore")} <span>{isExpanded ? '▲' : '▼'}</span>
                </div>
            )}
        </div>
    );
};

export default DescriptionSectionComponenet;
