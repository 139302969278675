import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { CompanyFilterParam } from "../../helpers/types/filter";

export const useFilterState = (initialFilterParams: CompanyFilterParam) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterParams, setFilterParams] = useState<CompanyFilterParam>(initialFilterParams);

    useEffect(() => {
        const companyNameFromQuery = searchParams.get("companyName") || '';
        const emiratesFromQuery = searchParams.get("emirateIDs")?.split(',') || [];
        const typeIDFromQuery = searchParams.get("typeID");
        const pageFromQuery = searchParams.get("page");

        setFilterParams((prevParams) => ({
            ...prevParams,
            companyName: companyNameFromQuery || prevParams.companyName,
            emirateIDs: emiratesFromQuery.length ? emiratesFromQuery.map(Number) : prevParams.emirateIDs,
            typeID: typeIDFromQuery ? Number(typeIDFromQuery) : prevParams.typeID,
            page: pageFromQuery ? Number(pageFromQuery) : prevParams.page,
        }));
    }, [searchParams]);

    const updateFilterParams = (newParams: Partial<CompanyFilterParam>) => {
        setFilterParams((prevParams) => {
            const updatedParams = { ...prevParams, ...newParams };
            const newSearchParams: Record<string, string> = {};

            Object.entries(updatedParams).forEach(([key, value]) => {
                if (Array.isArray(value) && value.length > 0) {
                    newSearchParams[key] = value.join(',');
                } else if (typeof value === "number" && value !== null) {
                    newSearchParams[key] = String(value);
                } else if (typeof value === "string" && value) {
                    newSearchParams[key] = value;
                }
            });

            setSearchParams(newSearchParams);

            return updatedParams;
        });
    };

    return [filterParams, updateFilterParams] as const;
};
