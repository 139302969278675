import React from 'react';
import './aboutUs.scss';
import HeaderPages from '../pagesFooterHeaderSection/header';
import { useTranslation } from 'react-i18next';
const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div>
      <HeaderPages h1={t("nav.aboutus")} title='' />
      <div className="About-us">
        <br />
        {t("aboutUs.intro1")} <strong>{t("aboutUs.brandName")}</strong> {t("aboutUs.intro2")} <br /><br />
        {t("aboutUs.launch")} <br /><br />
        <strong>{t("aboutUs.platformOffers")}</strong>
        <ul style={{ margin: "30px 0px 30px 0px" }}>
          <li><strong>{t("aboutUs.advancedSearch")}</strong> {t("aboutUs.advancedSearchDesc")}</li>
          <li><strong>{t("aboutUs.comprehensiveListings")}</strong> {t("aboutUs.comprehensiveListingsDesc")}</li>
          <li><strong>{t("aboutUs.trustedPartnerships")}</strong> {t("aboutUs.trustedPartnershipsDesc")}</li>
        </ul>
        {t("aboutUs.missionIntro")} <br /><br />
        {t("aboutUs.missionDescription")} <br /><br />
        <strong>{t("aboutUs.missionTitle")}</strong><br /><br />
        {t("aboutUs.missionText")} <br /><br />
        <strong>{t("aboutUs.visionTitle")}</strong><br /><br />
        {t("aboutUs.visionText")} <br /><br />
        {t("aboutUs.conclusion")}<br /><br />
      </div>
    </div>
  );
};

export default AboutUs;
